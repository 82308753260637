import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {ComponentRef, Injectable, Injector} from '@angular/core';
import {CF_BTN_TYPE, ConfirmationModalOverlayRef} from '../../classes/confirmation-modal.ref';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationComponent} from '../../components/confirmation/confirmation.component';
import {CONFIRMATION_MODAL_DATA} from '../../tokens/confirmation-data.token';

export interface ConfirmationModalData {
  title?: string;
  content?: string;
  icon?: 'success' | 'error' | 'warning' | null;
  type?: 'info' | 'auth' | null;
  cancelText?: string;
  exitText?: string;
  saveText?: string;
  extraInput?: string;
}

interface ConfirmationModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: ConfirmationModalData;
}

const DEFAULT_CONFIG: ConfirmationModalConfig = {
  hasBackdrop: true,
  backdropClass: 'modal-backdrop',
  panelClass: 'modal'
};


@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  public dialogRef: ConfirmationModalOverlayRef;
  private isOpenConfirm = false;

  constructor(
    private translate: TranslateService,
    private injector: Injector,
    private overlay: Overlay
  ) {
  }

  open(config: ConfirmationModalConfig = {}): any {
    // Returns an OverlayRef (which is a PortalHost)
    const modalConfig = {...DEFAULT_CONFIG, ...config};
    const overlayRef = this.createOverlay(modalConfig);
    const dialogRef = new ConfirmationModalOverlayRef(overlayRef);

    const overlayComponent = this.attachModalContainer(overlayRef, modalConfig, dialogRef);

    this.dialogRef = dialogRef;

    return dialogRef;
  }

  private attachModalContainer(overlayRef: OverlayRef, config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): any {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ConfirmationComponent, null, injector);
    const containerRef: ComponentRef<ConfirmationComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ConfirmationModalOverlayRef, dialogRef);
    injectionTokens.set(CONFIRMATION_MODAL_DATA, config.data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ConfirmationModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  private createOverlay(config: ConfirmationModalConfig): OverlayRef {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  confirm(
    title = '',
    content = '',
    cancelText?,
    exitText?,
    saveText = 'COMMON.OK',
    onSave?,
    onExit?,
    onCancel?,
    onClose?,
    inputFlag?
  ): any {
    if (this.isOpenConfirm){
      return;
    }
    this.isOpenConfirm = true;
    const translateText = (key) => {
      if (key) {
        const translate = this.translate.instant(key);
        return translate !== key ? translate : key;
      }
      return key;
    };

    this.open({
      data: {
        title: translateText(title),
        content: translateText(content),
        cancelText: translateText(cancelText),
        exitText: translateText(exitText),
        saveText: translateText(saveText),
        extraInput: inputFlag
      }
    }).onEvent().subscribe(events => {
      this.isOpenConfirm = false;
      if (events.type === CF_BTN_TYPE.CANCEL && onCancel) {
        onCancel();
      }
      if (events.type === CF_BTN_TYPE.SAVE && onSave) {
        onSave(events.data ?? null);
      }
      if (events.type === CF_BTN_TYPE.EXIT && onExit) {
        onExit();
      }

      if (events.type === CF_BTN_TYPE.CLOSE && onClose) {
        onClose();
      }
    });
  }

  notify(title?, content?, cancelText?, exitText?, saveText?, onSave?): any {
    this.confirm(title, content, cancelText, exitText, saveText, onSave);
  }

  success(message: string, onConfirm?): any {
    this._notifyMessageSuccess('success', message, onConfirm);
  }

  warning(message: string, onConfirm): any {
    this._notifyMessage('warning', message, onConfirm);
  }

  error(message: string, onConfirm, time = 1000): any {
    this._notifyMessage('error', message, onConfirm, time);
  }

  auth(message: string,  cancelText?, okText?, onConfirm?): any {
    this.open({
      data: {
        type: 'auth',
        content: this.translate.instant(message),
        cancelText: this.translate.instant(cancelText),
        saveText: this.translate.instant(okText)
      }
    }).onEvent().subscribe(events => {
      if (events.type === CF_BTN_TYPE.SAVE && onConfirm) {
        onConfirm(events);
      }
    });
  }

  private _notifyMessage(type: 'success' | 'warning' | 'error', message: string, onConfirm?, time = 1000): void {
    this.open({
      data: {
        icon: type,
        content: this.translate.instant(message),
        saveText: this.translate.instant('COMMON.OK')
      }
    }).onEvent().subscribe(events => {
      if (events.type === CF_BTN_TYPE.SAVE && onConfirm) {
        onConfirm();
      }
    });

    if(time){
      setTimeout(() => {
        this.dialogRef.events.next({
          type: CF_BTN_TYPE.SAVE,
          data: null
        });
      }, time);
    }
  }

  private _notifyMessageSuccess(type: 'success' | 'warning' | 'error', message: string, onConfirm?): void {
    this.open({
      data: {
        icon: type,
        content: this.translate.instant(message)
      }
    }).onEvent().subscribe(events => {
      if (events.type === CF_BTN_TYPE.SAVE && onConfirm) {
          onConfirm();
        }
    });

    // auto close
    setTimeout(() => {
        this.dialogRef.events.next({
          type: CF_BTN_TYPE.SAVE,
          data: null
        });
      }, 1000);
    }
}
