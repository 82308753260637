import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseApi} from './base.api';
import {map, tap} from 'rxjs/operators';
import {UserSession} from '../../models/user-session.model';
import {UserSessionInterface, UserSubjectInterface} from '../../interfaces/user-session.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseApi {

    public currentUserSubject = new BehaviorSubject<UserSession>(null);
    tokenName = 'token';
    userInfo = 'userInfo';

    /**
     * Get current user's session
     */
    public get currentUserValue(): UserSession {
        return this.currentUserSubject.value;
    }

    /**
     * Get token
     */
    get token(): string | null {
        return localStorage.getItem(this.tokenName);
    }

    /**
     * Clear login data
     */
    clearToken(): void {
        localStorage.removeItem(this.tokenName);
        localStorage.removeItem(this.userInfo);
        this.currentUserSubject.next(null);
    }

    getUserInfo(): Observable<any> {
        return this.get('profile')
            .pipe(tap((user: UserSessionInterface) => {
                let userSession = new UserSession();
                userSession.token = this.token;
                userSession.user = user;
                localStorage.setItem(this.userInfo, JSON.stringify(user));
                this.currentUserSubject.next(userSession);
            })
        );
    }

    login(params: { email: string, password: string }): Observable<UserSession | any> {
        return this.post('login', params)
            .pipe(map((data: UserSubjectInterface | any) => {
                if (data?.status !== 200){
                    return data;
                }
                const userSession = new UserSession(data);
                localStorage.setItem(this.tokenName, `${data.token}`);
                localStorage.setItem(this.userInfo, JSON.stringify(data.user));
                this.currentUserSubject.next(userSession);
                return userSession;
            }));
    }

    authPassword(password: string): Observable<any> {
        return this.post('auth/check-password', {password});
    }

    logout(): Observable<any> {
        return new Observable<any>(subscriber => {
            this.clearToken();
            subscriber.next();
            subscriber.complete();
        });
    }

    changePass(params: { current_password: any, new_password: any, new_confirm_password: any }): Observable<any> {
        return this.post('change-password', params);
    }

    forgetPass(params: {url:any, email: any }): Observable<any> {
        return this.post(`forgot-password `, params)
    }

    getAuthPermission(studyID): any{
        // this.authService
        if (this.currentUserValue.user.roles.length) {
            // tslint:disable-next-line:prefer-const
            let permissions = [];
            this.currentUserValue.user.roles
                .filter(role => role.study_id === studyID)
                .map(role => {
                return role.permissions.map(permission => {
                    permissions.push(permission.id);
                });
                });
            return permissions;
        }
        return [];
    }

    resetPass(params: { password: any, password_confirmation: any }): Observable<any> {
        return this.post(`reset-password`, params);
    }

    gotoLogin(prefix = ''): void {
        this.clearToken();
        if (prefix === 'tablet-app'){
            this.router.navigateByUrl(`${prefix}/login`, {replaceUrl: true}).then();
        }else{
            this.router.navigateByUrl(`login`, {replaceUrl: true}).then();
        }
    }

    gotoHomePage(): void {
        this.router.navigateByUrl('/', {replaceUrl: true}).then();
    }
}
