import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../configs/app-config';
import {ConfirmationModalService} from '../share/service/modal/confirmation-modal.service';
import {AuthService} from "./api-service/auth.service";
import {ManagerStudySelectApi} from './api-service/manager-study-select.api.service';
import {ErrorService} from './error.service';
import * as moment from 'moment';
import {BehaviorService} from './behavior.service';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  private total_new = 0;
  private total_edit = 0;
  private total_delete = 0;
  private screenId = "";
  callSave: any = {};
  studySelected: any = {};
  appConfig = APP_CONFIG;
  isSave = false;
  isCheckform = false;
  metaTitlePage = 'TITLE_PAGE';
  isExit = false;
  public forms: Array<FormGroup> = [];
  public zoneURL = '';

  constructor(
    private confirmPopup: ConfirmationModalService,
    private translate: TranslateService,
    private authService: AuthService,
    protected studySelectApi: ManagerStudySelectApi,
    protected errorService: ErrorService,
    protected toastrService: ToastrService,
    protected behaviorService: BehaviorService,
    protected title: Title,
    private router: Router
  ) {
    setTimeout(() => {
      this.setMetaTitlePage(this.metaTitlePage);
    }, 100);
  }

  checkingEditData(): boolean {
    this.total_new = this.total_edit = this.total_delete = 0;
    for (const form of this.forms) {
      // tslint:disable-next-line:variable-name
      const form_data = form.getRawValue();
      if (form_data.isNew) {
        if (!form_data.isDeleted && form.dirty) {
          this.total_new++;
        }
      } else if (form_data.isDeleted) {
        this.total_delete++;
      } else if (!form.pristine) {
        this.total_edit++;
      }
    }
    if (this.total_new + this.total_edit + this.total_delete > 0) {
      return true;
    } else {
      return false;
    }
  }

  setComponentActive(name: string): void {
    this.screenId = name;
  }

  addForm(form: FormGroup): void {
    this.forms.push(form);
  }

  clearForm(): void {
    if (this.isCheckform){
      this.forms = [];
    }else {
      this.forms.map(form => {
        form.reset();
        form.markAsPristine();
      });
    }
  }

  callBackSaveChange(componentName: string, callBack: () => void): void {
    this.callSave[componentName] = callBack;
  }

  checkFormChange(screenId?: string, clearFormNotChange: boolean = true): Observable<boolean> {
    return new Observable<any>(subscriber => {
      if (this.checkingEditData()) {
        this.confirmPopup.confirm('', this.translate.instant('COMMON.CONFIRM_CHANGE'), 'COMMON.CANCEL', 'COMMON.EXIT', this.isSave === true ? '' : 'COMMON.SAVE', () => {
            // onSave
            // call save data
            let callBackSave = this.callSave[this.screenId];
            callBackSave().subscribe((status) => {
              subscriber.next(status);
              subscriber.complete();
            });
          }, () => {
            // onExit, discard
            this.isExit = true;
            this.clearForm();
            subscriber.next(true);
            subscriber.complete();
          }, () => {
            // onCancel, keep data
            subscriber.next(false);
            subscriber.complete();
          }, () => {
            subscriber.next(false);
            subscriber.complete();
          }
        );
      } else {
        if (clearFormNotChange) {
          this.clearForm();
        }
        subscriber.next(true);
        subscriber.complete();
      }
    });
  }

  grantUserAction(actionCode): boolean {
    console.log('Check ', actionCode);
    if (!actionCode) {
      return true;
    }
    const foundAction = this.authService.currentUserValue?.user?.actions
      .map(action => `${action.module_code}.${action.object_code}.${action.code}`).indexOf(actionCode) >= 0;
    return foundAction;
  }

  hasPermissionPHI(study_id = null): boolean {
    return this.hasAnyPermissions([this.appConfig.permission_value.PHI], study_id, false);
  }

  hasPermissionPHIRead(study_id = null): boolean {
    return this.hasAnyPermissions([this.appConfig.permission_value.PHI], study_id, false)
        && this.hasAnyPermissions(this.appConfig.permission_link.subjects, study_id, false);
  }

  hasAnyPermissions(permissionAllow = [], study_id = null, adminFull = true): boolean {
    if (permissionAllow === undefined || permissionAllow.length === 0) {
      return true;
    }

    if (!study_id){
      this.studySelected = this.studySelectApi.getStudyWorking(false);
      study_id = this.studySelected?.id;
      if (!study_id){
        return false;
      }
    }

    // this.authService
    if (this.authService.currentUserValue.user.roles.length) {
      let permissions = this.authService.getAuthPermission(study_id);
      if (adminFull && permissions.indexOf(this.appConfig.permission_value.CONSENT_ADMIN) >= 0) {
        return true;
      }

      const found = permissionAllow.findIndex(v => permissions.indexOf(v) >= 0);
      if (found >= 0) {
        return true;
      }
    }
    return false;
  }

  // has allow
  get hasAllowAddDocument(): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.document);
  }

  get hasAllowAddUser(): boolean {
    return this.hasAnyPermissions(this.appConfig.permission_link.user);
  }

  get hasAllowAddSubject(): boolean {
    return this.hasAnyPermissions(this.appConfig.permission_link.subject_add_edit);
  }

  get hasAllowDeleteSubject(): boolean{
    return this.hasAnyPermissions([this.appConfig.permission_value.DELETE_SUBJECTS]);
  }

  get hasAllowAddSite(): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.site);
  }

  get hasAllowAddRole(): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.role);
  }

  get hasConsentAdministrator(): boolean {
    return this.hasAnyPermissions([this.appConfig.permission_value.CONSENT_ADMIN]);
  }

  get hasAllowMonitoring(): boolean {
    return this.hasAnyPermissions(this.appConfig.permission_link.monitoring);
  }

  // check allow
  checkAllowAddDocument(studyId): boolean{
      return this.hasAnyPermissions(this.appConfig.permission_link.document, studyId);
  }

  checkAllowAddSubject(studyId): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.subject_add_edit, studyId);
  }

  checkAllowDeleteSubject(studyId): boolean{
    return this.hasAnyPermissions([this.appConfig.permission_value.DELETE_SUBJECTS], studyId);
  }

  checkAllowConsent(studyId): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.study_allow_consent, studyId, false);
  }

  checkAllowConsenting(studyId): boolean{
    return this.hasAnyPermissions(this.appConfig.permission_link.study_allow_consenting, studyId, false);
  }

  showErrmessage(message, screenCode = '', join = '.'): void {
    if (message === 'ER0030' || message === 'ER0040' || message === 'ER0031'){
      return;
    }
    this.toastrService.warning(this.errorService.getErrorsMessage(message, screenCode, join),
      '', this.appConfig.toastOption);
  }

  showSuccess(message): void {
    this.toastrService.success(this.translate.instant(message), '', this.appConfig.toastOption);
  }

  showError(message): void {
    this.toastrService.error(this.translate.instant(message), '', this.appConfig.toastOption);
  }

  showInfo(message): void {
    this.toastrService.info(this.translate.instant(message), '', this.appConfig.toastOption);
  }

  showWarning(message): void {
    this.toastrService.warning(this.translate.instant(message), '', this.appConfig.toastOption);
  }

  getCurrentLocalTime(): any {
    const timezone = new Date().getTimezoneOffset();
    return moment().add(timezone, 'minutes').format('YYYY-MM-DD HH:mm:ss');
  }

  setMetaTitlePage(title): void {
    this.metaTitlePage = title;
    this.listionChangeLanguage();
    setTimeout(() => {
      this.title.setTitle(this.translate.instant(this.metaTitlePage));
    }, 250);
  }

  listionChangeLanguage(): void {
    this.behaviorService.onChangeLanguage.subscribe(() => {
      const lang = APP_CONFIG.getLang();
      document.documentElement.lang = lang;
      this.translate.use(lang).subscribe(() => {
        this.title.setTitle(this.translate.instant(this.metaTitlePage));
      });
    });
  }
  /*A-3-2 Force delete all study begin*/
  showErrmessageStudy(message, screenCode = '', join = '.'): void {
    if (message === 'ER0030' || message === 'ER0040'){
      return;
    }
    this.toastrService.warning(this.errorService.getErrorsMessage(message, screenCode, join),
      '', this.appConfig.toastOption);
  }
  showErrmessage404(message, screenCode = '', join = '.'): void {
    if (message === 'ER0031'){
      this.confirmPopup.notify('', `ERRORS.ER0031`, '', '', 'COMMON.OK', () => {
        window.location.reload();
      })
    }else {
      if (message === 'ER0030' || message === 'ER0040'){
        return;
      }
      this.toastrService.warning(this.errorService.getErrorsMessage(message, screenCode, join),
        '', this.appConfig.toastOption);
    }
  }
  /*A-3-2 Force delete all study end*/
}
