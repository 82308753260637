<div *ngIf="time === 'DAY' || time === 'WEEK'">
  <input matInput [matDatepicker]="dp"
         [placeholder]="option?.placeholder"
         [class]="class"
         disabled
         [formControl]="date"
         (ngModelChange)="changeDate($event)"
         [min]="min ? yesterday : null">
  <mat-datepicker-toggle matSuffix [for]="dp" class="icon-date">
    <img matDatepickerToggleIcon class="w-15px cursor-pointer" src="assets/icons/down-arrow.svg">
  </mat-datepicker-toggle>
  <mat-datepicker #dp disabled="false"></mat-datepicker>
</div>
<div *ngIf="time === 'MONTH'">
  <input matInput [matDatepicker]="dp"
         [placeholder]="option?.placeholder"
         [class]="class"
         disabled
         (ngModelChange)="changeDate($event)"
         [formControl]="date"
         [min]="min ? yesterday : null">
  <mat-datepicker-toggle matSuffix [for]="dp" class="icon-date">
    <img matDatepickerToggleIcon class="w-15px cursor-pointer" src="assets/icons/down-arrow.svg">
  </mat-datepicker-toggle>
  <mat-datepicker #dp disabled="false"
                  startView="multi-year"
                  (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</div>
<div *ngIf="time === 'YEAR'">
  <input matInput [matDatepicker]="dp"
         [placeholder]="option?.placeholder"
         [class]="class"
         disabled
         (ngModelChange)="changeDate($event)"
         [formControl]="date"
         [min]="min ? yesterday : null">
  <mat-datepicker-toggle matSuffix [for]="dp" class="icon-date">
    <img matDatepickerToggleIcon class="w-15px cursor-pointer" src="assets/icons/down-arrow.svg">
  </mat-datepicker-toggle>
  <mat-datepicker #dp disabled="false"
                  startView="multi-year"
                  (yearSelected)="chosenYearHandler($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</div>
