import {BaseList} from './base-list';
import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {BaseApi} from "../services/api-service/base.api";
import {Router} from "@angular/router";
import {LoadingService} from "../share/service/loading.service";
import {ConfirmationModalService} from "../share/service/modal/confirmation-modal.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "../services/global-data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Directive({})
export abstract class BaseSelectMaster extends BaseList {

  @Input() selectedValue: { key: string, value: string } = {key: 'code', value: ''};

  protected constructor(
    protected mainAPIService: BaseApi,
    protected router: Router,
    protected loading: LoadingService,
    protected confirmPopup: ConfirmationModalService = null,
    protected translate: TranslateService = null,
    protected globalDataService: GlobalDataService = null,
    protected ngbModal: NgbModal = null,
    protected callGetList: boolean = true
  ) {
    super(mainAPIService, router, loading, confirmPopup, translate, globalDataService, ngbModal, callGetList);
  }


  onSelectFromTableCustom(e: Array<any>): void {
    super.onSelectFromTableCustom(e);
    this.selectOneRow.emit(this.selectedRows[0]);
  }

  selectSearchChosen(item: any): void {
    if (this.selectSearchMode) {
      this.selectOneRow.emit(item);
    }
  }
}
