import {PagingConfig} from '../configs/paging-config';
import {SORT_CONFIG} from '../configs/sort-config';

export interface BaseSearchParamInterface {
  limit: number;
  page: number;
  criteria: string;
  filter: string;
  order: string;
  order_by: string;
  order_dir: string;
  constCriteria?: string;
  fields?: any;
  conds?: any;
  values?: any;
  searchs?: any;
}

export class BaseSearchParam implements BaseSearchParamInterface {
  limit = PagingConfig.PageLimit;
  page = PagingConfig.FirstPage;
  criteria = '';
  filter = '';
  order = '';
  order_by = SORT_CONFIG.default_order_by;
  order_dir = SORT_CONFIG.default_order_dir;
  constCriteria = '';
  filterList: Array<Array<string>> = [];
  fields = [];
  conds = [];
  values = [];
  searchs = {};

  constructor(props?: BaseSearchParamInterface) {
    // super(props);
    //
    // this.syncProps(props);
  }

  updateCriteria(): void {
    this.criteria = (this.constCriteria ? this.constCriteria + ',' : '') + this.filterList?.map(v => v.join('')).join(',') || '';
  }
}
