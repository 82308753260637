import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import { GlobalDataService } from 'src/app/services/global-data.service';
import {AuthService} from '../../services/api-service/auth.service';

@Injectable({providedIn: 'root'})
export class LoginPageGuardHelper implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private globalDataService: GlobalDataService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.globalDataService.zoneURL = route.data['zone'];
    console.log('zoneURL', this.globalDataService.zoneURL);
    console.log('created', 'NXT-1902');
    if (!this.authService.token) {
      return true;
    } else {
      this.router.navigateByUrl('', {replaceUrl: true}).then();
      return false;
    }
  }
}
