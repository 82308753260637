import {BaseModel} from './base.model';
import {InputFormat} from '../configs/input-format';
import {SelectSearchOption, SelectSearchOptionModel} from './select-search-option.model';
import {NumberFormat, NumberFormatModel} from './number-format.model';
import {Select2Option, Select2OptionModel} from './select2-option.model';

export interface TableHeaderModel {
  name: string;
  column: string;
  type?: InputFormat;
  isPrimaryKey?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  minValue?: string;
  maxValue?: string;
  isHide?: boolean;
  subColumn?: string;
  cellText?: string;
  group?: string;

  select2Option?: Select2OptionModel; // use for type = InputFormat.SELECT2
  numberFormat?: NumberFormatModel; // use for type = InputFormat.NUMBER
  selectSearchOption?: SelectSearchOptionModel; // use for type = InputFormat.SELECT_SEARCH
  classColumn?: string;
  isFilter?: boolean;
}

export class TableHeader extends BaseModel implements TableHeaderModel {
  name = '';
  column = '';
  cellText = '';
  type: InputFormat = InputFormat.TEXT;
  string = null;
  isPrimaryKey = false;
  isDisabled = false;
  isReadOnly = false;
  minValue = null;
  maxValue = null;
  isHide = false;
  subColumn = null;
  group: '';

  select2Option = new Select2Option();
  numberFormat = new NumberFormat();
  selectSearchOption = new SelectSearchOption();
  classColumn = '';
  isFilter = false;

  constructor(props?: TableHeaderModel) {
    super(props);

    this.syncProps(props);
  }

  syncProps(props?: TableHeaderModel): this {
    super.syncProps(props);

    if (props?.select2Option) {
      this.select2Option = new Select2Option(props?.select2Option);
    }
    if (props?.numberFormat) {
      this.numberFormat = new NumberFormat(props?.numberFormat);
    }
    if (props?.selectSearchOption) {
      this.selectSearchOption = new SelectSearchOption(props.selectSearchOption);
    }

    return this;
  }
}
