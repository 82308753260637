import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-layout',
  template: `<router-outlet></router-outlet>`
})
export class DefaultLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
