import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataService } from 'src/app/services/global-data.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router,
    private globalDataService: GlobalDataService
  ) {
  }

  ngOnInit(): void {

  }
  routeTo(): void{
    const zoneUrl = localStorage.getItem('zoneURL');
    console.log(zoneUrl);
    this.router.navigateByUrl(zoneUrl, {replaceUrl: true}).then();
  }
  back(): void {
    this.location.back();
  }
}
