
export const SORT_CONFIG = {
  default_order_by: 'created_at',
  default_order_dir: 'desc',
  order_dir_asc: 'asc',
  order_dir_desc: 'desc',

  default_sort: [
    { value: 'name', label: 'STUDIES.NAME' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' }
  ],

  partner_sort: [
    { value: 'name', label: 'PARTNER_COMPANY.NAME' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' }
  ],
  user_sort: [
    { value: 'name', label: 'ADMIN_USERS.USER_DETAIL.USER_NAME' },
    { value: 'email', label: 'ADMIN_USERS.USER_DETAIL.EMAIL' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' },
  ],
  site_sort: [
    { value: 'site_code', label: 'SITES.SITE_CODE' },
    { value: 'name', label: 'SITES.NAME' },
    { value: 'location', label: 'SITES.LOCATION' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' },
  ],
  subject_sort: [
    {value: 'subject_code', label: 'SUBJECTS.SUBJECT_CODE'},
    {value: 'site_name', label: 'SUBJECTS.SITE'},
    {value: 'created_at', label: 'COMMON.CREATED_AT'},
  ],
  document_sort: [
    { value: 'name', label: 'DOCUMENTS.NAME' },
    { value: 'version', label: 'DOCUMENTS.VERSION' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' },
  ],
    role_sort: [
    { value: 'name', label: 'MANAGER_ROLES.ROLES_DETAIL.ROLE_NAME' },
    { value: 'description', label: 'MANAGER_ROLES.ROLES_DETAIL.DESCRIPTION' },
    { value: 'created_at', label: 'COMMON.CREATED_AT' },
  ],
  role_users_sort : [
    {value: 'name', label: 'MANAGER_ROLES.ROLE_USER.USER_NAME'},
    {value: 'email', label: 'MANAGER_ROLES.ROLE_USER.EMAIL'},
  ],
  site_subject_sort: [
    {value: 'subject_code', label: 'SUBJECTS.SUBJECT_CODE'},
    {value: 'created_at', label: 'COMMON.CREATED_AT'}
  ],
};
