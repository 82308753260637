import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ItemModel } from 'src/app/models/item.model';
import { QuickAccessForm } from '../../utils/quick-access-form';

@Component({
  selector: 'app-item-builder',
  templateUrl: './item-builder.component.html',
  styleUrls: ['./item-builder.component.scss']
})
export class ItemBuilderComponent implements OnInit {

  /** label: onlye label
   *  text, number, datetime, date, time, radio, checkbox, select */
  @Input() itemInfo: ItemModel;
  @Input() showQuestion: boolean = false;

  inputType = {
    label: 'label',
    text: 'text',
    number: 'number',
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    radio: 'radio',
    checkbox: 'checkbox',
    select: 'select',
  };

  form = new FormGroup({});

  constructor(protected translate: TranslateService,) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.form.addControl('id', new FormControl({ value: this.itemInfo?.id, disabled: true }, []));
    this.form.addControl('study_id', new FormControl({ value: this.itemInfo?.study_id, disabled: true }, []));
    this.form.addControl('section_id', new FormControl({ value: this.itemInfo?.section_id, disabled: true }, []));
    this.form.addControl('name', new FormControl({ value: this.itemInfo?.name, disabled: true }, [Validators.maxLength(255)]));
    this.form.addControl('type', new FormControl({ value: this.itemInfo?.type }, []));
    this.form.addControl('question', new FormControl({ value: this.itemInfo?.question }, []));
    this.form.addControl('options', new FormControl({ value: this.itemInfo?.options }, []));
    this.form.addControl('tag_name', new FormControl({ value: this.itemInfo?.tag_name }, []));
    this.form.addControl('url', new FormControl({ value: this.itemInfo?.url }, []));
    this.form.addControl('text_color', new FormControl({ value: this.itemInfo?.text_color }, []));
    this.form.addControl('text_color_hover', new FormControl({ value: this.itemInfo?.text_color_hover }, []));
    this.form.addControl('text_size', new FormControl({ value: this.itemInfo?.text_size }, []));
    this.form.addControl('text_align', new FormControl({ value: this.itemInfo?.text_align }, []));
    this.form.addControl('margin_top', new FormControl({ value: this.itemInfo?.margin_top }, []));
    this.form.addControl('margin_right', new FormControl({ value: this.itemInfo?.margin_right }, []));
    this.form.addControl('margin_bottom', new FormControl({ value: this.itemInfo?.margin_bottom }, []));
    this.form.addControl('margin_left', new FormControl({ value: this.itemInfo?.margin_left }, []));
    this.form.addControl('padding_top', new FormControl({ value: this.itemInfo?.padding_top }, []));
    this.form.addControl('padding_right', new FormControl({ value: this.itemInfo?.padding_right }, []));
    this.form.addControl('padding_bottom', new FormControl({ value: this.itemInfo?.padding_bottom }, []));
    this.form.addControl('padding_left', new FormControl({ value: this.itemInfo?.padding_left }, []));
    this.form.addControl('background_color', new FormControl({ value: this.itemInfo?.background_color }, []));
    this.form.addControl('border_color', new FormControl({ value: this.itemInfo?.border_color }, []));
    this.form.addControl('order', new FormControl({ value: this.itemInfo?.order }, []));
    this.form.addControl('required', new FormControl({ value: this.itemInfo?.required }, []));
  }

  formGetErrorControlMsg(controlName: string, errors: Array<string>): string {
    return QuickAccessForm.getErrorControlMsg(this.form, controlName, errors, null, this.translate);
  }

  formIsShowErrorControl(controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(this.form, controlName);
  }

}
