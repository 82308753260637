import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl} from "@angular/forms";
import {SelectSearchOption, SelectSearchOptionModel} from "../../../models/select-search-option.model";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject, fromEvent, Observable} from "rxjs";
import {MasterSelectSearch} from "../../../configs/master-select-search";
import {BaseSearchParam, BaseSearchParamInterface} from "../../../models/base-search-param.model";
import {BaseApi} from "../../../services/api-service/base.api";
import {debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {OperatorFilter} from "../../../configs/filter-setting";

@Component({
  selector: 'app-select-search-multiple',
  templateUrl: './select-search-multiple.component.html',
  styleUrls: ['./select-search-multiple.component.scss']
})
export class SelectSearchMultipleComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  // region VARIABLES
  @Input() class: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() options: SelectSearchOptionModel;
  @Output() choose = new EventEmitter<object>();
  @Output() onTouch = new EventEmitter<object>();
  @Input() isClearVal = true;
  @Input() allowScrollPage = true;
  @Input() flagProduct = true;
  @Input() maxValue: number;
  @Input() selectedVal = [];
  // default option
  option: SelectSearchOptionModel = new SelectSearchOption();
  popup: NgbModalRef;

  showDrop = new BehaviorSubject<boolean>(false);
  searchKey = new FormControl('');
  optionList: Array<any> = [];
  totalRecord = 0;
  isLoading = false;


  private sub;

  masterSelectSearchScreen = MasterSelectSearch;

  private searchParams: BaseSearchParamInterface = new BaseSearchParam();
  private onChangeModel = (v: any) => {
  };
  private onTouchModel = (v: any) => {
  };

  // endregion
  // region CONSTRUCTOR

  constructor(
    private ngbModal: NgbModal,
    private el: ElementRef,
    private baseApi: BaseApi,
  ) {
  }

  // endregion
  // region LIFE CYCLE
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.option = new SelectSearchOption(changes.options.currentValue);
    }
  }

  ngOnInit(): void {
    this.selectedVal.map(value => {
      return value.colLabel = value[this.options.colLabel];
    });
    // region Dropdown search

    // bắt sự kiện mỗi khi biến "showDrop thay đổi giá trị"
    // Sử dụng `distinctUntilChanged` và `switchMap` để huỷ sự kiện nếu "cố tình" changeValue liên tục
    this.showDrop.pipe(
      distinctUntilChanged(),
      switchMap((flag) => {
        if (!flag) {
          // nếu biến show drop = false -> đóng popup
          if (this.sub) {
            this.optionList = [];
            this.sub.unsubscribe();
          }
          return new Observable<any>(subscriber => {
            subscriber.next();
            subscriber.complete();
          });
        }
        // mặc định: khi mở popup sẽ call api để data
        return this.search(this.searchKey.value);
      }))
      .subscribe(() => {
      });

    // bắt sự kiện mỗi khi biến "searchKey thay đổi giá trị";
    // `distinctUntilChanged` và `switchMap`: tương tự với showDrop bên trên
    // debounceTime: đợi ~500ms để người dùng dừng thao tác mới bắt đầu gọi api
    this.searchKey.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((valueChanges) => this.search(valueChanges)))
      .subscribe(() => {
      });
    // endregion
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.showDrop) {
      this.showDrop.unsubscribe();
    }
  }

  // endregion

  // region ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // if (isDisabled) {
    //   this.selectedVal.disable();
    // } else {
    //   this.selectedVal.enable();
    // }
  }

  writeValue(obj: any): void {
    // this.selectedVal.setValue(obj);
    // this.searchKey.setValue(obj || '', {emitEvent: false});
  }

  // endregion

  // region Call api and choose option
  search(val?): Observable<any> {
    if (!this.option.apiUrl || !this.option.colValue || !this.option.colLabel) {
      return new Observable<any>(subscriber => {
        subscriber.next();
        subscriber.complete();
      });
    }
    this.optionList = [];
    this.searchParams.criteria = this.option.colLabel + OperatorFilter.CONTAINS + val;
    this.searchParams.page = 0;

    return this.baseApi.get(this.option.apiUrl, this.searchParams)
      .pipe(tap((res) => {
        if (res?.data) {
          this.optionList = res.data;
          this.totalRecord = res.totalRecords;
        } else this.optionList = res;
      }));
  }

  chooseOpt(val: object, chooseInDropdown = true): void {
    if (this.option.colValue) {
      const arr = this.selectedValue(val);
      this.choose.emit(arr);
    }
    if (chooseInDropdown) {
      this.toggleDrop();
    }
  }

  // endregion

  // region OTHERS
  toggleDrop(show?): void {
    this.searchKey.setValue('', {emitEvent: false});

    if (show) {
      this.showDrop.next(true);
    } else {
      this.showDrop.next(!this.showDrop.value);
    }

    if (this.showDrop) {
      // event click outside this component
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.showDrop.next(false);
        }
      });
    }
  }


  onTouched(): void {
    this.onTouchModel('touched');
    this.onTouch.emit();
    // if (this.selectedVal.value == '' && this.selectedVal.dirty)
    //   this.onChangeModel(this.selectedVal.value);
  }

  dropListScroll($event): void {
    if (!this.allowScrollPage) return;
    const el = $event.srcElement;
    if (el.scrollTop + el.offsetHeight < el.scrollHeight - 30) {
      return;
    }

    if (this.optionList.length < this.totalRecord && this.isLoading == false) {
      this.searchParams.page++;
      this.isLoading = true;
      this.baseApi.get(this.option.apiUrl, this.searchParams)
        .subscribe((res) => {
          this.isLoading = false;
          this.optionList = this.optionList.concat(res.data);
          this.totalRecord = res.totalRecords;
        });
    }
  }

  selectedValue(val?: any): any {
    let id: any = null;
    if (val) {
      if (this.selectedVal.length) {
       for (let i = 0; i < this.selectedVal.length ; i++) {
         if (this.selectedVal[i].id === val.id) {
            id = i;
            break;
         }
       }
       if (id != null) {
          this.selectedVal.splice(id, 1);
        } else {
          this.selectedVal.push(val);
        }
      } else {
        this.selectedVal.push(val);
      }
    }
    this.selectedVal.map(value => {
      return value.colLabel = value[this.options.colLabel];
    });
    return this.selectedVal;
  }

  clearVal(index: any): any {
    this.selectedVal.splice(index, 1);
    this.choose.emit(this.selectedVal);
  }
}
