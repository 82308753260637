import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges,} from '@angular/core';
import {AuthService} from '../../../services/api-service/auth.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized} from '@angular/router';
import {UserSession} from '../../../models/user-session.model';
import {I18NList} from '../../../configs/i18n-list';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import { BehaviorService } from 'src/app/services/behavior.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APP_CONFIG } from 'src/app/configs/app-config';
import {ConsentSectionApi} from "../../../services/api-service/consent-section.api.service";
import {ConfirmationModalService} from "../../../share/service/modal/confirmation-modal.service";
import { GlobalDataService } from 'src/app/services/global-data.service';

@Component({
  selector: 'app-tabletapp-layout',
  templateUrl: './tabletapp-layout.component.html',
  styleUrls: ['./tabletapp-layout.component.scss'],
  preserveWhitespaces: false,
})
export class TabletappLayoutComponent implements OnInit, OnDestroy {
  isToggledNav = false;
  interval: any;
  itemName = '';
  currentUser: UserSession;
  i18nList = I18NList;
  i18nUse = '';
  multiLang = true;
  titleHeader = 'DASHBOARD';
  env = environment;
  showHeader = true;
  appConfig = APP_CONFIG;
  tablet_screen = APP_CONFIG.tablet_screen;

  constructor(
    private activeRouter : ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    public behaviorService: BehaviorService,
    private activatedRoute: ActivatedRoute,
    private consentSectionApi: ConsentSectionApi,
    private confirmPopup: ConfirmationModalService,
    protected globalDataService: GlobalDataService,
    public el: ElementRef
  ) {
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang){
      lang = this.i18nList[0];
    }
    this.i18nUse = lang.name;
    this.el.nativeElement.parentElement.parentElement.parentElement.lang = lang.code;
    this.behaviorService.changeLanguage(lang.code);
    this.globalDataService.setMetaTitlePage('TITLE_PAGE');
  }

  changeLanguage(lang): void{
    this.i18nUse = lang.name;
    this.el.nativeElement.parentElement.parentElement.parentElement.lang = lang.code;
    this.behaviorService.changeLanguage(lang.code);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  logout(): void {
    const consent = this.consentSectionApi.consent;
    if (consent && consent.date_complete) {
      this.confirmPopup.notify('','DOCTOR_SIGN.CONFIRM_LOGOUT','COMMON.CANCEL', '', 'COMMON.OK', () => {
        this.authService.logout().subscribe(() => {
          this.router.navigateByUrl('/tablet-app/login', {replaceUrl: true}).then();
        });
      })
    } else {
      this.authService.logout().subscribe(() => {
        this.router.navigateByUrl('/tablet-app/login', {replaceUrl: true}).then();
      });
    }

  }
  changePassword(): void{
    this.router.navigateByUrl('/tablet-app/change-password', {replaceUrl: true}).then();
  }

  getItem(items, item): void {
    this.titleHeader = item.name;
    this.itemName = item.name;
  }

  get isShowHeader(): Observable<boolean>{
    return this.behaviorService.isShowHeader
      .pipe(
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn){
            this.showHeader = false;
            return false;
          }
          this.showHeader = false;
          return true;
        })
      );
  }

}
