import {Component, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-toggle-check',
  templateUrl: './toggle-check.component.html',
  styleUrls: ['./toggle-check.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleCheckComponent),
      multi: true,
    },
  ],
})
export class ToggleCheckComponent implements OnInit, ControlValueAccessor {
  @Input() flagCheck = false;
  @Input() class: string | string[] | Set<string> | { [klass: string]: any; };
  disabled = false;

  constructor() { }

  private onChangeModel = (v: any) => {};
  protected onTouchModel = (v: any) => {};

  ngOnInit(): void {

  }

  toggleBoolean(): void {
    this.flagCheck = !this.flagCheck;
    this.onChangeModel(this.flagCheck);
    this.onTouchModel(true);
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.registerOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.flagCheck = value;
  }
}
