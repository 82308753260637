import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {CustomValidators} from '../../../share/utils/custom-validators';
import {LoadingService} from '../../../share/service/loading.service';
import {ConfirmationModalService} from '../../../share/service/modal/confirmation-modal.service';
import {AuthService} from '../../../services/api-service/auth.service';
import {TranslateService} from "@ngx-translate/core";
import { GlobalDataService } from 'src/app/services/global-data.service';
import { BehaviorService } from 'src/app/services/behavior.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form = new FormGroup({
    current_password: new FormControl(null, CustomValidators.required()),
    password: new FormControl(null, [CustomValidators.required(), Validators.pattern('([a-zA-Z\\d!@#$%^&*()\\-_=+{}[\\]|¥;:\'",./<>?]){8,20}')]),
    password_confirmation: new FormControl(null, CustomValidators.required()),
  }, {validators: this.checkPasswords});

  @ViewChild('ngForm') formEl: NgForm;
  showPassword = false;
  showNewPass = false;
  showPassConfirm = false;
  currentObj = 'CHANGE_PASSWORD';

  constructor(
    private loading: LoadingService,
    private confirmPopup: ConfirmationModalService,
    private authService: AuthService,
    protected translate: TranslateService,
    protected globalDataService: GlobalDataService,
    protected behaviorService: BehaviorService,
  ) { }

  ngOnInit(): void {
    this.globalDataService.setMetaTitlePage('CHANGE_PASSWORD.TITLE');

    this.globalDataService.setComponentActive(this.currentObj);
    this.globalDataService.callBackSaveChange(this.currentObj, (): Observable<boolean> => {
      return new Observable<any>(subscriber => {
        this.changePass().subscribe((status) => {
          subscriber.next(status);
          subscriber.complete();
        });
      });
    });
    this.globalDataService.addForm(this.form);
  }

  actionChangePass(): void{
    this.changePass().subscribe((status) => {
      if (status) {
      }
    });
  }

  changePass(): Observable<boolean> {
    return new Observable<any>(subscriber => {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      this.loading.showAppLoading();
      this.authService.changePass(this.form.value).subscribe(() => {
          this.loading.hideAppLoading();
          this.globalDataService.showSuccess('COMMON.SAVE_SUCCESSFUL');
          this.form.markAsPristine();
        }, (error: { success: boolean, message: string }) => {
          this.loading.hideAppLoading();
          this.globalDataService.showErrmessage(error.message);
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }

  checkPasswords(group: FormGroup): any {
    const password = group.get('password').value;
    const confirmPassword = group.get('password_confirmation').value;

    return password === confirmPassword ? null : {matchPass: true};
  }

  // convenience getter for easy access to form fields
  formControl(control: string): AbstractControl {
    return this.form.get(control);
  }
}
