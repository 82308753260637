<div class="row form-group">
  <div class="col-md-4">title</div>
  <div class="col-md-8"><input type="text" class="form-control" formControlName="title"></div>
</div>

<div class="row form-group">
  <div class="col-md-4">description</div>
  <div class="col-md-8"><input type="text" class="form-control" formControlName="description"></div>
</div>

<div class="row form-group">
  <div class="col-md-4">type</div>
  <div class="col-md-8"><input type="text" class="form-control" formControlName="type"></div>
</div>
<hr />