import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-section-builder',
  templateUrl: './section-builder.component.html',
  styleUrls: ['./section-builder.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class SectionBuilderComponent implements OnInit {

  @Input() itemSection: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
