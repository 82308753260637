import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { BehaviorService } from 'src/app/services/behavior.service';
import { APP_CONFIG } from 'src/app/configs/app-config';
import {ManagerStudySelectApi} from "../../../services/api-service/manager-study-select.api.service";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

export const locale = APP_CONFIG.getLang();

@Component({
  selector: 'app-mat-datepicker',
  templateUrl: './mat-datepicker.component.html',
  styleUrls: ['./mat-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatDatepickerComponent),
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class MatDatepickerComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() placeholder = 'yyyy/mm/dd';
  @Input() format = 'YYYY/MM/DD';
  @Input() invalid = false;
  @Input() disabled = false;
  @Input() isStudyFormat = false;
  @Input() showClear = true;
  @Input() class = '';
  @Input() minDate = '';
  @Input() maxDate = '';
  @Input() margin = false;
  @Output() newDate = new EventEmitter<any>();
  dateFormat: number;
  @Input() dateFormatSetting: number;
  dateString: any;

  showControl = false;
  /*A-3-3 Download all begin*/
  @Input() isStudy = true;
  /*A-3-3 Download all end*/
  constructor(
    private dateAdapter: DateAdapter<any>,
    protected behaviorService: BehaviorService,
    protected studySelectApi: ManagerStudySelectApi,
  ) {
    this.behaviorService.listenLanguage.subscribe(langCode => {
      this.dateAdapter.setLocale(langCode);
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(change: SimpleChanges): void{
    if (change?.format?.currentValue || change?.isStudyFormat?.currentValue){
      this.initData();
    }
    if (this.dateFormatSetting || this.dateFormatSetting === 0){
      this.initDataSetting(this.dateFormatSetting);
    }
  }

  initData(): void{
    if (this.isStudyFormat){
      const studySelected = this.studySelectApi.getStudyWorking();
      this.dateFormat = studySelected.date_format;
      if (this.dateFormat === 0){
        MY_FORMATS.display.dateInput = 'YYYY/MM/DD';
        this.placeholder = 'yyyy/mm/dd';
      }else if (this.dateFormat === 1){
        MY_FORMATS.display.dateInput = 'DD/MM/YYYY';
        this.placeholder = 'dd/mm/yyyy';
      } else {
        MY_FORMATS.display.dateInput = 'MM/DD/YYYY';
        this.placeholder = 'mm/dd/yyyy';
      }
    } else {
      MY_FORMATS.display.dateInput = this.format;
    }
    this.showControl = true;
  }
  initDataSetting(format: any = null): void{
    console.log('format' + format);
    if (this.isStudyFormat){
      this.dateFormat = format;
      if (this.dateFormat === 0){
        MY_FORMATS.display.dateInput = 'YYYY/MM/DD';
        // this.placeholder = 'yyyy/mm/dd';
      }else if (this.dateFormat === 1){
        MY_FORMATS.display.dateInput = 'DD/MM/YYYY';
        // this.placeholder = 'dd/mm/yyyy';
      } else {
        MY_FORMATS.display.dateInput = 'MM/DD/YYYY';
        // this.placeholder = 'mm/dd/yyyy';
      }
    } else {
      MY_FORMATS.display.dateInput = this.format;
    }
    this.showControl = true;
  }

  private onChangeModel = (v: any) => { };

  protected onTouchModel = (v: any) => { };


  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  writeValue(value: any): void {
    if (value !== this.dateString && value) {
      this.dateString = new Date(value);
    } else if (!value) {
      this.dateString = '';
    }
  }

  changeDate(): void{
    this.onChangeModel(moment(this.dateString).format('YYYY-MM-DD'));
    this.newDate.emit(moment(this.dateString));
  }

  clearInput() {
    this.dateString = null;
    this.newDate.emit(null);
    this.onChangeModel('');
  }
}
