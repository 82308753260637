import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};

@Component({
  selector: 'app-mat-date-picker',
  templateUrl: './mat-date-picker.component.html',
  styleUrls: ['./mat-date-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'ja'}
  ]
})
export class MatDatePickerComponent implements OnInit, OnChanges {
  @Input() option = {
    valueDate: moment(),
    placeholder: 'date',
    emitEvent: true,
    emitModelToViewChange: true,
    emitViewToModelChange: true,
  };
  @Input() class = 'w-110px p-1';
  @Input() date = new FormControl('');
  @Input() time = 'DAY';
  @Input() min = false;
  @Output() dateChange = new EventEmitter();
  yesterday = moment().format();

  constructor() {
  }

  ngOnInit(): void {
  }

  chosenYearHandler(normalizedYear: Moment, datepicker?: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    if (datepicker) {
      datepicker.close();
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  changeDate(e) {
    this.dateChange.emit(moment(e).format('YYYY-MM-DD'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.date.setValue(moment(this.option?.valueDate), {
      emitEvent: this.option?.emitEvent,
      emitModelToViewChange: this.option?.emitModelToViewChange,
      emitViewToModelChange: this.option?.emitViewToModelChange,
    });
    if (changes['time'] && (changes['time'].currentValue === 'DAY' || changes['time'].currentValue === 'WEEK')) {
      MY_FORMATS.display.dateInput = 'MM/DD(dd)';
    }
    if (changes['time'] && changes['time'].currentValue === 'MONTH') {
      MY_FORMATS.display.dateInput = 'YYYY/MM';
    }
    if (changes['time'] && changes['time'].currentValue === 'YEAR') {
      MY_FORMATS.display.dateInput = 'YYYY';
    }
  }

}
