import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './module/auth/login/login.component';
import { MainLayoutComponent } from './module/layout/main-layout/main-layout.component';
import { NotFoundComponent } from './module/layout/not-found/not-found.component';
import { LoginPageGuardHelper } from './helpers/authen/login-page-guard.helper';
import { AuthGuardHelper } from './helpers/authen/auth-guard.helper';
import { AdminLayoutComponent } from './module/layout/admin-layout/admin-layout.component';
import { ManagerLayoutComponent } from './module/layout/manager-layout/manager-layout.component';
import { ChangePasswordComponent } from './module/commons/change-password/change-password.component';
import { TabletappLayoutComponent } from './module/layout/tabletapp-layout/tabletapp-layout.component';
import {ForgetPasswordComponent} from './module/commons/forget-password/forget-password.component';
import {ResetPasswordComponent} from "./module/commons/reset-password/reset-password.component";
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuardHelper]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { zone: '' },
    canActivate: [LoginPageGuardHelper]
  },
  {
    path: 'tablet-app/login',
    component: LoginComponent,
    data: { zone: 'tablet-app' },
    canActivate: [LoginPageGuardHelper]
  },
  {
    path: 'forgot-password',
    component: ForgetPasswordComponent,
    data: { zone: '' },
    canActivate: [LoginPageGuardHelper]
  },
  {
    path: 'tablet-app/forgot-password',
    component: ForgetPasswordComponent,
    data: { zone: 'tablet-app' },
    canActivate: [LoginPageGuardHelper]
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: { zone: '' },
    canActivate: [LoginPageGuardHelper]
  },
  {
    path: 'tablet-app/reset-password/:token',
    component: ResetPasswordComponent,
    data: { zone: 'tablet-app' },
    canActivate: [LoginPageGuardHelper]
  },

  // Modules
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardHelper],
    data: { zone: 'admin' },
    loadChildren: () => import('src/app/module/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'manager',
    component: ManagerLayoutComponent,
    canActivate: [AuthGuardHelper],
    data: { zone: 'manager' },
    loadChildren: () => import('src/app/module/manager/manager.module').then(m => m.ManagerModule)
  },
  {
    path: 'tablet-app',
    component: TabletappLayoutComponent,
    canActivate: [AuthGuardHelper],
    data: { zone: 'tablet-app' },
    loadChildren: () => import('src/app/module/tabletapp/tabletapp.module').then(m => m.TabletappModule)
  },

  {
    path: 'main',
    component: MainLayoutComponent,
    canActivate: [AuthGuardHelper],
    loadChildren: () => import('src/app/module/main/main.module').then(m => m.MainModule)
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
