<div class="full-page login-page" style="height: 100vh;" *ngIf="isShowForm">
  <div class="container">
    <div class="row vh-50 justify-content-center align-items-center">
      <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3 ml-auto mr-auto">
        <div class="d-flex justify-content-center align-items-center">
          <img class="w-60px h-60px img logo" src="assets/images/logo.png">
          <h1 class="ml-3 mt-1">{{ 'TITLE_PAGE' | translate }}</h1>
        </div>
        <form [formGroup]="form" (ngSubmit)="update()" class="shadow mt-3" #ngForm="ngForm">
          <div class="card" data-background="color" data-color="blue">
            <div class="card-header">
              <h3 class="card-title">{{'LOGIN_PAGE.FORGOT_PASSWORD_TITLE' | translate}}</h3>
            </div>
            <div class="card-content">
              <div class="form-group">
                <label for="email">{{'LOGIN_PAGE.EMAIL' | translate}}</label>
                <input type="email" id="email" class="form-control" [placeholder]="'LOGIN_PAGE.EMAIL' | translate"
                       formControlName="email"
                       [ngClass]="{'border border-danger': formIsShowErrorControl('email')}">
                <div class="text-danger mt-2" *ngIf="ngForm.submitted || ngForm.touched">
                  <div *ngIf="form.hasError('required','email')">{{'LOGIN_PAGE.ERRORS.EMAIL_REQUIRED' | translate}}
                  </div>
                  <div *ngIf="form.hasError('pattern', 'email')">{{'ERRORS.ER0013' | translate:{
                    attribute: 'LOGIN_PAGE.EMAIL' |
                      translate
                  } }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-center">
              <button class="btn btn-primary ">{{'LOGIN_PAGE.SEND' | translate}}</button>
              <div class="forgot mt-3">
                <a class="text-center cursor-point" [routerLink]="getLinkLogin()">{{'LOGIN_PAGE.LOGIN' | translate}}</a>
              </div>
            </div>
          </div>
        </form>
        <div class="mt-3">
          <ul class="list-lange text-center">
          <li *ngFor="let lang of i18nList; let i = index;" (click)="changeLanguage(lang)" class="ml-1 pt-1 item">
            <button [ngClass]="{'active': lang.code == appConfig.getLang()}" class="border-0 item">{{ lang.name }}</button>
          </li>
        </ul>
        </div>
      </div>
    </div>
  </div>
</div>


