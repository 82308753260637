import {BaseModel} from './base.model';

export interface Select2OptionModel {
  bindLabel?: string;
  bindValue?: string;
  options: Array<any>;
  isClearable?: boolean;
  isNoSearchable?: boolean;
  multipleSelect?: boolean;
  hideSelected?: boolean;
}

export class Select2Option extends BaseModel implements Select2OptionModel {
  bindLabel = '';
  bindValue = '';
  options = [];
  isClearable = true;
  isNoSearchable = false;
  multipleSelect = false;
  hideSelected = false;

  constructor(props?: Select2OptionModel) {
    super(props);

    this.syncProps(props);
  }
}
