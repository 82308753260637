import { Injectable } from '@angular/core';
import {BaseApi} from "./base.api";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ManagerDocumentApi extends BaseApi {
  public prefixURL = 'documents';
  api = environment.apiUrl;

  getMySiteInStudy(studyID: number, param?): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/my-sites` + '?' + this.extendSearchParams(param));
  }

  getSiteAssigned(studyID: number, documentID: number): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/document/${documentID}/sites`);
  }

  updateSiteAssigned(studyID: number, documentID: number, body: any, submit_time = ''): Observable<any> {
    return this.http.post(this.api + `/study/${studyID}/document/${documentID}/sites?submit_time=${submit_time}`, body);
  }
  updateStatusDocument(study_id: any, subject_id: any, document_id: any): Observable<any> {
    return this.http.get(this.api + `/study/${study_id}/subjects/${subject_id}/document/${document_id}/status`);
  }
  getTextConsent(studyID, id: any): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/document/${id}/setting-accept`);
  }
  getTextDoctor(studyID, id: any): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/document/${id}/setting-doctorsign`);
  }
  // study/${studySelected?.id}/documents
  getDocumentDetail(studyID, id: any): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/documents/${id}`);
  }
  copyDocument(study_id: any, document_id: any, body: any): Observable<any>{
    return this.http.post(this.api + `/study/${study_id}/document/${document_id}/copy`, body);
  }

  /*A-2 Import data begin*/
  getListStudyRelease(): Observable<any> {
    return this.http.get(this.api + `/list-study-release`);
  }
  releaseDocument(document_id: any, body: any): Observable<any>{
    return this.http.post(this.api + `/document/${document_id}/release`, body);
  }
  /*A-2 Import data end*/
}
