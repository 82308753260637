import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: 'input[noSpace]'
})
export class InputNoSpaceDirective {

  @HostListener('keypress', ['$event']) onKeyPress(event): void {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 32) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event): void {
    const pasteData = event.clipboardData.getData('text');
    if (pasteData.indexOf(' ') >= 0) {
      event.preventDefault();
    }
  }

  constructor() { }
}
