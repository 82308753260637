import {Component, Input, OnInit} from '@angular/core';
import {BaseListReadonly} from '../../../models/base-list-readonly';
import {BaseModel} from "../../../models/base.model";

@Component({
  selector: 'app-input-resize',
  templateUrl: './input-resize.component.html',
  styleUrls: ['./input-resize.component.scss']
})
export class InputResizeComponent extends BaseModel implements OnInit {

  @Input() type: any;
  @Input() value: string | number;
  @Input() header: any;
  @Input() unit = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
