import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges, SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-create-signature',
  templateUrl: './create-signature.' +
    'component.html',
  styleUrls: ['./create-signature.component.scss']
})
export class CreateSignatureComponent implements OnInit, OnChanges {
  @Input() width = 250;
  @Input() height: 100;
  @Input() readonly: false;
  @Input() class = false;

  @Input() dataImage: any;
  @ViewChild('sigPad', { static: true }) sigPad: ElementRef;
  @Output() onSign = new EventEmitter<any>();

  sigPadElement;
  context;
  isDrawing = false;
  constructor() { }

  ngOnInit(): void{
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataImage){
      this.drawImage();
    }
  }


  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e): void{
    if (!this.readonly && this.isDrawing){
      this.isDrawing = false;
      this.onSign.emit(this.sigPadElement.toDataURL('image/png'));
    }
  }

  onMouseDown(e): void{
    if (!this.readonly){
      this.isDrawing = true;
      const coords = this.relativeCoords(e);
      this.context.moveTo(coords.x, coords.y);
    }
  }

  onMouseMove(e): void{
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event): {x, y}{
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x, y };
  }

  clear(): void{
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
    this.onSign.emit('');
  }

  drawImage(): void{
    const ctx = this.sigPad.nativeElement.getContext('2d');
    ctx.clearRect(0, 0, this.width, this.height);
    ctx.beginPath();
    const img = new Image();

    img.onload = () => {
      ctx.drawImage(img, 0, 0, this.width, this.height);
    };
    img.src = this.dataImage;
  }
}
