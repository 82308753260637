<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h2>{{"NOTFOUND.TITLE" | translate}}</h2>
        <div class="error-details">
          {{"NOTFOUND.MESSAGE" | translate}}
        </div>
        <div class="error-actions">
            <button class="btn btn-lg btn-outline mr-2 my-1" (click)="back()">
              <img class="w-20px" src="assets/icons/back.svg">
            </button><button class="btn btn-lg btn-outline mr-2 my-1"(click)="routeTo()">
              <img class="w-20px" src="assets/icons/home.svg">
            </button>
        </div>
      </div>
    </div>
  </div>
</div>

