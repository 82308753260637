<div class="dropdown">
  <div class="input-group">
    <div class="inner-addon right-addon">
      <input *ngIf="option.allowSearch" class="form-control dropdown-toggle" readonly [value]="selected.text"
        (click)="toggleDrop(true)">
      <input *ngIf="!option.allowSearch && option.allowPopup" class="form-control dropdown-toggle" readonly
        [value]="selected.text" (click)="openPop()">
      <span class="inner-icon-right" *ngIf="selected.value" (click)="clearData()">&times;</span>
    </div>
    <div class="input-group-append" *ngIf="option.allowPopup">
      <button class="btn border-left-0" type="button" (click)="openPop()">
        <i class="fas fa-search fs14"></i></button>
    </div>
  </div>

  <div class="dropdown-menu p-1" [class]="{'show': showDrop.value}" (scroll)="dropListScroll($event)">
    <input class="form-control form-control-sm custom-size" [formControl]="searchKey">
    <a class="dropdown-item" *ngFor="let opt of optionList" (click)="chooseOpt(opt)" (keyup.enter)="chooseOpt(opt)"
      tabindex="0" [ngClass]="{'active': selected.value === opt[option.colValue]}">
      <span class="custom-size">{{opt[option.colLabel]}}</span>
    </a>
  </div>

</div>

<ng-template #modalContent let-modal>
  <ng-container *ngIf="option?.masterSearchComponents === masterSelectSearch.DEPARTMENTS">
    <div class="modal-header">
      <h3>{{ "DEPARTMENTS.SELECT" | translate }}</h3>

      <button type="button" class="close m-0 p-1" (click)="closePopup()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-share-department-list [selectSearchMode]="true" [showRoot]="true" [allowSelect]="true"
        [selected]="[itemSelected?.id]" (eventSelect)="onSelectPopup($event)">
      </app-share-department-list>
    </div>
  </ng-container>

</ng-template>