export class NumberProcessor {
  /* Example:
  * 123456.789 -> 123456.78 (limit = 2)
  * 200000 -> 200000.00 (limit = 2)
  * */
  static decimals(value: any, limit: number = 0): string {
    return value ? parseFloat(value).toFixed(limit) : '';
  }

  /* Example:
  * 123456.789 -> 123,456.789
  * 20000 -> 200,000.00 (minLimit = 2)
  * */
  static currencyLocaleString(value: any, minFractionDigits: number = 0, maxFractionDigits: number = 4, defaultValue: any = '0'): string {
    const option = {};
    if (minFractionDigits) {
      option['minimumFractionDigits'] = minFractionDigits;
    }
    if (maxFractionDigits) {
      option['maximumFractionDigits'] = maxFractionDigits;
    }
    return parseFloat(value) ? parseFloat(value).toLocaleString('en-US', option) : defaultValue;
  }
}
