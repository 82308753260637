import {Directive, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[numberOnly]'
})
export class NumberOnlyDirective {
  @Input() isInteger = false;
  @Input() isNumber = false;

  beforeValue: any;

  @HostListener('keypress', ['$event']) onKeyPress(event): void {
    if (this.isNumber) {
      const charCode = (event.which) ? event.which : event.keyCode;
      const key = event?.key;
      if ((!(charCode > 31 && (charCode < 48 || charCode > 57)))
        || (event.target.value && event.target.value.indexOf('.') < 0 && charCode === 46 && !this.isInteger)
        || key === '+' || key === '-'
      ) {
        // todo
      } else {
        event.preventDefault();
      }
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event): void {
    if (this.isNumber) {
      if(event.target.value !== '' && event.target.value !== '+' && event.target.value !== '-'){
        const regex = new RegExp(/^[\+\-]?\d+(\.\d*)?$/g);
        const checkNumber  = regex.test(event.target.value.trim());
        if (checkNumber === false) {
          event.target.value = this.beforeValue;
        }else{
          this.beforeValue = event.target.value;
        }
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(event): void {
    if (this.isNumber) {
      const pasteData = event.clipboardData.getData('text').replace(/\,/g, '');
      const regex = new RegExp(/^[\+\-]?\d+(\.\d+)?$/g);
      if (!regex.test(pasteData)) {
        // todo
      } else {
        event.preventDefault();
      }
    }
  }

  constructor() { }
}
