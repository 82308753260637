import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { AuthService } from 'src/app/services/api-service/auth.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import {CF_BTN_TYPE, ConfirmationModalOverlayRef} from '../../classes/confirmation-modal.ref';
import {ConfirmationModalData, ConfirmationModalService} from '../../service/modal/confirmation-modal.service';
import {CONFIRMATION_MODAL_DATA} from '../../tokens/confirmation-data.token';
import {FormControl, FormGroup} from "@angular/forms";
import {CustomValidators} from "../../utils/custom-validators";
import {BaseDetailPopup} from "../../../models/base-detail-popup";
import {ManagerDocumentApi} from "../../../services/api-service/manager-document.api.service";
import {Router} from "@angular/router";
import {LoadingService} from "../../service/loading.service";
import {TranslateService} from "@ngx-translate/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
// update for new common modal (add extra input to confirm modal).
export class ConfirmationComponent extends BaseDetailPopup implements OnInit, AfterViewInit {
  @ViewChild('elPopupCancel') elPopupCancel: ElementRef;
  @ViewChild('elPopupExit') elPopupExit: ElementRef;
  @ViewChild('elPopupSave') elPopupSave: ElementRef;

  inputPass = '';
  currentObj = this.data.extraInput;
  constructor(
    public dialogRef: ConfirmationModalOverlayRef,
    public authService: AuthService,
    protected documentApi: ManagerDocumentApi,
    protected router: Router,
    protected loading: LoadingService,
    protected confirmPopup: ConfirmationModalService,
    protected translate: TranslateService,
    protected globalDataService: GlobalDataService,
    protected ngbModal: NgbModal,
    @Inject(CONFIRMATION_MODAL_DATA) public data: ConfirmationModalData
  ) {
    super(documentApi, router, loading, confirmPopup, translate, globalDataService, ngbModal);
  }
  releaseActive = false;
  ngOnInit(): void {
    if (this.data.extraInput){
      this.formGroupAdd = this.buildFormDocument();
    }
  }

  buildFormDocument(): any {
    const form = new FormGroup({
      confirm: new FormControl('', [CustomValidators.required()]),
    });

    this.isShowForm = true;
    this.globalDataService.addForm(form);
    return form;
  }
  /**
   * Set focus to button
   */
  ngAfterViewInit(): void {
    if (this.data.saveText) {
      this.elPopupSave.nativeElement.focus();
    } else if (this.data.cancelText) {
      this.elPopupCancel.nativeElement.focus();
    } else if (this.data.exitText) {
      this.elPopupExit.nativeElement.focus();
    }
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    if(this.data.extraInput) {
      this.formGroupAdd.markAsPristine();
    }
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.CLOSE,
      data: null
    });
  }

  /**
   * cancel modal
   */
  public cancelModal(): void {
    if(this.data.extraInput) {
      this.formGroupAdd.markAsPristine();
    }
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.CANCEL,
      data: null
    });
  }

  /**
   * exit modal
   */
  public exitModal(): void {
    if(this.data.extraInput) {
      this.formGroupAdd.markAsPristine();
    }
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.EXIT,
      data: null
    });
  }

  /**
   * save modal
   */
  public saveModal(): void {
    if(this.data.extraInput) {
      this.formGroupAdd.markAllAsTouched();
      if(this.formGroupAdd.invalid){
        return;
      }
      this.formGroupAdd.markAsPristine();
      this.dialogRef.events.next({
        type: CF_BTN_TYPE.SAVE,
        data: this.formGroupAdd.get('confirm').value
      });
    }
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.SAVE,
      data: null
    });
  }
}
