import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NumberProcessor} from '../../utils/number-processor';
import {NumberFormatModel} from '../../../models/number-format.model';
import {TypeValue} from 'src/app/configs/input-format';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() class: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() moneyUnit = '';
  @Input() placeholder = '';
  @Input() isInteger = false;
  @Input() isDisabled = false;
  @Input() isReadOnly = false;
  @Input() minValue = null;
  @Input() maxValue = null;
  @Input() numberFormat?: NumberFormatModel;

  keyCodePress = [8];

  displayVal = new FormControl();
  private onChangeModel = (v: any) => {
  };
  protected onTouchModel = (v: any) => {
  };

  onfocus(e): void {
    if (this.displayVal.value == this.numberFormat?.defaultValue)
      this.displayVal.setValue("");
    else
      this.displayVal.setValue(this._cleanNumber(this.displayVal.value));
  }

  onblur(e): void {
    if (this.maxValue && parseInt(this.displayVal.value, 10) > this.maxValue) { this.displayVal.setValue(this.maxValue.toString()); }
    if (this.minValue && parseInt(this.displayVal.value, 10) < this.minValue) { this.displayVal.setValue(this.minValue.toString()); }

    if (parseFloat(e.target.value) === 0 || e.target.value === '') {
      this.displayVal.setValue(this.numberFormat.defaultValue);
    } else {
      this.displayVal.setValue(this._convertNumberToCurrency(e.target.value));
    }

    this.onChangeModel((this._cleanNumber(this.displayVal.value)));
  }

  onkeypress(e): void {
   this.onChangeModel((this._cleanNumber(this.displayVal.value)));
  }

  onKeyUp(e): void {
    this.onChangeModel((this._cleanNumber(this.displayVal.value)));
    this.displayVal.setValue(e.target.value);
  }

  private _cleanNumber(val: string): string {
    return val ? val.replace(/\,/g, '') : '';
  }

  private _convertNumberToCurrency(input: any): string {
    const c = input ? this.convertValue(input.toString().replace(/\,/g, '')) : '';
    if (isNaN(<number>c)) {
      return c;
    } else {
      return NumberProcessor.currencyLocaleString(c, 0, this.numberFormat?.fraction, this.numberFormat?.defaultValue);
    }
  }

  writeValue(value: number | string): void {
    this.displayVal.setValue(this._convertNumberToCurrency(value));
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.displayVal.disable();
    } else {
      this.displayVal.enable();
    }
  }

  //function convert value to utf8
  convertValue(chars) {
    return chars.replace(
      /[\uff01-\uff5e]/g,
      (char = chars) => {
        return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
      }
    );
  }

  //function preparatory
  toASCII(chars, type = TypeValue.STRING): number | string {
    let ascii = '';
    for (var i = 0, l = chars.length; i < l; i++) {
      let c = chars[i].charCodeAt(0);

      // make sure we only convert half-full width char
      if (c >= 0xFF00 && c <= 0xFFEF) c = 0xFF & (c + 0x20);

      ascii += String.fromCharCode(c);
    }

    if (type === TypeValue.INTEGER) return parseInt(ascii, 10);

    return ascii;
  }
}
