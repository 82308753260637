<div [ngClass]="{'inner-addon': true, 'right-addon': moneyUnit}">
  <input type="text" class="form-control text-right" [ngClass]="class"
         numberOnly [isInteger]="isInteger"
         [formControl]="displayVal"
         [placeholder]="placeholder"
         [attr.disabled]="isDisabled ? '' : null"
         [readOnly]="isReadOnly"
         (keypress)="onkeypress($event)"
         (keyup)="onKeyUp($event)"
         (focus)="onfocus($event)"
         (blur)="onblur($event)">
  <span class="inner-icon-right" *ngIf="moneyUnit">{{moneyUnit}}</span>
</div>
