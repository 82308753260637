import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SORT_CONFIG} from "../../../configs/sort-config";
import {EncodeDetailApi} from "../../../services/api-service/encodeDetail.api";
import {LoadingService} from "../../service/loading.service";
import {GlobalDataService} from "../../../services/global-data.service";

@Component({
  selector: 'app-filter-encode',
  templateUrl: './filter-encode.component.html',
  styleUrls: ['./filter-encode.component.scss']
})
export class FilterEncodeComponent implements OnInit {
  @Input() isSort = false;
  @Input() isAPI = false;
  @Input() fillSort = false;
  @Input() itemsSort = [];
  @Input() encode: any;
  @Input() restore: any;
  valueAPI: any;
  @Input() option = {
    filter: '',
    order_by: 'created_at',
    order_dir: 'asc',
  };
  @Input() placeholder = 'COMMON.PLACEHOLDER_SEARCH';

  @Output() applySort: EventEmitter<any> = new EventEmitter();
  @Output() clickSort: EventEmitter<any> = new EventEmitter();
  @Output() APIVal: EventEmitter<any> = new EventEmitter();
  @Output() closeSort: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalPopupSort') modalPopupSort;
  subject: any;
  popupSort: NgbModalRef;
  sortConfig = SORT_CONFIG;
  order_dir: any;
  order_by: any;


  constructor(protected modalService: NgbModal,
              protected encodeDetailApi: EncodeDetailApi,
              protected loading: LoadingService,
              public globalDataService: GlobalDataService) { }

  ngOnInit(): void {
    if ( this.option.order_by !== 'flag_encode' ){
      this.option.order_by = 'flag_encode';
    }
    if ( this.option.order_by !== 'encode_time' && this.encode == false ){
      this.option.order_by = 'encode_time';

    }
    if ( this.option.order_by !== 'r_status' && this.restore == false ){
      this.option.order_by = 'r_status';

    }
    this.order_dir = this.option.order_dir;
    this.order_by = this.option.order_by;
  }
  onEnter(e): void {
    if (e.keyCode === 13) {
      this.onApplyForm(false);
    }
  }

  // popup sort
  openPopUpSort($target: any = this.modalPopupSort, options = { size: 'md', scrollable: false, centered: false }): void {
    if (!$target){
      $target = this.modalPopupSort;
    }
    this.option.order_dir = this.order_dir;
    this.option.order_by = this.order_by;
    this.popupSort = this.modalService.open($target, options);
  }

  onCloseSort(): void {
    if (this.popupSort)
    {
      this.popupSort.close();
    }
  }

  onApplyForm(API: any): void {
    this.order_by = this.option.order_by;
    this.order_dir = this.option.order_dir;
    this.applySort.emit(this.option);
    this.clickSort.emit(true);
    this.onCloseSort();
  }

}
