import { Directive, EventEmitter, Output } from '@angular/core';
import { InputFormat } from '../configs/input-format';
import { TableHeaderModel } from './table-header.model';
import { NumberProcessor } from '../share/utils/number-processor';
import * as moment from 'moment';
import { DateFormat, DateFormatDash, DateTimeFormat } from '../configs/date-time-config';
import { URL_LIST } from '../configs/url-list';
import { APP_CONFIG } from '../configs/app-config';

export interface BaseInterface {
}

@Directive({})
export abstract class BaseModel {

  url_list = URL_LIST;
  app_config = APP_CONFIG;

  selectedRows = [];
  @Output() selectOneRow = new EventEmitter<any>();
  @Output() dismissPopup = new EventEmitter<any>();

  constructor(props?) {
  }

  syncProps(props?): this {
    if (props) {
      Object.keys(props).forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = props[key];
        }
      });
    }
    return this;
  }

  onSelectFromTableCustom(e: Array<any>): void {
    this.selectedRows = e;
    this.selectOneRow.emit(this.selectedRows[0]);
  }

  onDismissPopup(): void {
    this.dismissPopup.emit();
  }

  getTextAlign(type: InputFormat): 'left' | 'center' | 'right' {
    switch (type) {
      case InputFormat.NUMBER:
      case InputFormat.FLOAT: {
        return 'right';
      }
      case InputFormat.BOOLEAN: {
        return 'center';
      }
      default:
        return 'left';
    }
  }

  getValueFormat(type: any, value: any, options?: TableHeaderModel, unit?: string): string {
    switch (type) {
      case InputFormat.NUMBER:
      case InputFormat.FLOAT: {
        return `${NumberProcessor.currencyLocaleString(value, 0, null, 0) + (unit ? unit : '')
          }`;
      }
      case InputFormat.DATE: {
        return moment(value, DateFormatDash).format(DateFormat);
      }
      case InputFormat.DATE_TIME: {
        return moment(value, DateFormatDash).format(DateTimeFormat);
      }
      case InputFormat.BOOLEAN: {
        return value === true
          ? `<i class="fas fa-check text-success-500"></i>`
          : `<i class="fas fa-times text-danger-500"></i>`;
      }
      case InputFormat.SELECT2: {
        if (options?.select2Option?.bindLabel && options?.select2Option?.bindValue) {
          const matchVal = options.select2Option.options.find(v => value === v[options?.select2Option?.bindValue]);
          return matchVal ? matchVal[options?.select2Option?.bindLabel] : value;
        } else {
          return value;
        }
      }
      default:
        return value;
    }
  }

}
