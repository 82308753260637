import {Injectable} from "@angular/core";
import {BaseApi} from "./base.api";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EncodeDetailApi extends BaseApi {
  prefixURL = 'encode-detail';
  api = environment.apiUrl;
  getAllSiteByStudyID(studyID: number): Observable<any> {
    return this.http.get(this.api + `/select-list/study/${studyID}/sites-restore`);
  }
  getConsentSubject(studyID: number, siteID: number, subjectID: number){
    return this.http.get(this.api + `/encode-detail/${studyID}/${siteID}/${subjectID}`);
  }
  getSubject(siteID: number, subjectCode: any){
    return this.http.get(this.api + `/restore-detail/${siteID}/${subjectCode}`);
  }
  restore(studyID: number, body){
    return this.http.post(this.api + `/study/${studyID}/encode-detail`, body);
  }
}

