import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SORT_CONFIG} from "../../../configs/sort-config";
import {LoadingService} from "../../service/loading.service";
import {GlobalDataService} from "../../../services/global-data.service";
import {EncodeDetailApi} from "../../../services/api-service/encodeDetail.api";
import {count} from "rxjs/operators";

@Component({
  selector: 'app-filter-subject',
  templateUrl: './filter-subject.component.html',
  styleUrls: ['./filter-subject.component.scss']
})
export class FilterSubjectComponent implements OnInit {
  @Input() isSort = false;
  @Input() isAPI = false;
  @Input() fillSort = false;
  @Input() itemsSort = [];
  @Input() siteID: any;
  valueAPI: any;
  @Input() option = {
    filter: '',
    order_by: 'created_at',
    order_dir: 'asc',
  };
  @Input() placeholder = 'COMMON.PLACEHOLDER_SEARCH';

  @Output() applySort: EventEmitter<any> = new EventEmitter();
  @Output() APIVal: EventEmitter<any> = new EventEmitter();
  @Output() closeSort: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalPopupSort') modalPopupSort;
  subject: any;
  popupSort: NgbModalRef;
  sortConfig = SORT_CONFIG;
  order_dir: any;
  order_by: any;
  constructor(protected modalService: NgbModal,
              protected encodeDetailApi: EncodeDetailApi,
              protected loading: LoadingService,
              public globalDataService: GlobalDataService,
  ) { }

  ngOnInit(): void {
    if ( this.option.order_by !== 'encode_time' ){
      this.option.order_by = 'encode_time';
    }
    this.order_dir = this.option.order_dir;
    this.order_by = this.option.order_by;
  }

  onEnter(e): void {
    if (e.keyCode === 13) {
      this.onApplyForm(false);
    }
  }

  // popup sort
  openPopUpSort($target: any = this.modalPopupSort, options = { size: 'md', scrollable: false, centered: false }): void {
    if (!$target){
      $target = this.modalPopupSort;
    }
    this.option.order_dir = this.order_dir;
    this.option.order_by = this.order_by;
    this.popupSort = this.modalService.open($target, options);
  }

  onCloseSort(): void {
    if (this.popupSort)
    {
      this.popupSort.close();
    }
  }

  onApplyForm(API: any): void {
    if (this.siteID){
      this.loading.showAppLoading();
      this.encodeDetailApi.getSubject(this.siteID, this.option.filter).subscribe(data => {
        var subject = data;
        if (data){
          this.encodeDetailApi.getConsentSubject(data['study_id'], data['site_id'], data['subject_id']).subscribe(consent => {
            const subjectConsent =  {subject, consent};
            if (consent){
              this.APIVal.emit(subjectConsent);
              setTimeout(() => {
                this.loading.hideAppLoading();
              }, 500);

            }else {
              const subjectConsent =  null;
              this.APIVal.emit(subjectConsent);
              this.globalDataService.showErrmessage('ER0056');
              setTimeout(() => {
                this.loading.hideAppLoading();
              }, 500);

            }
          }, (error: { success: boolean, message: string }) => {
            this.loading.hideAppLoading();
            const subjectConsent =  null;
            this.APIVal.emit(subjectConsent);
            this.globalDataService.showErrmessage(error.message);
          });
        }else {
          const subjectConsent =  null;
          this.APIVal.emit(subjectConsent);
          this.globalDataService.showErrmessage('ER0056');
          setTimeout(() => {
            this.loading.hideAppLoading();
          }, 500);

        }
        setTimeout(() => {
                      this.loading.hideAppLoading();
                    }, 500);
      }, error => {
        if (!this.option.filter){
              setTimeout(() => {
                this.loading.hideAppLoading();
              }, 500);
          const subjectConsent =  null;
          this.APIVal.emit(subjectConsent);
          }else {
          const subjectConsent =  null;
          this.APIVal.emit(subjectConsent);
          this.globalDataService.showErrmessage('ER0056');
        }
      });

    }
    else {
      if (!this.option.filter){
        this.loading.showAppLoading();
        setTimeout(() => {
          this.loading.hideAppLoading();
        }, 500);
      }else {
        this.globalDataService.showErrmessage('ER0075');
      }
      const subjectConsent =  null;
      this.APIVal.emit(subjectConsent);
    }
      // this.loading.showAppLoading();
      // if (!this.option.filter){
      //     setTimeout(() => {
      //       this.loading.hideAppLoading();
      //     }, 500);
      // }else {
      //   if (!this.option.filter){
      //     setTimeout(() => {
      //       this.loading.hideAppLoading();
      //     }, 500);
      //
      //     const subjectConsent =  null;
      //     this.APIVal.emit(subjectConsent);
      //   }else {
      //     var subject = this.itemsSort.filter(obj => {
      //       return obj.encode.subject_code === this.option.filter;
      //     });
      //     if (subject.length > 0){
      //       this.encodeDetailApi.getConsentSubject(subject[0].encode.study_id, subject[0].encode.site_id, subject[0].encode.id).subscribe(consent => {
      //         const subjectConsent =  {subject, consent};
      //         if (consent){
      //           this.APIVal.emit(subjectConsent);
      //           setTimeout(() => {
      //             this.loading.hideAppLoading();
      //           }, 500);
      //
      //         }else {
      //           const subjectConsent =  null;
      //           this.APIVal.emit(subjectConsent);
      //           this.globalDataService.showErrmessage('ER0056');
      //           setTimeout(() => {
      //             this.loading.hideAppLoading();
      //           }, 500);
      //
      //         }
      //       });
      //     }else {
      //       const subjectConsent =  null;
      //       this.APIVal.emit(subjectConsent);
      //       this.globalDataService.showErrmessage('ER0056');
      //       setTimeout(() => {
      //         this.loading.hideAppLoading();
      //       }, 500);
      //
      //     }
      //   }
      // }
    // this.order_by = this.option.order_by;
    // this.order_dir = this.option.order_dir;
    // this.applySort.emit(this.option);
    // this.onCloseSort();
  }
  onTrim(item: any) {
    if (item) {
      this.option.filter = item.trim();
    }
  }

}
