import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorService {

  private items: Array<any> = [];

  private showHeader = new BehaviorSubject<boolean>(true); // {1}

  private changeLang = new BehaviorSubject<string>('ja');
  listenLanguage = this.changeLang.asObservable();


constructor() { }

  get isShowHeader(): Observable<boolean>{
    return this.showHeader.asObservable(); // {2}
  }

  setHeaderHide(): void{
    this.showHeader.next(false);
  }

  setHeaderShow(): void{
    this.showHeader.next(true);
  }


  get onChangeLanguage(): Observable<string>{
    return this.changeLang.asObservable(); // {2}
  }

  changeLanguage(code: string): void {
    localStorage.setItem('use_lang', code);
    this.changeLang.next(code);
  }

}
