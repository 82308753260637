<div class="take-photo d-flex">
  <div class="icon-img text-center text-primary" (click)="openCamera()"
       *ngIf="!showWebcam || errors.length > 0"><i class="fas fa-camera"></i></div>
  <div *ngIf="!webcamImage && showWebcam && errors.length === 0 && !url" class="text-center m-auto">
    <webcam [height]="height" [width]="width" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (initError)="handleInitError($event)"
    ></webcam>
  </div>
  <div *ngIf="url" class="m-auto">
    <img [height]="height" class="img-thumbnail-pt p-0" [src]="url">
  </div>
</div>
<br/>
<div class="text-center" *ngIf="!isDetail">
  <button  class="btn btn-info mr-2" [disabled]="disabled" (click)="triggerSnapshot();"><i class="fas fa-camera"></i>
  </button>
  <button  class="btn btn-danger" [disabled]="disabled" (click)="toggleWebcam();"><i class="fas fa-sync-alt"></i></button>
</div>

<br/>

