<svg *ngIf="type=='adn' || !type"
     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     style="margin: auto; background: transparent; display: block;" width="80px" height="80px"
     viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="6.451612903225806" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-0.5s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="0s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-0.5s"></animate>
  </circle>
  <circle cx="6.451612903225806" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-0.5s"></animate>
  </circle>
  <circle cx="16.129032258064512" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-0.7s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.2s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-0.7s"></animate>
  </circle>
  <circle cx="16.129032258064512" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.2s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-0.7s"></animate>
  </circle>
  <circle cx="25.806451612903224" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-0.9s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.4s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-0.9s"></animate>
  </circle>
  <circle cx="25.806451612903224" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.4s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-0.9s"></animate>
  </circle>
  <circle cx="35.48387096774193" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.1s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.6s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-1.1s"></animate>
  </circle>
  <circle cx="35.48387096774193" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.6s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-1.1s"></animate>
  </circle>
  <circle cx="45.16129032258064" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.3s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-0.8s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-1.3s"></animate>
  </circle>
  <circle cx="45.16129032258064" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.8s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-1.3s"></animate>
  </circle>
  <circle cx="54.838709677419345" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.5s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-1.5s"></animate>
  </circle>
  <circle cx="54.838709677419345" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.5s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-1.5s"></animate>
  </circle>
  <circle cx="64.51612903225805" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.7s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.2s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-1.7s"></animate>
  </circle>
  <circle cx="64.51612903225805" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.7s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.2s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-1.7s"></animate>
  </circle>
  <circle cx="74.19354838709677" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-1.9s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.4s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-1.9s"></animate>
  </circle>
  <circle cx="74.19354838709677" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.9s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.4s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-1.9s"></animate>
  </circle>
  <circle cx="83.87096774193547" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.1s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.6s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-2.1s"></animate>
  </circle>
  <circle cx="83.87096774193547" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-3.1s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.6s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-2.1s"></animate>
  </circle>
  <circle cx="93.54838709677418" cy="50" r="3" fill="#c5523f">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-2.3s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-1.8s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#c5523f;#f2b736;#c5523f" dur="2s" repeatCount="indefinite"
             begin="-2.3s"></animate>
  </circle>
  <circle cx="93.54838709677418" cy="50" r="3" fill="#499255">
    <animate attributeName="r" times="0;0.5;1" values="2.4000000000000004;3.5999999999999996;2.4000000000000004"
             dur="2s" repeatCount="indefinite" begin="-3.3s"></animate>
    <animate attributeName="cy" keyTimes="0;0.5;1" values="32;68;32" dur="2s" repeatCount="indefinite" begin="-2.8s"
             keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline"></animate>
    <animate attributeName="fill" keyTimes="0;0.5;1" values="#499255;#1875e5;#499255" dur="2s" repeatCount="indefinite"
             begin="-2.3s"></animate>
  </circle>
</svg>


<svg *ngIf="type=='round'"
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     style="margin: auto; background: transparent; display: block;" width="80px" height="80px"
     viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="75" cy="50" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.9166666666666666s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
  </circle>
  <circle cx="71.65063509461098" cy="62.5" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.8333333333333334s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
  </circle>
  <circle cx="62.5" cy="71.65063509461096" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.75s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
  </circle>
  <circle cx="50" cy="75" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.6666666666666666s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
  </circle>
  <circle cx="37.50000000000001" cy="71.65063509461098" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.5833333333333334s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
  </circle>
  <circle cx="28.34936490538903" cy="62.5" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.5s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
  </circle>
  <circle cx="25" cy="50" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.4166666666666667s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
  </circle>
  <circle cx="28.34936490538903" cy="37.50000000000001" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.3333333333333333s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
  </circle>
  <circle cx="37.499999999999986" cy="28.349364905389038" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.25s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
  </circle>
  <circle cx="49.99999999999999" cy="25" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.16666666666666666s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
  </circle>
  <circle cx="62.5" cy="28.349364905389034" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="-0.08333333333333333s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
  </circle>
  <circle cx="71.65063509461096" cy="37.499999999999986" fill="#689cc5" r="5">
    <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
             begin="0s"></animate>
    <animate attributeName="fill" values="#689cc5;#689cc5;#93dbe9;#689cc5;#689cc5" repeatCount="indefinite"
             times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
  </circle>
</svg>
