import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { count } from 'rxjs/operators';
import { AuthService } from './api-service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(
    private translate: TranslateService,
    private authService: AuthService
  ) {
  }

  getErrorsMessage(messageError: any, screenCode = null, join = '.'): any{
    let arrayMessage = [];
    if (typeof messageError === 'string'){
      arrayMessage.push(messageError);
    }else{
      arrayMessage = messageError;
    }

    const message = arrayMessage.map(val => this.getErrorMessage(val, screenCode));
    return join ? message.join('\n') : message;
  }

  getErrorMessage(keyMessage: string, screenCode = null): string{
    const code = keyMessage.split(':');
    const errorCode = code[0];
    let fieldName = '';
    let fieldText = '';
    let property = '';
    if (code.length >= 2){
      fieldName = code[1];
    }
    if (code.length >= 3){
      property = code[2];
    }

    if (fieldName){
      fieldText = this.getFieldText(fieldName, screenCode);
      if (fieldText === '' && fieldName.indexOf(' ') > 0){
        fieldName = fieldName.replace(/\s+/g, '_');
        fieldText = this.getFieldText(fieldName, screenCode);
      }
    }

    return this.getMessage(errorCode, screenCode, fieldText, property);
  }

  getMessage(errorCode, screenCode = null, attribute = '', property = ''): string{
    let message = '';
    const option = { attribute, property };
    if (screenCode){
      const keyScreenError = (`${screenCode}.ERRORS.${errorCode}`).toUpperCase();
      message = this.translate.instant(keyScreenError, option);
      if (message !== keyScreenError && message !== ''){
        return message;
      }
      const keyScreen = (`${screenCode}.${errorCode}`).toUpperCase();
      message = this.translate.instant(keyScreen, option);
      if (message !== keyScreen && message !== ''){
        return message;
      }
    }

    const keyCommonError = (`COMMON.ERRORS.${errorCode}`).toUpperCase();
    message = this.translate.instant(keyCommonError, option);
    if (message !== keyCommonError && message !== ''){
      return message;
    }

    const keyCommon = (`COMMON.${errorCode}`).toUpperCase();
    message = this.translate.instant(keyCommonError, option);
    if (message !== keyCommonError && message !== ''){
      return message;
    }

    const keyError = (`ERRORS.${errorCode}`).toUpperCase();
    message = this.translate.instant(keyError, option);
    if (message !== keyError && message !== ''){
      return message;
    }

    return this.translate.instant((`${errorCode}`).toUpperCase(), option);
  }

  /**
   * Get text field
   * @param fieldName : string
   * @param screenCode : string
   * @returns string
   */
  getFieldText(fieldName: string | any, screenCode = null): string{
    let message = '';
    if (screenCode){
      const keyScreenFields = (`${screenCode}.FIELDS.${fieldName}`).toUpperCase();
      message = this.translate.instant(keyScreenFields);
      if (message !== keyScreenFields && message !== ''){
        return message;
      }
      const keyScreen = (`${screenCode}.${fieldName}`).toUpperCase();
      message = this.translate.instant(keyScreen);
      if (message !== keyScreen && message !== ''){
        return message;
      }
    }

    const keyCommon = (`COMMON.${fieldName}`).toUpperCase();
    message = this.translate.instant(keyCommon);
    if (message !== keyCommon && message !== ''){
      return message;
    }

    const keyField = (`FIELDS.${fieldName}`).toUpperCase();
    message = this.translate.instant(keyField);
    if (message !== keyField && message !== ''){
      return message;
    }

    message = this.translate.instant((`${fieldName}`).toUpperCase());
    if (message === (`${fieldName}`).toUpperCase()){
      return '';
    }
  }
}
