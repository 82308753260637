export type TypeFilter = 'select' | 'date' | 'number' | 'boolean' | 'string';

export enum InputFormat {
  SELECT_SEARCH = 'select-search',
  SELECT2 = 'select2',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
  FLOAT = 'float',
  TEXTAREA = 'textarea',
  DATE_TIME = 'date_time',
  BUTTON = 'button',
  TEXT = 'text',
  DATE_PICKER = 'date_picker',
  BUTTONCIRCLE = 'button_circle',
  SELECT_CONDITION = 'select_condition',
  SELECT_TYPE = 'select_type',
  SELECT_DATA = 'select_data'
}

export enum ValueMaxLength {
  CODE = 20,
  STRING = 255,
  INTEGER = 10,
  BIGINT = 19,
  TEXT = 200,
}

export enum MaxValue {
  INTEGER = 2147483647,
  BIGINT = 9223372036854775807,
  NUMERIC16 = 9999999999999999,
  NUMERIC3 = 999,
}

export enum DefaultValueNumber {
  EMPTY = '',
  ZERO = '0',
  ONE = '1',
}

export enum TypeValue {
  INTEGER = 'INTEGER',
  STRING = 'STRING',
}
