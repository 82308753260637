import {Directive, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {

  @Output() scrollingFinished = new EventEmitter<void>();

  emitted = false;

  @HostListener('scroll', ['$event'])
  onScroll(event): void {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else if ((window.innerHeight + window.scrollY) < document.body.offsetHeight) {
      this.emitted = false;
    }
  }

}
