import { TableHeaderModel } from './table-header.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ConfirmationModalService } from '../share/service/modal/confirmation-modal.service';
import { SortableDirective, SortEvent } from '../share/directives/sortable.directive';
import { PagingConfig } from '../configs/paging-config';
import { BaseSearchParam } from './base-search-param.model';
import {Directive, Input, QueryList} from '@angular/core';
import { BaseListReadonly } from './base-list-readonly';
import { QuickAccessForm } from '../share/utils/quick-access-form';
import { tap } from 'rxjs/operators';
import {URL_LIST} from "src/app/configs/url-list";
import {Router} from "@angular/router";
import {LoadingService} from "../share/service/loading.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "../services/global-data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BaseApi} from "../services/api-service/base.api";
import {HandleError, HandleErrorModel} from "./handle-error.model";
import {APP_CONFIG} from "../configs/app-config";

@Directive({})
export abstract class BaseList extends BaseListReadonly {


  formList: Array<{ [klass: string]: any, _form: FormGroup }> = [];
  columnUpperCase = ['id'];

  isShow = true;


  constructor(
    protected mainAPIService: BaseApi,
    protected router: Router,
    protected loading: LoadingService,
    protected confirmPopup: ConfirmationModalService,
    protected translate: TranslateService,
    protected globalDataService: GlobalDataService,
    protected ngbModal: NgbModal,
    protected callGetList: boolean = true
  ) {
    super(mainAPIService, router, loading, confirmPopup, translate, globalDataService, ngbModal, callGetList);

    if (callGetList){
      if (this.selectSearchMode) {
        this.searchParam.limit = 25;
      } else {
        this.globalDataService.setComponentActive(this.screenId);
        this.globalDataService.callBackSaveChange(this.screenId, (): Observable<boolean> => {
          return new Observable<any>(subscriber => {
            this.save().subscribe((status) => {
              subscriber.next(status);
              subscriber.complete();
            });
          });
        });
      }
    }
  }

  // region update checked box
  get isSelectedAll(): boolean {
    return this.formList.length && this.formList.every(it => it?.checked?.value);
  }

  get isSelectedNone(): boolean {
    return this.formList.length && this.formList.every(it => !it?.checked?.value);
  }

  setSelectAll(val: boolean): void {
    this.formList.map((v) => {
      v.checked.setValue(val);
      return v;
    });
  }

  setSelectOneRow(item: FormControl, value: boolean): void {
    item.setValue(value);
  }

  beginSave() { }
  beforeConfirm(countItem: any) { return countItem; }
  beforeSave(data: any) { return data; }
  saveSuccess(res: any) { return res; }
  saveFail(err: HandleErrorModel): HandleErrorModel { return err; }

  save(): Observable<boolean> {
    this.beginSave();
    return new Observable<any>(subscriber => {
      this.formList.map(v => {
        v._form.markAllAsTouched();
        if (v._form.pristine) {
          return;
        }
      });

      if (this.isFormError) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      if (this.saveData.length !== 0) {
        // get message change
        let countItem = this.countItemSave();
        let mess = [];
        if (countItem.add) {
          mess.push(this.app_config.getMessTranslate(this.translate, this.currentObj, 'CONFIRM_SAVE.ADD', {number: countItem.add}));
        }
        if (countItem.update) {
          mess.push(this.app_config.getMessTranslate(this.translate, this.currentObj, 'CONFIRM_SAVE.UPDATE', {number: countItem.update}));
        }
        if (countItem.del) {
          mess.push(this.app_config.getMessTranslate(this.translate, this.currentObj, 'CONFIRM_SAVE.DEL', {number: countItem.del}));
        }
        let str_mess = mess.join(", ") + ". " + this.app_config.getMessTranslate(this.translate, this.currentObj, 'CONFIRM_SAVE.CONFIRM');

        // show message change and confirm save

        countItem = this.beforeConfirm(countItem);
        this.confirmPopup.confirm(this.app_config.getMessTranslate(this.translate, this.currentObj, 'CONFIRM_SAVE.TITLE'), str_mess,
          this.app_config.getMessTranslate(this.translate, this.currentObj, 'COMMON.CANCEL'), '',
          this.app_config.getMessTranslate(this.translate, this.currentObj, 'COMMON.SAVE'), () => {
            let data = this.beforeSave(this.saveData);
            this.mainAPIService.saveAll(data).subscribe((res) => {
              this.saveSuccess(res);
              this.globalDataService.clearForm();

              // show success save
              this.confirmPopup.success(this.app_config.getMessTranslate(this.translate, this.currentObj, 'COMMON.SAVE_SUCCESSFUL'));
              this.onReload(subscriber);
            }, (er: HandleErrorModel) => {
              let err = this.saveFail(er);
              subscriber.next(false);
              subscriber.complete();
              const handleError = new HandleError(err);
              handleError.checkAuthen(err, handleError, this.currentObj.toUpperCase(), this.translate, this.confirmPopup);
            });
          });
      }

    });
  }

  getErrorControlMsg(form: FormGroup, controlName: string, errors: Array<string>): string {
    return QuickAccessForm.getErrorControlMsg(form, controlName, errors, this.currentObj, this.translate);
  }


  selectSearchChosen(item: any): void {
    if (this.selectSearchMode) {
      this.selectOneRow.emit(item);
    }
  }

  actionSave() {
    this.save().subscribe((status) => {
      if (status) {
        this.disableAddButton = false;
        this.getList();
      }
    });
  }


  // region create from
  buildForm(val?): any {
    const formList = {
      checked: new FormControl(false),
      _form: new FormGroup({
        isNew: new FormControl(!val),
        isDeleted: new FormControl(false)
      }),
    };
    setTimeout(() => {
      formList._form.markAsPristine();
    });
    return formList;
  }

  // endregion

  // region confirm popup for delete
  confirmDeletePopup(confirmPop: ConfirmationModalService, message: string, form?: FormGroup, fieldName = 'id'): Observable<boolean> {
    return new Observable(subscriber => {
      if (form) {
        this.isConfirmDelete(form, fieldName).subscribe((isConfirm: boolean) => {
          if (isConfirm) {
            confirmPop.confirm(
              '',
              message,
              'COMMON.CANCEL',
              null,
              'COMMON.DELETE',
              () => {
                subscriber.next();
                subscriber.complete();
              });
          } else {
            subscriber.next();
            subscriber.complete();
          }
        });
      } else {
        confirmPop.confirm(
          '',
          message,
          'COMMON.CANCEL',
          null,
          'COMMON.DELETE',
          () => {
            subscriber.next();
            subscriber.complete();
          });
      }
    });
  }

  isConfirmDelete(item: FormGroup, fieldName = 'id'): Observable<boolean> {
    return new Observable(subscriber => {
      if (item.get(fieldName).value) {
        subscriber.next(true);
        subscriber.complete();
      } else {
        if (item.pristine) {
        }
        subscriber.next(item.dirty);
        subscriber.complete();
      }
    });
  }

  // endregion

  // region getList

  getList(): void {
    if (this.selectSearchMode) {
      this.getListWithoutCheck();
    } else {
      this.globalDataService.checkFormChange(this.screenId).subscribe((allowChange) => {
        if (allowChange) {
          this.getListWithoutCheck();
        }
      });
    }
  }

  getListWithoutCheck() {
    this.loading.showAppLoading();
    this.mainAPIService.getAll(this.searchParam)
      .subscribe(res => {
        this.loading.hideAppLoading();
        if (!this.selectSearchMode) {
          this.globalDataService.clearForm();
        }

        this.formList = res.data.map(val => {
          return this.buildForm(val);
        });
      }, (er: HandleErrorModel) => {
        this.loading.hideAppLoading();
        const handleError = new HandleError(er);
        handleError.checkAuthen(er, handleError, this.currentObj , this.translate, this.confirmPopup);
      });
  }


  // region action in form
  addRow(): void {
    this.disableAddButton = true;
    this.formList.unshift(this.buildForm());
  }

  deleteOne(form: FormGroup, idx: number): void {
    form.get('isDeleted').setValue(true);
    if (this.formList[idx]._form.get('isNew').value) {
      this.disableAddButton = false;
      this.formList.splice(idx, 1);
    }
    this.emitDeleteOne();
  }

  emitDeleteOne(): void {
  }

  deleteManyForm(): void {
    this.formList.map((v, index: number) => {
      if (v.checked.value && !v._form.get('isNew').value) {
        this.deleteOne(v._form, index);
      }
    });
    this.formList = this.formList.filter(v => !(v._form.value.isNew && v.checked.value));
  }



  get saveData(): Array<any> {
    return this.formList.filter((f) => f._form.dirty || f._form.get('isDeleted').value)
      .map((v) => {
        return v._form.getRawValue();
      });
  }

  // endregion

  get isChangeData(): boolean {
    return this.formList.findIndex((f) => f._form.dirty) >= 0;
  }

  get isFormError(): boolean {
    return this.formList.findIndex((f) => f._form.invalid) >= 0;
  }

  isShowErrorControl(form: FormGroup, controlName: string): boolean {
    return QuickAccessForm.isShowErrorControl(form, controlName);
  }

  getDataDuplicate(id = 'code'): string {
    const duplicateData = this.formList.filter((v1, index1, self) => {
      return self.findIndex((v2, index2) =>
        String(v1._form.get(id).value).toLowerCase() === String(v2._form.get(id).value).toLowerCase()
        && v1._form.get('isNew').value
        && index2 > index1
      ) >= 0;
    });
    return duplicateData.map(v => v._form.get(id).value)
      .filter((elem, index, self) => index === self.indexOf(elem))
      .join('、');
  }

  countItemSave(): any {
    let del = 0;
    let add = 0;
    let update = 0;
    this.formList.forEach((f) => {
      if (f._form.get('isNew').value) {
        add++;
        return;
      }
      if (f._form.get('isDeleted').value) {
        del++;
        return;
      }
      if (f._form.dirty) {
        update++;
        return;
      }
    });
    return { del, add, update };
  }

  markAllAsPristine() {
    this.formList.filter((f) => f._form.dirty || f._form.get('isDeleted').value)
      .map((v) => {
        return v._form.markAsPristine();
      });
  }

  markAllAsTouched(): void {
    this.formList.map((v) => {
      return v._form.markAllAsTouched();
    });
  }

  onCancel() {
    this.globalDataService.clearForm();
    this.onRevert();
  }

  onRevert(): void {
    this.formList = this.originalData.map(e => {
      return this.buildForm(e);
    })
  }

}
