import { BaseInterface, BaseModel } from './base.model';

///// ConSent
export interface ConsentModel extends BaseInterface {
    id: number;
    study_id: number;
    document_id: number;
    subject_id: number;
    subject_portrait_image: string;
    site_id: number;
    subject_comment: string;
    subject_name: string;
    subject_sign: string;
    subject_sign_date: string;
    doctor_sign: string;
    doctor_name: string
    date_complete: string
    sign_off_date: string
    subject_accept: boolean;
    status: number;
    doctor_comment: string;

    sections: Array<ConsentSectionModel>;
}

export class Consent extends BaseModel implements ConsentModel {
    id = 0;
    study_id = 0;
    document_id = 0;
    subject_id = 0;
    subject_portrait_image = '';
    site_id = 0;
    subject_comment = '';
    subject_name = '';
    subject_sign = '';
    subject_sign_date = '';
    doctor_sign = '';
    subject_confirm = '';
    doctor_accept = false;
    sections = [];
    doctor_name = '';
    date_complete = null;
    sign_off_date = null;
    subject_accept = false;
    owner_confirm = false;
    status = 2;
    doctor_comment = '';
    subject_reconfirm = false;
    subject_relationship= 0;
    subject_represent_name = '';

    /*A-3-3 Download all begin*/
    file_pdf = null;
    /*A-3-3 Download all end*/
    constructor(props?: ConsentModel) {
        super(props);
        this.syncProps(props);
    }

    toObject(): any{
        return {
            id: this.id,
            study_id: this.study_id,
            document_id: this.document_id,
            subject_id: this.subject_id,
            subject_portrait_image: this.subject_portrait_image,
            site_id: this.site_id,
            subject_comment: this.subject_comment,
            subject_name: this.subject_name,
            subject_sign: this.subject_sign,
            subject_sign_date: this.subject_sign_date,
            doctor_sign: this.doctor_sign,
            sections: this.sections,
            doctor_name: this.doctor_name,
            date_complete: this.date_complete,
            sign_off_date: this.sign_off_date,
            subject_accept: this.subject_accept,
            doctor_accept: this.doctor_accept,
            subject_confirm: this.subject_confirm,
            owner_confirm: this.owner_confirm,
            status: this.status,
            doctor_comment: this.doctor_comment,
            subject_reconfirm: this.subject_reconfirm,
            subject_relationship: this.subject_relationship,
            subject_represent_name: this.subject_represent_name,
            /*A-3-3 Download all begin*/
            file_pdf: this.file_pdf
            /*A-3-3 Download all end*/
        };
    }
}

///// ConSentSection
export interface ConsentSectionModel extends BaseInterface {
    id: number;
    study_id: number;
    consent_id: number;
    section_id: number;
    name: string;
    status: number;
    footer_sign: string;
    footer_date: string;
    footer_text: string;
    submit_time: string;
    items: Array<ConsentItemModel>;
}

export class ConsentSection extends BaseModel implements ConsentSectionModel {
    id = 0;
    study_id = 0;
    consent_id = 0;
    section_id = 0;
    name = '';
    status = 0;
    footer_sign = '';
    footer_date = null;
    footer_text = '';
    items = [];
    submit_time = '';

    constructor(props?: ConsentSectionModel) {
        super(props);
        this.syncProps(props);
    }
}

///// ConSentItem
export interface ConsentItemModel extends BaseInterface {
    id: number;
    study_id: number;
    consent_id: number;
    consent_section_id: number;
    item_id: number;
    value: string;
    type: string;
  }

export class ConsentItem extends BaseModel implements ConsentItemModel {
    id = 0;
    study_id = 0;
    consent_id = 0;
    consent_section_id = 0;
    item_id = 0;
    value = '';
    type = '';

    constructor(props?: ConsentItemModel) {
      super(props);
      this.syncProps(props);
    }
  }
