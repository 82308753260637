<div class="dropdown">
  <div class="" [ngClass]="class">
    <div class="inner-addon right-addon px-2 py-1 dropdown-gds-select" >
      <ng-container *ngIf="selectedVal.length">
        <ng-container *ngFor="let val of selectedVal; let i = index">
          <div class="tags-selected">
            <span class="tag-name">{{val.colLabel}}</span>
            <button class="remove-tag" type="button" (click)="clearVal(i)"><i class="fa fa-times-circle"></i></button>
          </div>
        </ng-container>
      </ng-container>

      <button class="btn btn-sm btn-primary btn-fill toggle-select rounded-circle" *ngIf="selectedVal.length < maxValue" (click)="toggleDrop(true)">
          <i class="fa fa-plus"></i>
        </button>
    </div>
  </div>

  <div class="dropdown-menu p-1" [class]="{'show': showDrop.value}" (scroll)="dropListScroll($event)">
    <input class="form-control form-control-sm custom-size" [formControl]="searchKey">
    <a class="dropdown-item" *ngFor="let opt of optionList" (click)="chooseOpt(opt)" (keyup.enter)="chooseOpt(opt)"
       tabindex="0">
      <span class="custom-size">{{opt[option.colLabel]}}</span>
    </a>
  </div>
</div>
