import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, OnDestroy, SimpleChanges, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/share/service/loading.service';
import { BaseApi } from 'src/app/services/api-service/base.api';
import { MasterSelectSearch } from 'src/app/configs/master-select-search';
import { SelectSearchOption, SelectSearchOptionModel } from 'src/app/models/select-search-option.model';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import { BaseSearchParam, BaseSearchParamInterface } from 'src/app/models/base-search-param.model';
import { OperatorFilter } from 'src/app/configs/filter-setting';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnChanges, OnDestroy {

  @Input() itemSelected: any = null;
  @Input() option: SelectSearchOptionModel;
  @Input() allowScrollPage = true;
  @Output() eventSelect = new EventEmitter<any>();

  @ViewChild('modalContent') modalContent;

  popup: NgbModalRef;
  masterSelectSearch = MasterSelectSearch;

  selected: any = {value: 0, text: ""};
  isLoading: boolean = false;

  searchKey = new FormControl('');
  totalRecord = 0;

  showDrop = new BehaviorSubject<boolean>(false);
  private sub;
  optionList: Array<any> = [];
  private searchParams: BaseSearchParamInterface = new BaseSearchParam();

  constructor(
    private baseApi: BaseApi,
    protected router: Router,
    protected loading: LoadingService,
    protected translate: TranslateService,
    protected ngbModal: NgbModal,
    private el: ElementRef,
  ) {
    
  }

  ngOnInit() {
    if(this.itemSelected){
      this.selected.value =  this.itemSelected[this.option.objectSelectValue];
      this.selected.text =  this.itemSelected[this.option.objectSelectLabel];
    }

    this.showDrop.pipe(
      distinctUntilChanged(),
      switchMap((flag) => {
        if (!flag) {
          // nếu biến show drop = false -> đóng popup
          if (this.sub) {
            this.optionList = [];
            this.sub.unsubscribe();
          }
          return new Observable<any>(subscriber => {
            subscriber.next();
            subscriber.complete();
          });
        }
        // mặc định: khi mở popup sẽ call api để data
        return this.search(this.searchKey.value);
      }))
      .subscribe(() => {
      });

    // bắt sự kiện mỗi khi biến "searchKey thay đổi giá trị";
    // `distinctUntilChanged` và `switchMap`: tương tự với showDrop bên trên
    // debounceTime: đợi ~500ms để người dùng dừng thao tác mới bắt đầu gọi api
    this.searchKey.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((valueChanges) => this.search(valueChanges)))
      .subscribe(() => {
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.option && changes.option.currentValue) {
      this.option = new SelectSearchOption(changes.option.currentValue);
    }
  }

  // search api
  toggleDrop(show?): void {
    this.searchKey.setValue('', {emitEvent: false});
    
    if (show) {
      this.showDrop.next(true);
    } else {
      this.showDrop.next(!this.showDrop.value);
    }

    if (this.showDrop) {
      // event click outside this component
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.showDrop.next(false);
        }
      });
    }
  }

  search(val?): Observable<any> {
    if (!this.option.apiUrl || !this.option.colValue || !this.option.colLabel) {
      return new Observable<any>(subscriber => {
        subscriber.next();
        subscriber.complete();
      });
    }
    this.optionList = [];
    this.searchParams.fields = this.option.colLabel;
    this.searchParams.conds =  OperatorFilter.CONTAINS;
    this.searchParams.values =  val;
    this.searchParams.page = 0;

    return this.baseApi.get(this.option.apiUrl, this.searchParams)
      .pipe(tap((res) => {
        if(res?.data){
          this.optionList = res.data;
          this.totalRecord = res.totalRecords;
        }else this.optionList = res;
      }));
  }

  dropListScroll($event): void {
    if (!this.allowScrollPage) return;
    const el = $event.srcElement;
    if (el.scrollTop + el.offsetHeight < el.scrollHeight - 30) {
      return;
    }

    if (this.optionList.length < this.totalRecord && this.isLoading == false) {
      this.searchParams.page++;
      this.isLoading = true;
      this.baseApi.get(this.option.apiUrl, this.searchParams)
        .subscribe((res) => {
          this.isLoading = false;
          this.optionList = this.optionList.concat(res.data);
          this.totalRecord = res.totalRecords;
        });
    }
  }

  chooseOpt($event){
    this.selected.value = $event[this.option.colValue];
    this.selected.text = $event[this.option.colLabel];

    this.toggleDrop(false);

    this.emitData();
  }

  emitData(): void{
    let obj = {};
    obj[this.option.objectSelectValue] = this.selected.value;
    obj[this.option.objectSelectLabel] = this.selected.text;
    this.eventSelect.emit(obj);
  }


  // popup select

  openPop(): void {
    this.popup = this.ngbModal.open(this.modalContent, {size: '', scrollable: false, centered: false});
  }

  onSelectPopup($event): void{
    if($event.value == 1){
      this.itemSelected = $event.item;
      this.selected.value = $event.item[this.option.objectSelectValue];
      this.selected.text = $event.item[this.option.objectSelectLabel];
    }else{
      this.itemSelected = null;
      this.selected.value = null;
      this.selected.text = null;
    }
    this.eventSelect.emit(this.itemSelected);
  }

  clearData(): void{
    this.selected.value = "";
    this.selected.text = "";
    this.emitData();
  }

  closePopup(){
    this.popup.close();
  }


  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.showDrop) {
      this.showDrop.unsubscribe();
    }
  }

}
