<button class="btn btn-custom btn-outline mr-2 my-1" (click)="openModalExport()"
        [ngClass]="{'btn-lg': btnSize=='lg', 'btn-xs': btnSize=='xs'}">
  <img class="w-20px" src="assets/icons/export.svg">
  {{"COMMON.EXPORT" | translate}}
</button>


<ng-template #modalExport let-modal>
  <div class="modal-header d-none">
    <h5 class="modal-title"></h5>
  </div>
  <div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div>
      <input class="mr-2" id="all" type="radio" value="all" [(ngModel)]="export_column"/>
      <label for="all">{{'EXPORT_FILE.ALL_ITEMS' | translate}}</label>
    </div>
    <div class="mt-1">
      <input class="mr-2" id="selected" type="radio" value="selected" [(ngModel)]="export_column"/>
      <label for="selected">{{'EXPORT_FILE.ONLY_DISPLAYED_ITEMS' | translate}}</label>
    </div>
    <div class="mt-2">
      <input class="mr-2" id="checkbox" type="checkbox" [(ngModel)]="export_template"/>
      <label for="checkbox">{{'EXPORT_FILE.TEMPLATE_ONLY' | translate}}</label>
    </div>
  </div>
  <div class="modal-footer d-block text-center">
    <button class="btn m-1 btn-primary" (click)="exportCSV()">
      {{'EXPORT_FILE.BTN_EXPORT' | translate}}
    </button>
    <button class="btn btn btn-outline m-1" (click)="closeModal()">
      {{'EXPORT_FILE.BTN_CANCEL' | translate}}
    </button>
  </div>
</ng-template>
