import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {DateFormat} from '../../configs/date-time-config';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = moment(value, DateFormat);
      return {
        day: date.date(),
        month: date.month() + 1, // month of moment start from 0
        year: date.year()
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? moment().date(date.day).month(date.month - 1).year(date.year).format(DateFormat) : '';
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = moment(value, DateFormat);
      return {
        day: date.date(),
        month: date.month() + 1, // month of moment start from 0
        year: date.year()
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? moment().date(date.day).month(date.month - 1).year(date.year).format(DateFormat) : '';
  }
}
