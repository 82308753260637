import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { AuthService } from '../../services/api-service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardHelper implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private globalDataService: GlobalDataService,
    private modalService: NgbModal
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = route.pathFromRoot
          .map(v => v.url.map(segment => segment.toString()).join('/'))
          .join('/');
    this.globalDataService.zoneURL = path.indexOf('manager') >= 0 ? 'manager' : (path.indexOf('tablet-app') >= 0 ? 'tablet-app' : 'admin');
    localStorage.setItem('zoneURL', this.globalDataService.zoneURL);
    console.log('zoneURL', this.globalDataService.zoneURL);
    if (this.authService.currentUserValue) {
      if (this.authService.token) {
        return new Observable<any>(subscriber => {
          this.globalDataService.checkFormChange('auth guard').subscribe((allowChange) => {
            if (allowChange) {
              subscriber.next(allowChange);
              subscriber.complete();
            } else {
              history.go(1);
            }
          });
        });
      } else {
        this.modalService.dismissAll();
        this.globalDataService.clearForm();
        this.authService.gotoLogin(this.globalDataService.zoneURL);
      }
    } else if (this.authService.token) {
      return this.checkLogin(route);
    } else {
      this.modalService.dismissAll();
      this.globalDataService.clearForm();
      this.authService.gotoLogin(this.globalDataService.zoneURL);
    }

  }

  private checkLogin(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable(subscriber => {
      this.authService.getUserInfo().subscribe((userSession) => {
        if (userSession) {
          subscriber.next(true);
        }
      }, () => {
        this.modalService.dismissAll();
        this.globalDataService.clearForm();
        this.authService.gotoLogin(this.globalDataService.zoneURL);
        subscriber.next(false);
      }, () => {
        subscriber.complete();
      });
    });
  }
}
