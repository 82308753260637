<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-body" *ngIf="data.content">
      <div class="text-center fa-3x">
        <i class="text-success-500 far fa-check-circle" *ngIf="data.icon==='success'"></i>
        <i class="text-danger-500 far fa-times-circle" *ngIf="data.icon==='error'"></i>
        <i class="text-warning-500 fas fa-exclamation-circle" *ngIf="data.icon==='warning'"></i>
      </div>
      <h5 class="modal-title text-center mb-4 mt-4" *ngIf="data.title">{{data.title}}</h5>
      <p class="dialog-content text-center white-space-text mb-4 mt-4" [innerHTML]="data.content"></p>
      <div class="form-group" *ngIf="(data.extraInput === 'documents') && isShowForm" [formGroup]="formGroupAdd">
        <label>{{'DOCUMENTS.DOCUMENT_DETAIL.CONFIRM' | translate}}</label>「<label class="label-required">{{'COMMON.REQUIRED_LABEL' | translate}}</label>」
        <input type="text" formControlName="confirm" [appTooltip]="formGetErrorControlMsg('confirm', ['required'])"
               [ngClass]="{'border border-danger': formIsShowErrorControl('confirm')}"
               [attr.disabled]="!globalDataService.hasAllowAddDocument || releaseActive ?true:null"
               placeholder="{{'DOCUMENTS.DOCUMENT_DETAIL.CONFIRM' | translate}}" class="form-control" maxlength="255"
               (blur)="onTrim('confirm')" />
      </div>

      <div class="form-group" *ngIf="(data.extraInput === 'studies') && isShowForm" [formGroup]="formGroupAdd">
        <label>{{'STUDIES.STUDY_DETAIL.CONFIRM' | translate}}</label>「<label class="label-required">{{'COMMON.REQUIRED_LABEL' | translate}}</label>」
        <input type="text" formControlName="confirm" [appTooltip]="formGetErrorControlMsg('confirm', ['required'])"
               [ngClass]="{'border border-danger': formIsShowErrorControl('confirm')}"
               placeholder="{{'STUDIES.STUDY_DETAIL.CONFIRM' | translate}}" class="form-control" maxlength="255"
               (blur)="onTrim('confirm')" />
      </div>
    </div>
    <div class="modal-footer justify-content-center p-2" *ngIf="data.exitText || data.saveText || data.cancelText">
      <button *ngIf="data.saveText" #elPopupSave class="btn m-1" (click)="saveModal()"
        [ngClass]="data.saveText === ('COMMON.DELETE' | translate) ? 'btn-danger' : 'btn-primary'">
        {{data.saveText}}
      </button>
      <button *ngIf="data.exitText" #elPopupExit class="btn btn btn-outline m-1" (click)="exitModal()">
        {{data.exitText}}
      </button>
      <button *ngIf="data.cancelText" #elPopupCancel class="btn btn btn-outline m-1" (click)="cancelModal()">
        {{data.cancelText}}
      </button>
    </div>
  </div>
</div>
