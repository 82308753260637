import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {SelectSearchOption, SelectSearchOptionModel} from '../../../models/select-search-option.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {BaseApi} from '../../../services/api-service/base.api';
import {BaseSearchParam, BaseSearchParamInterface} from '../../../models/base-search-param.model';
import {OperatorFilter} from '../../../configs/filter-setting';
import {MasterSelectSearch} from '../../../configs/master-select-search';

const ACTIVE_STATUS = 'status=ACTIVE';

@Component({
  selector: 'app-select-search-with-popup',
  templateUrl: './select-search-with-popup.component.html',
  styleUrls: ['./select-search-with-popup.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSearchWithPopupComponent),
      multi: true,
    },
  ],
})
export class SelectSearchWithPopupComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  // region VARIABLES
  @ViewChild('modalContent') modalContent;
  @ViewChild('selectSearch') selectSearch;
  @Input() class: string | string[] | Set<string> | { [klass: string]: any; };
  @Input() options: SelectSearchOptionModel;
  @Output() choose = new EventEmitter<object>();
  @Output() onTouch = new EventEmitter<object>();
  @Input() isClearVal = true;
  @Input() allowScrollPage = true;
  @Input() flagProduct = true;
  @Input() isMultipleSelect = false;
  // default option
  option: SelectSearchOptionModel = new SelectSearchOption();
  popup: NgbModalRef;
  selectedVal = new FormControl('');
  showDrop = new BehaviorSubject<boolean>(false);
  searchKey = new FormControl('');
  optionList: Array<any> = [];
  totalRecord = 0;
  isLoading = false;

  private sub;

  masterSelectSearchScreen = MasterSelectSearch;

  private searchParams: BaseSearchParamInterface = new BaseSearchParam();
  private onChangeModel = (v: any) => {
  };
  private onTouchModel = (v: any) => {
  };

  // endregion
  // region CONSTRUCTOR

  constructor(
    private ngbModal: NgbModal,
    private el: ElementRef,
    private baseApi: BaseApi,
  ) {
  }

  // endregion
  // region LIFE CYCLE
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      this.option = new SelectSearchOption(changes.options.currentValue);
    }
  }

  ngOnInit(): void {
    // region Dropdown search
    // bắt sự kiện mỗi khi biến "showDrop thay đổi giá trị"
    // Sử dụng `distinctUntilChanged` và `switchMap` để huỷ sự kiện nếu "cố tình" changeValue liên tục
    this.showDrop.pipe(
      distinctUntilChanged(),
      switchMap((flag) => {
        if (!flag) {
          // nếu biến show drop = false -> đóng popup
          if (this.sub) {
            this.optionList = [];
            this.sub.unsubscribe();
          }
          return new Observable<any>(subscriber => {
            subscriber.next();
            subscriber.complete();
          });
        }
        // mặc định: khi mở popup sẽ call api để data
        return this.search(this.searchKey.value);
      }))
      .subscribe(() => {
      });
    // bắt sự kiện mỗi khi biến "searchKey thay đổi giá trị";
    // `distinctUntilChanged` và `switchMap`: tương tự với showDrop bên trên
    // debounceTime: đợi ~500ms để người dùng dừng thao tác mới bắt đầu gọi api
    this.searchKey.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((valueChanges) => this.search(valueChanges)))
      .subscribe(() => {
      });
    // endregion
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.showDrop) {
      this.showDrop.unsubscribe();
    }
  }

  // endregion

  // region ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectedVal.disable();
    } else {
      this.selectedVal.enable();
    }
  }

  writeValue(obj: any): void {
    this.selectedVal.setValue(obj);
    // this.searchKey.setValue(obj || '', {emitEvent: false});
  }

  // endregion

  // region Call api and choose option
  search(val?): Observable<any> {
    if (!this.option.apiUrl || !this.option.colValue || !this.option.colLabel) {
      return new Observable<any>(subscriber => {
        subscriber.next();
        subscriber.complete();
      });
    }
    this.optionList = [];
    this.searchParams.criteria = this.option.colLabel + OperatorFilter.CONTAINS + val;
    this.searchParams.filter = val;
    this.searchParams.values = val;
    this.searchParams.page = 0;

    return this.baseApi.get(this.option.apiUrl, this.searchParams)
      .pipe(tap((res) => {
        if(res?.data){
          this.optionList = res.data;
          this.totalRecord += res.data.length;
          // this.totalRecord = res.totalRecords;
          this.searchParams.page = 1;
        }else this.optionList = res;
      }));
  }

  // select from search
  chooseOpt(val: object, chooseInDropdown = true): void {
    if (this.option.colValue && !this.selectedVal.disabled) {
      this.selectedVal.setValue(val ? val[this.option.colLabel] : '');
      // this.searchKey.setValue(val ? val[this.option.colValue] : '', {emitEvent: false});

      //onChange sẽ trả ra 1 formControl và emit trả ra một object
      this.onChangeModel(val ? val[this.option.colValue] : '');
      this.choose.emit(val);
    }
    if (chooseInDropdown) {
      this.toggleDrop();
    }
  }

  // select from popup
  chooseOptFromMaster(val: object): void {
    this.chooseOpt(val, false);
    if (!this.isMultipleSelect) {
      this.popup.close();
    }
  }

  clearData(): void {
    this.selectedVal.setValue('');
    this.searchKey.setValue('', {emitEvent: false});

    //onChange sẽ trả ra 1 formControl và emit trả ra một object
    this.onChangeModel(this.selectedVal.value);
    this.choose.emit({});
  }

  // endregion

  // region OTHERS
  toggleDrop(show?): void {
    this.searchKey.setValue('', {emitEvent: false});
    if (this.selectedVal.disabled) {
      this.showDrop.next(false);
      return;
    }

    if (show) {
      this.showDrop.next(true);
    } else {
      this.showDrop.next(!this.showDrop.value);
    }

    if (this.showDrop) {
      // event click outside this component
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.showDrop.next(false);
        }
      });
    }
  }
  activeFocus(): void {
    this.selectSearch.nativeElement.focus();
  }

  openPop(): void {
    this.popup = this.ngbModal.open(this.modalContent, {size: 'lg', scrollable: false, centered: false});
  }

  onTouched(): void {
    this.onTouchModel('touched');
    this.onTouch.emit();
    // if (this.selectedVal.value == '' && this.selectedVal.dirty)
    //   this.onChangeModel(this.selectedVal.value);
  }

  dropListScroll($event): void {
    if (!this.allowScrollPage) return;
    const el = $event.srcElement;
    if (el.scrollTop + el.offsetHeight < el.scrollHeight - 30) {
      return;
    }
    this.totalRecord += this.optionList.length;
    if (this.optionList.length < this.totalRecord && this.isLoading == false) {
      this.searchParams.page++;
      this.isLoading = true;
      this.baseApi.get(this.option.apiUrl, this.searchParams)
        .subscribe((res) => {
          this.isLoading = false;
          this.optionList = this.optionList.concat(res.data);
          // this.totalRecord = res.totalRecords;
          this.totalRecord += res.data.length;
        });
    }
  }
}
