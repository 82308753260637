import {Component, Inject, Input, OnInit} from '@angular/core';
import {LOADING_DATA} from '../../tokens/loading-data.token';

@Component({
  selector: 'app-global-loading',
  templateUrl: './global-loading.component.html',
  styleUrls: ['./global-loading.component.scss']
})
export class GlobalLoadingComponent implements OnInit {
  @Input() type = 'round';

  constructor(@Inject(LOADING_DATA) public data) {
    if (data && data.type) {
      this.type = data.type;
    }
  }

  ngOnInit(): void {
  }

}
