import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from './configs/app-config';
import {NgSelectConfig} from "@ng-select/ng-select";
import set = Reflect.set;
import { GlobalDataService } from './services/global-data.service';
import { AuthService } from './services/api-service/auth.service';
import { UserRoles } from './configs/user-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  appConfig = APP_CONFIG;
  userRoles = UserRoles;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private config: NgSelectConfig,
    private authService: AuthService,
    private globalDataService: GlobalDataService
  ) {
    if (this.authService.currentUserValue){
      if (this.authService.currentUserValue.user.is_admin === this.userRoles.ADMIN) {
        this.router.navigateByUrl('admin', {replaceUrl: true}).then();
      }else{
        this.router.navigateByUrl('manager', {replaceUrl: true}).then();
      }
    }
  }
}
