import {Injectable} from '@angular/core';
import {BaseApi} from './base.api';
import {Observable} from 'rxjs';
import {PersistStateScreen} from '../../configs/persist-state-screen';
import { PersistState } from "../../models/persist-state-model";

@Injectable({
  providedIn: 'root'
})
export class PersistStateApi extends BaseApi {
  prefixURL = 'display_order_fields';

  getPersistState(screen: PersistStateScreen): Observable<PersistState> {
    return this.get('' + screen);
  }

  saveVisibleOrder(screen: PersistStateScreen, visibleOrder: string): Observable<any> {
    return this.put('visible-order/' + screen, {screen_code: screen, field_display: visibleOrder} );
  }

  saveFilter(screen: PersistStateScreen, filter: Array<string>): Observable<any> {
    return this.put('filter', {screenId: screen, filter});
  }
}
