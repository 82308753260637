import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragConfig } from '../configs/drag-config';

import { NumberInputComponent } from './components/number-input/number-input.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { FloatNumberPipe } from './pipe/float-number.pipe';
import { CurrencyNumberPipe } from './pipe/currency-number.pipe';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GlobalLoadingComponent } from './components/global-loading/global-loading.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { InputNoSpaceDirective } from './directives/input-no-space.directive';
import { SelectSearchWithPopupComponent } from './components/select-search-with-popup/select-search-with-popup.component';
import { SortableDirective } from './directives/sortable.directive';
import { ImportFileComponent } from './components/import-file/import-file.component';
import { ExportFileComponent } from './components/export-file/export-file.component';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalConfig,
  NgbModalModule,
  NgbNavModule, NgbPopoverModule, NgbTimepickerModule,

} from '@ng-bootstrap/ng-bootstrap';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColumnSettingComponent } from './master-component/column-setting/column-setting.component';
import { FilterSettingComponent } from './master-component/filter-setting/filter-setting.component';
import { TableCustomComponent } from './master-component/table-custom/table-custom.component';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { ToggleCheckComponent } from './components/toggle-check/toggle-check.component';
import { DynamicInputComponent } from './master-component/dynamic-input/dynamic-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CodeTypingDirective } from './directives/code-typing.directive';
import { CustomAdapter, CustomDateParserFormatter } from './service/custom-date-parser-formatter.service';
import { DateTimePickerComponent } from './components/date-picker/date-time-picker.component';
import { PopupConfigs } from '../configs/popup-config';
import { ExamDataMasterComponent } from './master-select-components/exam-data/exam-data-master.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { FilterSettingV2Component } from './master-component/filter-setting-v2/filter-setting-v2.component';
import { InputResizeComponent } from './components/input-resize/input-resize.component';
import { MatDatePickerComponent } from './components/mat-date-picker/mat-date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { SelectSearchMultipleComponent } from './components/select-search-multiple/select-search-multiple.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { FilterSettingV3Component } from './master-component/filter-setting-v3/filter-setting-v3.component';
import { CreateSignatureComponent } from './master-component/create-signature/create-signature.component';
import { ItemAnswerComponent } from './form-controls/item-answer/item-answer.component';
import { ItemBuilderComponent } from './form-controls/item-builder/item-builder.component';
import { ItemViewAnswerComponent } from './form-controls/item-view-answer/item-view-answer.component';
import { SectionBuilderComponent } from './form-controls/section-builder/section-builder.component';
import { SectionAnswerComponent } from './form-controls/section-answer/section-answer.component';
import {TakePhotoComponent} from './components/take-photo/take-photo.component';
import {WebcamModule} from 'ngx-webcam';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FilterSortComponent } from './components/filter-sort/filter-sort.component';
import { HandSignComponent } from './components/hand-sign/hand-sign.component';
import {AngularSignaturePadModule} from "@almothafar/angular-signature-pad";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerComponent} from "./components/mat-datepicker/mat-datepicker.component";
import { AuthSubjectDetailComponent } from './components/auth-subject-detail/auth-subject-detail.component';
import { StudyDatePipe } from './pipe/study-date.pipe';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MatDateAdminComponent } from './components/mat-date-admin/mat-date-admin.component';
import { FilterSubjectComponent } from './components/filter-subject/filter-subject.component';
import { FilterEncodeComponent } from './components/filter-encode/filter-encode.component';

const components = [
  NumberInputComponent,
  PaginationComponent,
  GlobalLoadingComponent,
  ConfirmationComponent,
  AutocompleteComponent,
  ToggleCheckComponent,
  SelectSearchWithPopupComponent,
  DateTimePickerComponent,
  InputResizeComponent,
  ImportFileComponent,
  ExportFileComponent,
  SelectSearchComponent,
  TakePhotoComponent,
  FilterSortComponent,
  HandSignComponent,
  MatDatepickerComponent,
  AuthSubjectDetailComponent
];

const masterComponents = [
  ColumnSettingComponent,
  FilterSettingComponent,
  FilterSettingV2Component,
  TableCustomComponent,
  DynamicInputComponent,
  MatDatePickerComponent,
  FilterSettingV3Component
];

const itemsComponents = [
  ItemAnswerComponent,
  ItemBuilderComponent,
  ItemViewAnswerComponent,
  SectionBuilderComponent,
  SectionAnswerComponent
];

const masterSelectComponents = [
  ExamDataMasterComponent,
];

const directives = [
  NumberOnlyDirective,
  TooltipDirective,
  InputNoSpaceDirective,
  SortableDirective,
  CodeTypingDirective
];

const pipes = [
  CurrencyNumberPipe,
  FloatNumberPipe,
  SafeHtmlPipe,
  StudyDatePipe
];

const libraries = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  NgSelectModule,
  OverlayModule,
  DragDropModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbCollapseModule,
  NgbNavModule,
  NgbAccordionModule,
  MatIconModule,
  MatButtonModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSlideToggleModule,
  PdfJsViewerModule
];

@NgModule({
  declarations: [
    components,
    masterComponents,
    masterSelectComponents,
    directives,
    pipes,
    DateTimePickerComponent,
    SelectSearchMultipleComponent,
    TimePickerComponent,
    CreateSignatureComponent,
    itemsComponents,
    ScrollTrackerDirective,
    MatDateAdminComponent,
    FilterSubjectComponent,
    FilterEncodeComponent,
  ],
  imports: [
    libraries,
    MatDatepickerModule,
    NgbPopoverModule,
    NgbTimepickerModule,
    NgbModule,
    WebcamModule,
    AngularSignaturePadModule,
    MatFormFieldModule,
  ],
  exports: [
    libraries,
    components,
    masterComponents,
    masterSelectComponents,
    directives,
    pipes,
    SelectSearchMultipleComponent,
    TimePickerComponent,
    CreateSignatureComponent,
    itemsComponents,
    ScrollTrackerDirective,
    MatDateAdminComponent,
    FilterSubjectComponent,
    FilterEncodeComponent
  ],
  providers: [
    { provide: NgbModalConfig, useValue: PopupConfigs },
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ShareModule {
}
