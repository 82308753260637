import {BaseModel} from './base.model';

export interface NumberFormatModel {
  unit?: string;
  fraction?: number;
  defaultValue?: string;
}

export class NumberFormat extends BaseModel implements NumberFormatModel {
  unit = '';
  fraction = 4;
  defaultValue = '0';

  constructor(props?: NumberFormatModel) {
    super(props);

    this.syncProps(props);
  }
}
