export enum MasterSelectSearch {
  EXAM_DATA_LIST = 'exam_data_list',
  CATRGORY_LIST = 'category_list',
  USER_LIST = 'user_list',
  STAFF_USE = 'STAFF_USE',
  GROUP_STAFF_USE = 'GROUP_STAFF_USE',
  USE_STAFF = 'USE_STAFF',
  USE_GROUP_STAFF = 'USE_GROUP_STAFF',
  EMPLOYEE_GROUP = 'EMPLOYEE_GROUP',
  DEPARTMENTS = 'DEPARTMENTS',
  EMPLOYEES = 'EMPLOYEES'
}
