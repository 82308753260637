import {Injectable} from "@angular/core";
import {BaseApi} from "./base.api";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SubjectsApi extends BaseApi {
  prefixURL = 'subjects';
  api = environment.apiUrl;

  getDetailFull(id: number | string, password: string): Observable<any> {
    return this.post(`${id}/detail`, {password});
  }

  getDetailView(studyId, id: number | string, password: string): Observable<any> {
    return this.http.post(`${this.api}/study/${studyId}/subjects/${id}/detail`, {password});
  }
  getIncrement(studyId , id: number | string, $study): Observable<any> {
    return this.http.post(`${this.api}/study/${studyId}/sites/${id}/autoincrement`, $study);
  }

  getAttachDetail(id: number | string, password: string): Observable<any> {
    return this.post(`${id}/detail`, {password});
  }

  getExportSubject(study_id, params): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post(`${this.api}/study/${study_id}/subjects/export`, params, {
      headers,
      responseType: 'blob',
      observe: 'response'
    });
  }


  getCancel(studyId: any, subjectId): Observable<any> {
    return this.get(`${subjectId}/cancel`);
  }

  getStudyID(studyId): Observable<any> {
    return this.http.get(this.api + `/studies/${studyId}`);
  }
}
