<div class="position-relative">
  <input type="text"
         autocomplete="off"
         class="form-control dropdown-toggle"
         [id]="id"
         [maxLength]="maxLength"
         [placeholder]="placeholder | translate"
         [formControl]="searchKey"
         (input)="toggleDrop(true)"
         (focus)="toggleDrop(true)">

  <div class="dropdown-menu" [class]="{'show': showDrop.value && optionList.length}">
    <div class="opt-list-scroll" #optListScroll>
      <div *ngFor="let opt of optionList">
        <a class="dropdown-item"
           (click)="chooseOpt(opt)"
           (keyup.enter)="chooseOpt(opt)"
           tabindex="0">
          {{opt}}
        </a>
      </div>
    </div>
  </div>
</div>
