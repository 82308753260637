import { Component, OnDestroy, OnInit, } from '@angular/core';
import { AuthService } from '../../../services/api-service/auth.service';
import { Router } from '@angular/router';
import { UserSession } from '../../../models/user-session.model';
import { NavMenuList } from '../../../configs/menu-list';
import { I18NList } from '../../../configs/i18n-list';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NavMenuInterface } from 'src/app/interfaces/nav-menu.interface';
import { environment } from 'src/environments/environment';
import { BehaviorService } from 'src/app/services/behavior.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { APP_CONFIG } from 'src/app/configs/app-config';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  preserveWhitespaces: false,
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  isToggledNav = false;
  interval: any;
  itemName = '';
  currentUser: UserSession;
  menuList = NavMenuList.MENU_MANAGER;
  i18nList = I18NList;
  i18nUse = '';
  multiLang = false;
  titleHeader = 'Trang chủ';
  env = environment;
  appConfig = APP_CONFIG;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    protected globalDataService: GlobalDataService,
    protected behaviorService: BehaviorService
  ) {
  }

  ngOnInit(): void {
    let lang = this.i18nList.find(val => val.code === this.appConfig.getLang());
    if (!lang){
      lang = this.i18nList[0];
    }
    this.i18nUse = lang.name;

    this.behaviorService.changeLanguage(lang.code);

    this.menuList.map((menu: any) => {
      if (menu.url === this.router.url) {
        this.globalDataService.setMetaTitlePage(menu.name);
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigateByUrl('/login', { replaceUrl: true }).then();
    });
  }

  getItem(items, item): void {
    this.titleHeader = item.name;
    this.itemName = item.name;
  }
}
