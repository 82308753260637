import { Component, ComponentRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../service/loading.service';
import { ConfirmationModalService } from '../../service/modal/confirmation-modal.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableHeaderModel } from 'src/app/models/table-header.model';
import { IOService } from 'src/app/services/IO.service';
import {log} from "util";

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.scss']
})
export class ExportFileComponent implements OnInit {

  @Input() type = "";
  @Input() params = {};
  @Input() keyScreen = "";
  @Input() btnSize = "lg";
  @Input() criteria = "";
  @Input() order = "";
  @Input() headers: TableHeaderModel[] = [];
  @Input() selectedHeaders: TableHeaderModel[] = [];

  @ViewChild('modalExport') modalExport;

  export_column = 'all';
  export_template = false;

  constructor(
    private translate: TranslateService,
    private confirmPopup: ConfirmationModalService,
    private modalService: NgbModal,
    private ioService: IOService,
    private loading: LoadingService,
  ) { }

  ngOnInit() {
  }

  openModalExport() {
    this.modalService.open(this.modalExport, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      keyboard: false,
      backdrop: 'static',
      size: 'sm'
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  exportCSV() {
    const columnName = (this.export_column == 'all' ? this.headers : this.selectedHeaders).map(col => {
      return this.translate.instant(col.name);
    });

    const column = (this.export_column == 'all' ? this.headers : this.selectedHeaders).map(col => {
      return col.column;
    });

    if (this.export_template) {
      this.exportTemplate(columnName, column);
    } else {
      this.exportData(columnName, column);
    }
  }

  exportTemplate(columnName, column) {
    console.log(1);
    let rows = [];

    rows.push(columnName); // field title
    rows.push(column); // field name

    this.ioService.exportToCsv(rows, this.type.toLowerCase() + ".csv");
  }

  exportData(columnName, column) {
    console.log(2);
    this.loading.showAppLoading();
    this.ioService.getExportData(this.type, this.params, column, this.criteria, this.order).subscribe((res) => {
      this.loading.hideAppLoading();
      console.log(res.body);
      let csv_content = this.ioService.processRowCSV(columnName);
      csv_content += this.ioService.processRowCSV(column);
      csv_content += res.body;
      this.ioService.downloadFile(csv_content, this.type.toLowerCase() + ".csv");
    }, (error) => {
      this.loading.hideAppLoading();
      // error = JSON.parse(error);
      if (error.SyntaxError) {
        this.confirmPopup.notify('', this.translate.instant(error.SyntaxError));
      }
      console.log(error);
    });
  }
}
