<div class="row">
  <div class="col-10" *ngIf="!publishKey">
    <div class="input-group" >
      <input type="text" class="form-control" [placeholder]="placeholder | translate" (keypress)="onEnter($event)"
        [(ngModel)]="option.filter" autocomplete="one-time-code">
      <button class="input-group-prepend input-group-text" tabindex="0" (click)="onApplyForm()">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
  <div class="col-2">
    <button class="sort" (click)="openPopUpSort(modalSort)" tabindex="0">
      <ng-container *ngIf="order_by == 'created_at' || order_by == 'start_date' || order_by == 'end_date'; else elseTemplate">
        <i class="fas fa-sort-numeric-down fa-2x" *ngIf="order_dir == sortConfig.order_dir_asc"></i>
        <i class="fas fa-sort-numeric-down-alt fa-2x" *ngIf="order_dir == sortConfig.order_dir_desc"></i>
      </ng-container>
      <ng-template #elseTemplate>
        <i class="fas fa-sort-alpha-down fa-2x" *ngIf="order_dir == sortConfig.order_dir_asc"></i>
        <i class="fas fa-sort-alpha-down-alt fa-2x" *ngIf="order_dir == sortConfig.order_dir_desc"></i>
      </ng-template>
    </button>
  </div>
</div>

<ng-template #modalSort let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{"TABLET_APP.SUBJECT.SORT" | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCloseSort()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="mb-1">{{"TABLET_APP.SUBJECT.ORDER_BY" | translate}}</div>
      <div class="form-check form-check-inline" *ngFor="let item of itemsSort; let i = index">
        <input class="form-check-input" type="radio" name="sortBy" id="field-sort-{{ i }}" [value]="item?.value"
          [(ngModel)]="option.order_by">
        <label class="form-check-label" for="field-sort-{{ i }}">{{ item?.label | translate}}</label>
      </div>
    </div>
    <div class="form-group mt-3">
      <div class="mb-1">{{"TABLET_APP.SUBJECT.ORDER_DIR" | translate}}</div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="sortDir" id="asc" value="asc" [(ngModel)]="option.order_dir">
        <label class="form-check-label" for="asc">{{"TABLET_APP.SUBJECT.ASC" | translate}}</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="sortDir" id="desc" value="desc"
          [(ngModel)]="option.order_dir">
        <label class="form-check-label" for="desc">{{"TABLET_APP.SUBJECT.DESC" | translate}}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer d-block text-center">
    <button class="ml-2 btn btn-primary" (click)="onApplyForm()">{{'COMMON.APPLY' |
      translate}}</button>
    <button class="btn btn-outline-dark" (click)="onCloseSort()">{{'COMMON.CLOSE' | translate}}</button>
  </div>
</ng-template>
