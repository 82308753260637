import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {NgbDatepickerI18n, NgbTimepickerConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import {DatePipe} from "@angular/common";
import {DatepicketLocalizationService, I18n} from "../date-picker/data-picker.service";
import {TimeModel} from "./time.model";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    },
    I18n,
    {provide: NgbDatepickerI18n, useClass: DatepicketLocalizationService}
  ]
})
export class TimePickerComponent implements OnInit {
  @Input() isDisabled: any;
  @Input() class: any;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() date: any;
  datetime: TimeModel = new TimeModel();

  valueTime = new FormControl();
  private ngControl: NgControl;
  private firstTimeAssign = true;

  private onChangeModel = (v: any) => {
  };
  protected onTouchModel = (v: any) => {
  };

  constructor(private config: NgbTimepickerConfig, private inj: Injector) {
    config.spinners = false;

    this.valueTime.valueChanges.subscribe(val => {
      if (this.valueTime.dirty) {
        this.onChangeModel(val);
      }
    });
    this.valueTime.statusChanges.subscribe(val => {
      this.onTouchModel(val);
    });
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
  }

  writeValue(time: string): void {
    this.date = moment(new Date()).format('YYYY-MM-DD');
    if (time) {
      const dateTime = this.date + ' ' + time;
      this.datetime = TimeModel.fromLocalString(dateTime);
    } else {
      this.datetime = new TimeModel();
      this.firstTimeAssign = false;
    }

    this.setTimeStringModel();
  }

  onTouched(): void {
    if (this.valueTime.dirty) {
      this.valueTime.setValue(this.valueTime.value.trim());
    }
    this.onTouchModel('touched');
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeTime(event: NgbTimeStruct): void {
    if (event) {
      this.datetime = new TimeModel();
      this.datetime.hour = event.hour;
      this.datetime.minute = event.minute;
    } else {
      this.datetime = null;
    }
    this.setTimeStringModel();
  }

  setTimeStringModel(): void {
    if (this.datetime) {
      const date = new Date(this.datetime.year, this.datetime.month, this.datetime.day, this.datetime.hour, this.datetime.minute);
      this.valueTime.setValue(moment(date).format('HH:mm'), {emitEvent: false});
    } else {
      this.valueTime.setValue('');
    }

    if (!this.firstTimeAssign) {
      setTimeout(() => {
        this.valueTime.markAsDirty()
        this.onTouched();
      }, 10);
    } else {
      if (this.datetime) {
        this.firstTimeAssign = false;
      }
    }
  }
}
