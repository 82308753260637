import { Injectable } from '@angular/core';
import {BaseApi} from "./base.api";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ConfirmationModalService} from "../../share/service/modal/confirmation-modal.service";
import {Router} from "@angular/router";
import {LoadingService} from "../../share/service/loading.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "../global-data.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import { Consent, ConsentModel, ConsentSection } from 'src/app/models/consent.model';

@Injectable({
  providedIn: 'root'
})
export class ConsentSectionApi extends BaseApi{
  prefixURL = 'study';
  api = environment.apiUrl;
  keyStore = 'consent-section';

  consentObj: Consent;

  dataConsent: any = {
    site_id: null,
    document_id: null,
    subject_id: null,
    sections: [],
    subject_comment: null,
    subject_portrait_image: null,
    subject_name: null,
    subject_sign: null,
    subject_sign_date: null,
    doctor_sign: null,
    date_complete: null,
    sign_off_date: null,
    subject_accept: null
  };

  constructor(
    protected confirmPopup: ConfirmationModalService,
    protected http: HttpClient,
    protected router: Router
  ) {
    super(http, router);
  }

  getListSection(studyID, documentID): Observable<any> {
    return this.http.get(this.api + `/study/${studyID}/document/${documentID}/sections-full`);
  }

  //////////////////////////
  makeConsent(study_id, subject_id, document_id, site_id): void{
    this.consentObj = new Consent();
    this.consentObj.study_id = study_id;
    this.consentObj.site_id = site_id;
    this.consentObj.subject_id = subject_id;
    this.consentObj.document_id = document_id;
    this.saveConsent(this.consentObj);
  }

  saveConsent(consent: Consent): void{
    localStorage.setItem(this.keyStore, JSON.stringify(consent.toObject()));
    this.consentObj = consent;
  }

  get consent(): Consent{
    const item = localStorage.getItem(this.keyStore);
    if (!item) {
      return null;
    }
    return new Consent(JSON.parse(item));
  }

  getSection(sectionId, index): ConsentSection{
    const consent = this.consent;
    if (!consent)
    {
      return null;
    }
    const section = consent.sections[index];
    if (!section){
      return null;
    }

    if (section?.section_id === sectionId){
      return section;
    }

    return null;
  }

  setSubjectPortraitImage(image): void{
    const consent = this.consent;
    if (!consent)
    {
      return null;
    }
    consent.subject_portrait_image = image;
    this.saveConsent(consent);
  }

  saveSection(section: ConsentSection, index): void{
    const consent = this.consent;
    if (!consent)
    {
      return null;
    }

    if (section?.section_id != null){
      consent.sections[index] = section;
    }
    this.saveConsent(consent);
  }

  saveSubjectSign(subjectSign, subjectSignDate, subject_name, date_complete, subject_accept, subject_relationship,
                  subject_represent_name): void{
    const consent = this.consent;
    if (!consent)
    {
      return null;
    }
    consent.subject_sign = subjectSign;
    consent.subject_sign_date = subjectSignDate;
    consent.subject_name = subject_name;
    consent.subject_name = subject_name;
    consent.date_complete = date_complete;
    consent.subject_accept = subject_accept;
    consent.subject_relationship = subject_relationship;
    consent.subject_represent_name = subject_represent_name;
    this.saveConsent(consent);
  }

  removeConsentLocal(): void {
    localStorage.removeItem(this.keyStore);
  }

}
