import { APP_CONFIG } from './app-config';

export const NavMenuList =  {
  MENU_ADMIN: [
    {
      name: 'ADMIN.STUDY',
      icon: 'fas fa-briefcase',
      url: '/admin/studies',
      isHome: true,
      items: [
      ]
    },
    {
      name: 'ADMIN.USER',
      icon: 'fas fa-user-tie',
      url: '/admin/users',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'ADMIN.PARTNER_COMPANY',
      icon: 'fas fa-cogs',
      url: '/admin/setting-partner-company',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'DATA-TRAK.TITLE',
      icon: 'fas fa-database',
      url: '/admin/setting-data-trak',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'ADMIN.PUBLISH_KEY',
      icon: 'fas fa-history',
      url: '/admin/publish-key',
      isHome: false,
      items: [
      ]
    },
    /*A-2 Import data begin*/
    {
      name: 'ADMIN.RELEASE_DOCUMENT_API',
      icon: 'fas fa-cog',
      url: '/admin/release-document-api',
      isHome: false,
      items: [
      ]
    },
    /*A-2 Import data end*/
  ],

   MENU_MANAGER: [
    {
      name: 'MANAGER.SETTING_MANAGER',
      icon: 'fas fa-cogs',
      permissions: APP_CONFIG.permission_link.view_setting,
      url: '/manager/study-setting',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.SETTING_SUBJECT',
      icon: 'fas fa-user-cog',
      permissions: APP_CONFIG.permission_link.view_setting,
      url: '/manager/study-setting-subject',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.SITES',
      icon: 'fas fa-map-marker-alt',
      permissions: APP_CONFIG.permission_link.sites,
      url: '/manager/sites',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.DOCUMENTS',
      icon: 'fas fa-file-alt',
      permissions: APP_CONFIG.permission_link.documents,
      url: '/manager/documents',
      isHome: false,
      items: [
      ]
    },
     {
       name: 'MANAGER.DOCUMENT_MONITOR',
       icon: 'fab fa-watchman-monitoring',
       permissions: APP_CONFIG.permission_link.document_monitor,
       url: '/manager/document-monitor',
       isHome: false,
       items: [
       ]
     },
     {
       name: 'MANAGER.ENCODE_SUBJECT',
       icon: 'fab fa-slack',
       permissions: APP_CONFIG.permission_link.encode_subject,
       url: '/manager/encryption',
       isHome: false,
       items: [
       ]
     },
     {
       name: 'MANAGER.PERSONAL_DETAIL',
       icon: 'fab fa-sistrix',
       permissions: APP_CONFIG.permission_link.personal_detail,
       url: '/manager/personal',
       isHome: false,
       items: [
       ]
     },
    {
      name: 'MANAGER.USERS',
      icon: 'fas fa-user-nurse',
      permissions: APP_CONFIG.permission_link.users,
      url: '/manager/users',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.SUBJECTS',
      icon: 'fas fa-user-tie',
      permissions: APP_CONFIG.permission_link.subjects,
      url: '/manager/subjects',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.ROLES',
      icon: 'fas fa-ruler-vertical',
      permissions: APP_CONFIG.permission_link.roles,
      url: '/manager/roles',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.CONFIG_LOG',
      icon: 'fas fa-tasks',
      permissions: APP_CONFIG.permission_link.config_logs,
      url: '/manager/config-logs',
      isHome: false,
      items: [
      ]
    },
    {
      name: 'MANAGER.DATA_AUDIT_LOG',
      icon: 'fas fa-history',
      permissions: APP_CONFIG.permission_link.data_audit_logs,
      url: '/manager/data-audit-logs',
      isHome: false,
      items: [
      ]
    }
  ]
};
