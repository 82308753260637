<div id="wrapper" [ngClass]="{'toggled': isToggledNav}">
  <div id="sidebar-wrapper">
    <div class="flexbox-sidebar">
      <a [routerLink]="'/admin'" class="text-decoration-none" (click)=" titleHeader = 'Trang chủ' "
        routerLinkActive="active">
        <div class="header-logo text-center">
          <img class="w-45px h-45px img" src="assets/images/logo.png">
          <h3 class="text-white logo pt-2 pl-3">eCONSENT</h3>
        </div>
      </a>
    </div>
    <div class="menu-left">
      <ul class="sidebar-nav nav-pills nav-stacked" id="menu">
        <ng-container *ngFor="let menu of menuList">
          <li>
            <a class="item-menu" [routerLink]="menu.url" routerLinkActive="active">
              <span class="custom-icon"><i class="{{menu.icon}}"></i></span>
              <span class="text">{{menu.name | translate}}</span>
            </a>
            </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <!-- Page Content -->
  <div id="page-content-wrapper">
    <div class="page-headers">
      <div class="left-header ml-3">
        <button class="btn btn-toggle custom-btn" (click)="isToggledNav = !isToggledNav">
          <i class="fas fa-bars"></i>
        </button>
        <a href="" class="title-header ml-3">{{titleHeader | translate}}</a>
      </div>
      <div class="i18n-select" *ngIf="multiLang">
        <i class="fas fa-globe fs18"></i>
        <select class="form-control cursor-pointer" [formControl]="i18nUse">
          <option *ngFor="let lang of i18nList" [value]="lang.code">{{ lang.name }}</option>
        </select>
      </div>
      <div class="user-info cursor-pointer pr-3 d-flex">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" class="avatar">
          <img class="w-30px h-30px img" src="assets/images/default-avatar.png"> Admin
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item class="custom-logout" (click)="logout()">
            <i class="fas fa-power-off"></i><span class="ml-2">{{ 'COMMON.LOGOUT' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="content-wrapper" [class]="isToggledNav==true ? 'overlay-show' :'' ">
      <div class="overlay" *ngIf="isToggledNav==true" (click)="isToggledNav =false"></div>
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
