import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[codeTyping]'
})
export class CodeTypingDirective {
  @Input() ngModel: string;


  constructor(private el: ElementRef) {
  }

  @HostListener('blur', ['$event']) onBlur(event): void {
    const ele = this.el.nativeElement as HTMLInputElement;
    ele.value = (event?.target?.value).toLocaleUpperCase().trim();
    // ele.value = this.mapValue(event?.target?.value);
  }

  mapValue(oldValue: string): string {
    const regex = new RegExp('^[A-Z0-9]+$');
    const value = this.convertValue((oldValue).toUpperCase());
    return regex.test(value) ? value.trim() : '';
  }

  convertValue(chars): string {
    return chars.replace(
      /[\uff01-\uff5e]/g,
      (char = chars) => {
        return String.fromCharCode(char.charCodeAt(0) - 0xfee0);
      }
    );
  }
}
