import {BaseModel} from './base.model';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationModalService} from '../share/service/modal/confirmation-modal.service';

export interface HandleErrorModel {
  status: string;
  timestamp: Date;
  message: string;
  errorCodes: Array<string>;
  errorData: Array<{ errorCode: string, errorData: Array<string> }>;
}

export class HandleError extends BaseModel implements HandleErrorModel {
  status = '';
  timestamp = new Date();
  message = '';
  errorCodes = [];
  errorData = [];

  constructor(props?: HandleErrorModel) {
    super(props);

    this.syncProps(props);
  }

  getErrorCodeStr(translateService?: TranslateService, keyScreen?: string): string {
    if (translateService && this.errorData.length) {
      return this.errorData.map(v => translateService.instant(keyScreen ? keyScreen + '.ERRORS.' + v.errorCode : 'ERRORS.' + v, {value: v.errorData.join('、')})).join('\n');
    } else if (translateService && this.errorCodes.length) {
      return this.errorCodes.map(v => translateService.instant(keyScreen ? keyScreen + '.ERRORS.' + v : 'ERRORS.' + v)).join('\n');
    } else {
      return this.errorCodes.join('\n');
    }
  }

  getFileError(translateService?: TranslateService, keyScreen?: string) {
    if (translateService && this.errorData.length) {
      let errors = [];
      errors.push(translateService.instant("IMPORT.ERRORS_LIST"));
      errors.push("");
      this.errorData.map(errs => {
        errs.errorData.map(err => {
          errors.push(translateService.instant('COMMON.LINE_N', { number: err.line }) + " "
            + translateService.instant('IMPORT.' + errs.errorCode, { fieldName: err.fieldName, fieldValue: err?.fieldValue }));
        })
      });
      return errors;
    }
    return null;
  }
  checkAuthen(err?: any, handleError?: any, screen?: any, translate?: TranslateService, confirmPopup?: ConfirmationModalService): string {
    if (err.status !== 'UNAUTHORIZED') {
      if (!handleError.errorCodes.length && !handleError.errorData.length) return;
      confirmPopup.notify('', handleError.getErrorCodeStr(translate, screen.toUpperCase()));
    }
  }
}
