import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {GlobalDataService} from '../../services/global-data.service';

export type SortDirection = 'asc' | 'desc';
const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: 'asc'};

export interface SortEvent {
  column: string;
  direction: SortDirection;
  sortQuery: string;
}

@Directive({
  selector: '[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'onClick()'
  }
})
export class SortableDirective {
  @Input() sortable = '';
  @Input() direction: SortDirection = 'desc';
  @Input() selectSearchMode = false;
  @Output() sort = new EventEmitter<SortEvent>();

  constructor(
    private globalDataService: GlobalDataService
  ) {
  }

  onClick(): void {
    if (this.selectSearchMode) {
      this.processSort();
    } else {
      this.globalDataService.checkFormChange().subscribe((allowChange) => {
        if (allowChange) {
          this.processSort();
        }
      });
    }
  }

  processSort(): void {
    this.direction = rotate[this.direction];
    // emit value
    let sortQuery = '';

    if (this.direction === 'asc') sortQuery = `+${this.sortable}`;
    if (this.direction === 'desc') sortQuery = `-${this.sortable}`;

    this.sort.emit({column: this.sortable, direction: this.direction, sortQuery});
  }
}
