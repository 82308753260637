<div [formGroup]="form">
  <input class="form-control" formControlName="name"
    [appTooltip]="formGetErrorControlMsg('question', ['required', 'maxLength'])"
    [ngClass]="{'border border-danger': formIsShowErrorControl('name')}" />

  <div *ngIf="showQuestion">
    <input class="form-control" formControlName="question"
      [appTooltip]="formGetErrorControlMsg('question', ['required', 'maxLength'])"
      [ngClass]="{'border border-danger': formIsShowErrorControl('question')}" />
  </div>
  <ng-container [ngSwitch]="itemInfo.type">
    <ng-container *ngSwitchCase="inputType.label"></ng-container>
    <ng-container *ngSwitchCase="inputType.text"></ng-container>
    <ng-container *ngSwitchCase="inputType.number"></ng-container>
    <ng-container *ngSwitchCase="inputType.datetime"></ng-container>
    <ng-container *ngSwitchCase="inputType.date"></ng-container>
    <ng-container *ngSwitchCase="inputType.time"></ng-container>
    <ng-container *ngSwitchCase="inputType.radio"></ng-container>
    <ng-container *ngSwitchCase="inputType.checkbox"></ng-container>
    <ng-container *ngSwitchCase="inputType.select"></ng-container>
  </ng-container>
</div>