import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { ShareModule } from '../../share/share.module';
import { MatMenuModule } from '@angular/material/menu';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { ManagerLayoutComponent } from './manager-layout/manager-layout.component';
import { TabletappLayoutComponent } from './tabletapp-layout/tabletapp-layout.component';

const layouts = [
  MainLayoutComponent,
  AdminLayoutComponent,
  NotFoundComponent,
  DefaultLayoutComponent,
  ManagerLayoutComponent,
  TabletappLayoutComponent
];

@NgModule({
  declarations: [layouts],
  exports: [layouts],
  imports: [
    CommonModule,
    ShareModule,
    MatMenuModule
  ]
})
export class LayoutModule {
}
