<div class="d-flex date-picker {{margin ? '' : 'mt-2'}} " [ngClass]="{'date-time': disabled}" *ngIf="showControl">
  <input matInput [matDatepicker]="dp" [placeholder]="placeholder" disabled [class]="class"
         [ngClass]="{'border border-danger': invalid}" class="form-control" [(ngModel)]="dateString"
         (ngModelChange)="changeDate()" [min]="minDate" [max]="maxDate">
  <div class="icon-btn">
    <mat-icon *ngIf="showClear && dateString" matDatepickerToggleIcon (click)="clearInput()" class="clear-icon"><i
        class="icon-time fas fa-times"></i></mat-icon>
    <mat-datepicker-toggle matSuffix [for]="dp" class="icon-date">
    </mat-datepicker-toggle>
  </div>
  <mat-datepicker #dp [disabled]="disabled"></mat-datepicker>
</div>
