import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {BaseApi} from '../../../services/api-service/base.api';
import {PagingConfig} from '../../../configs/paging-config';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() id: string;
  @Input() ngClass: string;
  @Input() placeholder = '';
  @Input() maxLength = 10;
  @Input() options: {
    apiUrl?: string,
    displayKey?: string,
    searchKey?: string,
  };
  @Output() chooseVal = new EventEmitter<string>();

  private sub;

  option = {
    apiUrl: '',
    displayKey: 'text',
    searchKey: 'filter',
  };
  showDrop = new BehaviorSubject(false);
  searchKey = new FormControl('');
  optionList: Array<any> = [];

  @ViewChild('optListScroll') optListScroll: ElementRef;

  private onChangeModel = (v: any) => {};
  protected onTouchModel = (v: any) => {};

  constructor(
    private el: ElementRef,
    private baseApi: BaseApi
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      const newOpt = changes.options.currentValue;
      Object.keys(newOpt).forEach((key) => {
        if (this.option.hasOwnProperty(key)) {
          this.option[key] = this.options[key];
        }
      });
    }
  }

  ngOnInit(): void {
    this.showDrop.subscribe(flag => {
      if (!flag) {
        if (this.sub) {
          this.optionList = [];
          this.sub.unsubscribe();
        }
      } else {
        this.callData();
      }
    });

    this.searchKey.valueChanges.subscribe(val => {
      this.onChangeModel(val);
    });

    this.searchKey.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => this.callData())).subscribe(() => {});
  }

  /**
   * Retrieve data from server
   */
  callData(): Observable<any> {
    if (!this.option.apiUrl) {
      return;
    }
    this.optionList = [];
    return this.baseApi.get(this.option.apiUrl, {page: PagingConfig.FirstPage, [this.option.searchKey]: this.searchKey.value})
      .pipe(tap((res) => {
        this.optionList = res;
      }));
  }

  /**
   * Option changed handling function
   * @param val: any
   */
  chooseOpt(val: any): void {
    this.searchKey.setValue(val);
    this.onChangeModel(val);
    this.chooseVal.emit(val);
    this.toggleDrop();
  }

  /**
   * Toggle dropdown shown
   */
  toggleDrop(show?: boolean): void {
    if (show) {
      this.showDrop.next(true);
    } else {
      this.showDrop.next(!this.showDrop.value);
    }

    if (this.showDrop) {
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.showDrop.next(false);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchKey.disable();
    } else {
      this.searchKey.enable();
    }
  }

  writeValue(value: string): void {
    this.searchKey.setValue(value);
  }
}
