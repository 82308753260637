export enum UserRoles {
  ADMIN = 1,
  NORMAL_USER = 0,
}

export enum UserState {
  ACTIVE = 1,
  INACTIVE = 0,
}


export const UserConfigs = {
  passwod_policy: {
    minlength: 6,
    maxlength: 128
  },
  DefaultAvatar: 'assets/images/default-avatar.png',
  permission_value: {
    ADD_EDIT_DOCUMENTS: 1,
    ADD_EDIT_SUBJECTS: 2,
    AUDIT_LOG: 3,
    CHANGE_SUBJECT_STATUS: 4,
    CONFIGURE: 5,
    CONSENT_ADMINISTRATOR: 6,
    DELETE_SUBJECT: 7,
    PHI: 8,
    READ_ONLY: 9,
    REPORTS: 10,
    MONITORING: 11,
  },
  permission_list: [
    { id: 1, text: 'Add/Edit Documents' },
    { id: 2, text: 'Add/Edit subjects' },
    { id: 3, text: 'Audit Log' },
    { id: 4, text: 'Change Subject Status' },
    { id: 5, text: 'Configure' },
    { id: 6, text: 'Consent Administrator' },
    { id: 7, text: 'Delete Subject' },
    { id: 8, text: 'PHI' },
    { id: 9, text: 'Read Only' },
    { id: 10, text: 'Reports' },
    { id: 11, text: 'Monitoring' }
  ]
};
