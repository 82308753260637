import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {BaseSearchParam} from '../../models/base-search-param.model';
import {cloneDeep} from 'lodash';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BaseApi {
  constructor(
    protected http: HttpClient,
    protected router: Router
  ) { }

  protected apiUrl = environment.apiUrl;
  public prefixURL = '';
  private apiSaves = 'save-all';

  /** region pristine api */
  public get(url, param?: any): Observable<any> {
    return this.http.get(this.fullURL(url) + '?' + this.extendSearchParams(param));
  }

  protected post(url: string, body: any, param?: any): Observable<any> {
    return this.http.post(this.fullURL(url), body, {params: param});
  }

  protected put(url: string, body: any, param?: any): Observable<any> {
    return this.http.put(this.fullURL(url), body, {params: param});
  }

  protected patch(url: string, body: any, param?: any): Observable<any> {
    return this.http.patch(this.fullURL(url), body, {params: param});
  }

  protected delete(url: string, param?: any): Observable<any> {
    return this.http.delete(this.fullURL(url), {params: param});
  }

  protected download(url: string, body: any = {}): Observable<any> {
    return this.http.post(this.fullURL(url), body, {responseType: 'blob', observe: 'response'});
  }

  protected downloadByGet(url: string): Observable<any> {
    return this.http.get(this.fullURL(url), {responseType: 'blob', observe: 'response'});
  }

  // endregion

  /** region RESTFUL api */

  getAll(pagingParam: any = {}, url?: string): Observable<any> {
    // const params = cloneDeep(pagingParam);
    // delete params.filterList;
    return this.get(url, pagingParam);
  }

  getDetail(id: number | string): Observable<any> {
    return this.get(`${id}`);
  }

  /**
   * create or update one object
   * @param newObj object to save
   * @returns
   */
  save(newObj, url: string = ''): Observable<any> {
    if(newObj.id)
      return this.update(newObj.id, newObj, url);
    else
      return this.create(newObj, url);
  }

  create(newObj, url: string = ''): Observable<any> {
    return this.post(url, newObj);
  }

  update(id?: number | string, updateObj?: any, url: string = ''): Observable<any> {
    if(url != ''){
      return id ? this.put(`${id}/${url}`, updateObj) : this.put(``, updateObj);
    }else {
      return id ? this.put(`${id}`, updateObj) : this.put(``, updateObj);
    }
  }

  remove(id: any, url = '', submit_time = '', extra_title = '', extra_value = ''): Observable<any> {
    let requestURL = `` + url + id + '?submit_time=' + submit_time;
    if (extra_title != ''){
      requestURL += '&' + extra_title + '=' + extra_value;
    }
    return this.delete(requestURL);
  }


  deleteMany(newObj: any, url = ''): Observable<any> {
    return this.post(`` + url, { ids : newObj });
  }
 /**
  * Remove many
  * @param ids : array
  * @param url
  * @returns
  */
  removes(ids: Array<any> = [], url = ''): Observable<any> {
    return this.delete(`` + url, {cid: ids});
  }

  /**
   * Save all for table edit
   * @param arrObj
   * @returns
   */
  saveAll(arrObj: any) {
    return  this.post(`` + this.apiSaves, arrObj);
  }

  //TODO
  uploadFile(params?: any, body?: any): Observable<any> {
    let formData = new FormData();
    formData = this.convertModelToFormData(body);

    return this.post('' + '?type=' + params, formData);
  }
  // endregion

  /** region Extension */
  public fullURL(url?: string): string {
    let fullUrl = this.apiUrl;
    if (this.prefixURL) {
      fullUrl += '/' + this.prefixURL;
    }
    if (url) {
      fullUrl += '/';
      if (url !== '/') {
        fullUrl += url;
      }
    }
    return fullUrl;
  }

  // endregion

  // function convert from model to formData
  convertModelToFormData(val, formData = new FormData(), namespace = '') {
    if ((typeof val !== 'undefined') && (val !== null)) {
      if (val instanceof Date) {
        formData.append(namespace, val.toISOString());
      } else if (val instanceof Array) {
        for (let i = 0; i < val.length; i++) {
          this.convertModelToFormData(val[i], formData, namespace + '[' + i + ']');
        }
      } else if (typeof val === 'object' && !(val instanceof File)) {
        if (val instanceof FileList) {
          for (let i = 0; i < val.length; i++) {
            formData.append(namespace + '[]', val[i]);
          }
        } else {
          for (const propertyName in val) {
            if (val.hasOwnProperty(propertyName)) {
              this.convertModelToFormData(val[propertyName], formData, namespace ? namespace + '[' + propertyName + ']' : propertyName);
            }
          }
        }
      } else if (val instanceof File) {
        formData.append(namespace, val);
      } else {
        formData.append(namespace, val.toString());
      }
    }
    return formData;
  }

  //encode param api
  protected extendSearchParams(param?: object): string {
    const p = new URLSearchParams();
    if (param && typeof param === 'object') {
      Object.keys(param).map(key => {
        if (typeof param[key] === 'object'){
          Object.keys(param[key]).map(key2 => {
            p.append(key2, param[key][key2]);
          });
        }else{
          p.append(key, param[key]);
        }
      });
    }
    return p.toString();
  }

  /**
   * Get list for select
   * @param objName
   * @param filter
   */
  getSelectList(objName: string, filter?: string): Observable<any> {
    if (filter){
      return this.http.get(`${this.apiUrl}/select-list${objName}` + '?' + this.extendSearchParams({filter}));
    }else{
      return this.http.get(`${this.apiUrl}/select-list${objName}`);
    }
  }
}
