import {Pipe, PipeTransform} from '@angular/core';
import {NumberProcessor} from '../utils/number-processor';

@Pipe({
  name: 'FloatNumber'
})
export class FloatNumberPipe implements PipeTransform {

  transform(value: any, limit: number = 0): string {
    return NumberProcessor.decimals(value, limit);
  }
}
