import {MasterSelectSearch} from '../configs/master-select-search';
import {BaseModel} from './base.model';

/**
 * colValue, colLabel: field on api search
 * selectColumn, selectLabel field on popup
 * objectSelectValue, objectSelectLabel field input and output
 */
export interface SelectSearchOptionModel {
  placeholder?: string;
  apiUrl?: string;
  colValue?: string;
  colLabel?: string;
  selectColumn?: any;
  selectLabel?: any;
  masterSearchComponents?: MasterSelectSearch;
  isMultipleSelect?: boolean;
  isShowPopUp?: boolean;
  allowPopup?: boolean;
  allowSearch?: boolean;

  objectSelectValue?: string;
  objectSelectLabel?: string;
}

export class SelectSearchOption extends BaseModel implements SelectSearchOptionModel {
  placeholder = '';
  apiUrl = '';
  colValue = "value";
  colLabel = "text";
  selectColumn = 'id';
  selectLabel = 'name';
  masterSearchComponents = null;
  isMultipleSelect = false;
  isShowPopUp = false;
  allowPopup = false;
  allowSearch = false;
  objectSelectValue = 'value';
  objectSelectLabel = 'text';

  constructor(props?: SelectSearchOptionModel) {
    super(props);

    this.syncProps(props);
  }
}
