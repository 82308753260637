import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {BaseApi} from './base.api';
import {map, tap} from 'rxjs/operators';
import {BaseSearchParam} from "../../models/base-search-param.model";

@Injectable({
  providedIn: 'root'
})
export class ManagerStudySelectApi extends BaseApi{

  prefixURL = 'my-studies';
  keyStore = 'study-working';
  searchParamStudy = new BaseSearchParam();

  private hasStudyWorking = new BehaviorSubject<boolean>(false); // {1}

  get isHasStudyWorking(): Observable<boolean>{
    return this.hasStudyWorking.asObservable(); // {2}
  }

  getMyStudy(filterDate = false): Observable<any>{
    return this.getAll({filterDate});
  }

  setStudyWorking(data: any): void {
    localStorage.setItem(this.keyStore, JSON.stringify(data));
    this.hasStudyWorking.next(true);
  }

  clearStudyWorking(): void{
    localStorage.removeItem(this.keyStore);
    this.hasStudyWorking.next(false);
  }

  getStudyWorking(redirectNotFound = true): any {
    const item = localStorage.getItem(this.keyStore);
    if (!item) {
      if (redirectNotFound){
        this.router.navigateByUrl('manager/study-select').then();
      }
      this.hasStudyWorking.next(false);
      return;
    }
    this.hasStudyWorking.next(true);
    return JSON.parse(item);
  }
   getNewStudy(url, study_id: any): Observable<any> {
    return this.http.get(this.fullURL(url) + '?' + this.extendSearchParams(study_id));
  }
}
