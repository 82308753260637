import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubjectsApi } from 'src/app/services/api-service/subjects.api';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { LoadingService } from '../../service/loading.service';
@Component({
  selector: 'app-auth-subject-detail',
  templateUrl: './auth-subject-detail.component.html',
  styleUrls: ['./auth-subject-detail.component.scss']
})
export class AuthSubjectDetailComponent implements OnInit {

  @Input() subjectID = null;
  @Output() authForm = new EventEmitter<any>();

  inputPass = '';
  targetForm = null;

  constructor(
    private ngbModal: NgbModal,
    private subjectService: SubjectsApi,
    private globalDataService: GlobalDataService,
    protected loading: LoadingService,
  ) { }

  ngOnInit(): void {
  }

  openPopup($target: any, options = {size: 'sm', scrollable: false, centered: true}): any {
    this.targetForm = this.ngbModal.open($target, options);
  }

  onAuth(): void{
    this.loading.showAppLoading();
    this.subjectService.getDetailFull(this.subjectID, this.inputPass).subscribe(res => {
      this.loading.hideAppLoading();
      this.authForm.emit(res);
      this.targetForm.close();
    }, (err: {message: string}) => {
      this.loading.hideAppLoading();
      this.globalDataService.showErrmessage(err.message);
    });
  }

  onClose(): void{
    this.inputPass = '';
    this.targetForm.close();
  }

}
