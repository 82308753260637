import { BaseModel } from './base.model';
import { UserSessionInterface, UserSubjectInterface } from '../interfaces/user-session.interface';
import { UserRoles, UserState } from '../configs/user-config';

export class UserSession extends BaseModel implements UserSubjectInterface {
  token = '';
  tokenType = '';
  expiresIn = '';
  user = null;
  roles = null;
  is_admin = null;

  constructor(props?) {
    super(props);

    this.syncProps(props);
  }

  checkPermision(privilege: any): boolean {
    return true;
  }
}
