import {Pipe, PipeTransform} from '@angular/core';
import {NumberProcessor} from '../utils/number-processor';

@Pipe({
  name: 'CurrencyNumber'
})
export class CurrencyNumberPipe implements PipeTransform {

  transform(value: string | number, unit = 'JPY', minFractionDigits: number = null, maxFractionDigits: number = null): string {
    return NumberProcessor.currencyLocaleString(value, minFractionDigits, maxFractionDigits);
  }
}
