import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgSignaturePadOptions, SignaturePadComponent} from "@almothafar/angular-signature-pad";

@Component({
  selector: 'app-hand-sign',
  templateUrl: './hand-sign.component.html',
  styleUrls: ['./hand-sign.component.scss']
})
export class HandSignComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  @Input() lineWidth = 0.5;
  @Input() canvasWidth = 500;
  @Input() canvasHeight = 300;
  @Input() backgroundColor = 'rgba(255,255,255,1)';

  @Input() signaturePadOptions: NgSignaturePadOptions = {
    maxWidth : 0.75,
    canvasWidth: 500,
    canvasHeight: 300,
    backgroundColor: 'rgba(0,0,0,0)',
  };

  @Output() onSign = new EventEmitter<any>();
  @Input() dataPoint = [];
  @Input() signatureImage = '';

  constructor() { }

  ngOnInit(): void {
    this.signaturePadOptions.maxWidth = this.lineWidth;
    this.signaturePadOptions.canvasWidth = this.canvasWidth;
    this.signaturePadOptions.canvasHeight = this.canvasHeight;
    this.signaturePadOptions.backgroundColor = this.backgroundColor;
  }

  ngAfterViewInit(): void{
    // this.signaturePad.set('minWidth', this.lineWidth);
    this.signaturePad.clear();

    if (this.signatureImage) {
      this.signaturePad.fromDataURL(this.signatureImage);
    }
  }

  drawComplete(event: MouseEvent | Touch): void {
    this.onSign.emit({dataUrl: this.signaturePad.toDataURL()});
  }

  drawStart(event: MouseEvent | Touch): void {
  }

  clear(): void {
    this.signaturePad.clear();
    this.onSign.emit({dataUrl: ''});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.signaturePad){
      if (this.signatureImage) {
        this.signaturePad.fromDataURL(this.signatureImage);
      }else{
        this.signaturePad.clear();
      }
    }
  }
}
