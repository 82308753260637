import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {WebcamImage, WebcamInitError, WebcamUtil} from "ngx-webcam";
import {Observable, Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "../../../services/global-data.service";

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss']
})
export class TakePhotoComponent  implements OnInit {
  @Input() height = 250;
  @Input() width = 450;
  @Input() showBtnSnapshot = true;
  @Input() showBtnReset = true;
  @Input() disabled = false;
  @Input() isDetail = false;
  @Output() takeImage = new EventEmitter<any>();

  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode: string = 'environment';
  public errors: WebcamInitError[] = [];
  messError = 'ERROR_DEVICE';
  url = '';

  constructor(
    protected translate: TranslateService,
    public globalDataService: GlobalDataService
  ){
  }

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public openCamera(): void {
    this.showWebcam = true;
  }

  public triggerSnapshot(): void {
    this.showWebcam = true;
    this.trigger.next();
    if (this.errors.length)
      this.getError();

  }

  public toggleWebcam(): void {
    this.webcamImage = null;
    this.url = '';
    this.errors = [];
    this.takeImage.emit({imageData : null});
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
      this.getError();
    }
    if (error && this.errors.length == 0)
      this.getError();
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.takeImage.emit({imageData : webcamImage.imageAsDataUrl});
    this.url = webcamImage.imageAsDataUrl;
  }


  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }

  getError(): void{
    return this.globalDataService.showError(`ERRORS.${this.messError}`);
  }
}
