import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-answer',
  templateUrl: './item-answer.component.html',
  styleUrls: ['./item-answer.component.scss']
})
export class ItemAnswerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
