import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SORT_CONFIG } from 'src/app/configs/sort-config';

@Component({
  selector: 'app-filter-sort',
  templateUrl: './filter-sort.component.html',
  styleUrls: ['./filter-sort.component.scss']
})
export class FilterSortComponent implements OnInit {

  @Input() itemsSort = [];
  @Input() publishKey: any;
  @Input() option = {
    filter: '',
    order_by: 'created_at',
    order_dir: 'asc',
  };
  @Input() placeholder = 'COMMON.PLACEHOLDER_SEARCH';

  @Output() applySort: EventEmitter<any> = new EventEmitter();
  @Output() closeSort: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalPopupSort') modalPopupSort;

  popupSort: NgbModalRef;
  sortConfig = SORT_CONFIG;
  order_dir: any;
  order_by: any;

  constructor(protected modalService: NgbModal) { }

  ngOnInit(): void {
    this.order_dir = this.option.order_dir;
    this.order_by = this.option.order_by;
  }

  onEnter(e): void {
    if (e.keyCode === 13) {
      this.onApplyForm();
    }
  }

  // popup sort
  openPopUpSort($target: any = this.modalPopupSort, options = { size: 'md', scrollable: false, centered: false }): void {
    if (!$target){
      $target = this.modalPopupSort;
    }
    this.option.order_dir = this.order_dir;
    this.option.order_by = this.order_by;
    this.popupSort = this.modalService.open($target, options);
  }

  onCloseSort(): void {
    if (this.popupSort)
    {
      this.popupSort.close();
    }
  }

  onApplyForm(): void {
    this.order_by = this.option.order_by;
    this.order_dir = this.option.order_dir;
    this.applySort.emit(this.option);
    this.onCloseSort();
  }
}
