<button class="mt-2 btn btn-primary" (click)="openPopup(modalInputPass)">{{'SUBJECTS.VIEW_SUBJECT_INFORMATION' |
  translate}}</button>

<ng-template #modalInputPass let-modal>
  <div class="modal-body">
    <div class="form-group">
      <label>{{'COMMON.AUTH_PASSWORD_MESS' | translate}}</label>
      <input type="password" class="form-control" [(ngModel)]="inputPass" />
    </div>

  </div>
  <div class="modal-footer d-block text-center">
    <button class="ml-2 btn btn-primary" (click)="onAuth()"
      [attr.disabled]="inputPass == ''? true: null">{{'COMMON.APPLY' |
      translate}}</button>
    <button class="btn btn-outline-dark" (click)="onClose()">{{'COMMON.CLOSE' | translate}}</button>
  </div>

</ng-template>