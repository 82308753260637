export const OperatorFilter = {
  EQUAL: '=',
  GREATER: '>',
  LESS: '<',
  GREATER_OR_EQUAL: '>=',
  LESS_OR_EQUAL: '<=',
  NOT_EQUAL: '<>',
  CONTAINS: '%%',
  NOT_CONTAIN: '!%',
  START_WITH: '-%',
  SPACE:' - '
};

export const arrConditions = [
  {name: 'EQUAL', value: OperatorFilter.EQUAL},
  {name: 'GREATER', value: OperatorFilter.GREATER},
  {name: 'LESS', value: OperatorFilter.LESS},
  {name: 'GREATER_OR_EQUAL', value: OperatorFilter.GREATER_OR_EQUAL},
  {name: 'LESS_OR_EQUAL', value: OperatorFilter.LESS_OR_EQUAL},
  {name: 'NOT_EQUAL', value: OperatorFilter.NOT_EQUAL},
  {name: 'CONTAINS', value: OperatorFilter.CONTAINS},
  {name: 'NOT_CONTAIN', value: OperatorFilter.NOT_CONTAIN},
  {name: 'START_WITH', value: OperatorFilter.START_WITH},
];

export const FilterSetting = {
  number: [
    {name: 'EQUAL', value: OperatorFilter.EQUAL},
    {name: 'GREATER', value: OperatorFilter.GREATER},
    {name: 'LESS', value: OperatorFilter.LESS},
    {name: 'GREATER_OR_EQUAL', value: OperatorFilter.GREATER_OR_EQUAL},
    {name: 'LESS_OR_EQUAL', value: OperatorFilter.LESS_OR_EQUAL},
    {name: 'NOT_EQUAL', value: OperatorFilter.NOT_EQUAL},
  ],
  string: [
    {name: 'CONTAINS', value: OperatorFilter.CONTAINS},
    { name: 'EQUAL', value: OperatorFilter.EQUAL },
    {name: 'START_WITH', value: OperatorFilter.START_WITH},
    {name: 'NOT_CONTAIN', value: OperatorFilter.NOT_CONTAIN},
  ],
  date: [
    {name: 'EQUAL', value: OperatorFilter.EQUAL},
    {name: 'BEFORE', value: OperatorFilter.LESS_OR_EQUAL},
    {name: 'AFTER', value: OperatorFilter.GREATER_OR_EQUAL},
    {name: 'NOT_EQUAL', value: OperatorFilter.NOT_EQUAL},
  ],
  boolean: [
    {name: 'EQUAL', value: OperatorFilter.EQUAL},
  ],
  select: [
    {name: 'EQUAL', value: OperatorFilter.EQUAL},
    {name: 'NOT_EQUAL', value: OperatorFilter.NOT_EQUAL},
  ]
};
