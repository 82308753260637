<div class="input-group mr-2" [class]="class">
  <input class="form-control" (blur)="onTouched()" [(ngModel)]="valueModel" [disabled]="disabled"
    [placeholder]="placeholder" readonly />
  <div class="input-group-append" [ngbPopover]="calendarContent">
    <button class="btn border-button h-35px" [disabled]="disabled" type="button">
      <i class="far fa-calendar-alt"></i>
    </button>
  </div>
  <div class="input-group-append" *ngIf="!isValueToday">
    <button class="btn border-button h-35px" type="button" (click)="clearData()">
      <i class="fas fa-times"></i>
    </button>
  </div>
</div>

<ng-template #calendarContent>
  <div>
    <div *ngIf="!showTimePickerToggle">
      <ngb-datepicker *ngIf="!isShowDatePicker" id="dp" #dp name="datepicker" [minDate]="minDate" [maxDate]="maxDate"
        [markDisabled]="isDisabled" [ngModel]="datetime" (ngModelChange)="onDateChange($event, dp) ">
      </ngb-datepicker>
      <div class="mt-auto d-flex justify-content-center" *ngIf="isShowTimePicker">
        <ngb-timepicker #tp name="timepicker" [ngModel]="datetime" (ngModelChange)="onTimeChange($event)"
          [hourStep]="hourStep" [minuteStep]="minuteStep">
        </ngb-timepicker>
      </div>
    </div>
  </div>
</ng-template>
