<input *ngIf="type !== 'boolean'"
       [value]="value ? getValueFormat(type, value, header, unit) : (type === 'number' ? (0 + unit) : '')"
       disabled
       [style.text-align]="getTextAlign(type)"
       class="form-control"/>

<div *ngIf="type === 'boolean'"
     [style.text-align]="getTextAlign(type)">
  <i class="fas"
     [ngClass]="value ? 'fa-check text-success-500': 'fa-times text-danger-500'">
  </i>
</div>
