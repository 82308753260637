import { FormControl, FormGroup } from '@angular/forms';
import { QuickAccessForm } from '../share/utils/quick-access-form';
import { BaseModel } from "./base.model";
import { Directive, EventEmitter, Input, OnInit, Output, } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BaseApi } from '../services/api-service/base.api';
import { Router } from '@angular/router';
import { LoadingService } from '../share/service/loading.service';
import { ConfirmationModalService } from '../share/service/modal/confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataService } from '../services/global-data.service';
import { HandleError, HandleErrorModel } from './handle-error.model';
import { Observable } from 'rxjs';

@Directive({})
export abstract class BaseDetailPopup extends BaseModel {

  popupAdd: NgbModalRef;
  formGroupAdd: FormGroup;
  itemEdit: any;
  isEdit = false;

  @Input() id = 0;
  @Output() closeForm = new EventEmitter<any>();

  isShowForm = false;
  optionsError: any = [];
  urlSave = '';

  abstract currentObj;

  constructor(
    protected mainAPIService: BaseApi,
    protected router: Router,
    protected loading: LoadingService,
    protected confirmPopup: ConfirmationModalService,
    protected translate: TranslateService,
    protected globalDataService: GlobalDataService,
    protected ngbModal: NgbModal
  ) {
    super();
  }

  showFormEdit(id = null): void{
    if (id){
      this.id = id;
    }
    if (this.id) {
      this.loading.showAppLoading();
      this.mainAPIService.getDetail(this.id).subscribe(res => {
        this.loading.hideAppLoading();

        this.itemEdit = res;
        this.isEdit = true;
        this.editItem(this.itemEdit);
        this.globalDataService.addForm(this.formGroupAdd);
      }, error => {
        this.loading.hideAppLoading();
        this.onCloseForm();
      });
    } else {
      this.itemEdit = {};
      this.editItem();
    }
  }

  editItem(item?: any): void {
    this.formGroupAdd = this.buildForm(item);
    this.isShowForm = true;
  }

  onCloseForm($event: any = { isLoading: false }): void {
    this.globalDataService.checkFormChange().subscribe((allowChange) => {
      if (allowChange) {
        if (this.popupAdd)
        {
          this.popupAdd.close();
        }
        this.closeForm.emit($event);
      }
    });
  }

  buildForm(val?): FormGroup {
    const form = new FormGroup({
      isNew: new FormControl(!val),
      isDeleted: new FormControl(false)
    });
    return form;
  }

  save(urlSave = this.urlSave): Observable<boolean> {
    return new Observable<any>(subscriber => {
      this.formGroupAdd.markAllAsTouched();
      if (!this.beginSave()) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }
      if (this.formGroupAdd.invalid) {
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      const data = this.beforeSave(this.formGroupAdd.getRawValue());
      data['submit_time'] = this.globalDataService.getCurrentLocalTime();
      this.loading.showAppLoading();
      this.mainAPIService.save(data, urlSave).subscribe((res) => {
        this.loading.hideAppLoading();
        if (this.saveSuccess(res)) {
          this.formGroupAdd.markAsPristine();
          // show success save
          this.globalDataService.showSuccess('COMMON.SAVE_SUCCESSFUL');
          this.onCloseForm({ item: res, isLoading: true });
          subscriber.next(true);
          subscriber.complete();
          // this.confirmPopup.success(this.app_config.getMessTranslate(this.translate, this.currentObj, 'COMMON.SAVE_SUCCESSFUL'), () => {
          //   this.onCloseForm({ item: res, isLoading: true });
          //   subscriber.next(true);
          //   subscriber.complete();
          // });
          this.formGroupAdd.get('id')?.setValue(res.id);
        } else {
          subscriber.next(true);
          subscriber.complete();
        }
      }, (er: HandleErrorModel) => {
        this.loading.hideAppLoading();
        subscriber.next(false);
        subscriber.complete();
        if (!this.saveFail(er)) {
          return;
        }

        if (er.message !== 'ER0030')
          this.globalDataService.showErrmessage(er.message, this.currentObj, '.');
        // const handleError = new HandleError(err);
        // handleError.checkAuthen(err, handleError, this.currentObj.toUpperCase(), this.translate, this.confirmPopup);
        // this.saveError(er);
      });
    });
  }

  actionSave(urlSave = this.urlSave): void {
    this.save(urlSave).subscribe((status) => {
      if (status) {
      }
    });
  }

  openPopUp($target: any, options = { size: 'md', scrollable: false, centered: false }): NgbModalRef {
    return this.ngbModal.open($target, options);
  }

  onTrim(item): void {
    if (this.formGroupAdd.get(item).value && this.formGroupAdd.get(item).dirty)
    {
      this.formGroupAdd.get(item).setValue(this.formGroupAdd.get(item).value.trim());
    }
  }

  beginSave(): any { return true; }
  beforeConfirm(countItem: any): any { return countItem; }
  beforeSave(data: any): any { return data; }
  saveSuccess(res: any): any { return true; }
  saveFail(err: HandleErrorModel): any { return true; }
  saveError(err: HandleErrorModel): HandleErrorModel { return err; }

  formGetErrorControlMsg(controlName: string, errors: Array<string>, form = this.formGroupAdd): string {
    const optionsControl = this.optionsError[controlName] || {};
    return QuickAccessForm.getErrorControlMsg(form, controlName, errors, this.currentObj, this.translate, optionsControl);
  }

  formIsShowErrorControl(controlName: string, form = this.formGroupAdd): boolean {
    return QuickAccessForm.isShowErrorControl(form, controlName);
  }

}
