import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewChild,
  AfterViewInit,
  Injector,
  Output,
  EventEmitter
} from "@angular/core";
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbPopoverConfig,
  NgbPopover,
  NgbDatepicker,
  NgbDatepickerI18n, NgbDate
} from "@ng-bootstrap/ng-bootstrap";
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, FormControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { DateTimeModel } from "./date-time.model";
import * as moment from 'moment';
import { DatepicketLocalizationService, I18n } from "./data-picker.service";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    },
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatepicketLocalizationService }
  ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() format = 'DD-MM-YYYY HH:mm';
  @Input() hourStep = 1;
  @Input() minuteStep = 15;
  @Input() secondStep = 30;
  @Input() seconds = true;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() isDisabled: any;
  @Input() isValueToday = true;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() class: any;
  @Input() isShowDatePicker: false;
  dateString = '';
  valueModel = '';

  value = new FormControl();

  private showTimePickerToggle = false;

  private datetime: DateTimeModel;
  private firstTimeAssign = true;
  private isShowTimePicker = false;

  @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;

  constructor(private config: NgbPopoverConfig, private inj: Injector, private datePipe: DatePipe) {
    config.autoClose = 'outside';
    config.placement = 'auto';

    this.value.valueChanges.subscribe(val => {
      if (this.value.dirty) {
        this.onChangeModel(val);
      }
    });
    this.value.statusChanges.subscribe(val => {
      this.onTouchModel(val);
    });
  }

  ngOnInit(): void {
    if (!this.placeholder){
      this.placeholder = this.format;
    }
    this.isShowTimePicker = this.format.indexOf('HH') >= 0;
  }

  ngAfterViewInit(): void {
    this.popover.hidden.subscribe($event => {
      this.showTimePickerToggle = false;
    });
  }

  private onChangeModel = (v: any) => {
  }
  protected onTouchModel = (v: any) => {
  }

  writeValue(newModel: string): void{
    if (newModel) {
      this.dateString = newModel;
      this.datetime = DateTimeModel.fromLocalString(newModel);
    } else {
      if (this.isValueToday) {
        this.datetime = new DateTimeModel();
      }
      this.firstTimeAssign = false;
    }

    this.setDateStringModel();
  }

  onTouched(): void {
    if (this.value.dirty) {
      this.value.setValue(this.value.value.trim());
    }
    this.onTouchModel('touched');
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  toggleDateTimeState($event): void{
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker): void {
    let date = null;
    if (typeof $event === 'string')
      {
        date = DateTimeModel.fromLocalString($event)
      }
    else{
      date = new DateTimeModel($event);
    }

    if (!date) {
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    const adjustedDate = new Date(this.datetime.toString());
    if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }

    this.setDateStringModel();
    this.toggleDateTimeState($event);
  }

  onTimeChange(event: NgbTimeStruct): void {
    // @ts-ignore
    this.datetime?.hour = event?.hour;
    // @ts-ignore
    this.datetime?.minute = event?.minute;
    // @ts-ignore
    this.datetime?.second = event?.second;

    this.setDateStringModel();
  }

  setDateStringModel(): void {
    if (this.datetime) {
      this.dateString = this.datetime.toString(this.format);
      this.valueModel = this.dateString;
      const date = new Date(this.datetime.year, this.datetime.month - 1, this.datetime.day, this.datetime.hour, this.datetime.minute);
      const outFormat = this.isShowTimePicker ? 'YYYY-MM-DD HH:mm:00' :  'YYYY-MM-DD';
      this.value.setValue(moment(date).format(outFormat), { emitEvent: false });

      if (!this.firstTimeAssign) {
        setTimeout(() => {
          this.value.markAsDirty();
          this.onTouched();
        }, 10);
      } else {
        // Skip very first assignment to null done by Angular
        if (this.dateString !== null) {
          this.firstTimeAssign = false;
        }
      }
    }
  }

  clearData(): void {
    this.valueModel = '';
    this.value.setValue(null);
  }
}
