import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseApi } from './api-service/base.api';

@Injectable({
  providedIn: 'root',
})
export class IOService extends BaseApi {

  importFile(url: any,file: any, type: string, params: {}): Observable<any> {
    //this.extendSearchParams(params);
    let formData = this.convertModelToFormData({ file: file });
    Object.keys(params).map(key => {
      formData.append(key, params[key]);
    });
    return this.post(``+url, formData, {table: type});
  }

  importFileExcel(file: any, type: string, params: {}): Observable<any> {
    //this.extendSearchParams(params);
    let formData = this.convertModelToFormData({ file: file });
    Object.keys(params).map(key => {
      formData.append(key, params[key]);
    });
    return this.post(`import-excel`, formData, {table: type});
  }

  getExportData(type: string, params: any, columns: string, criteria: string, order: string) {
    let headers = new HttpHeaders();
    params.columns = columns;
    params.criteria = criteria;
    params.order = order;
    return this.http.post(this.fullURL(`export-csv/${type}`) + "?" + this.extendSearchParams(params), {}, {
      headers,
      responseType: 'blob',
      observe: 'response'
    });
  }

  processRowCSV(row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      // if (row[j] instanceof Date) {
      //   innerValue = row[j].toLocaleString();
      // };
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (isNaN(result)) {
        result = '"' + result + '"';
      }
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  }

  exportToCsv(rows, filename) {
    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += this.processRowCSV(rows[i]);
    }

    this.downloadFile(csvFile, filename);
  }

  exportToTXT(rows, filename) {
    const contents = rows.join("\n");
    this.downloadFile(contents, filename, "text/txt;charset=utf-8;");
  }

  downloadFile(contents, filename = 'download-file.csv', type = 'text/csv;charset=utf-8;') {
    const blob = new Blob([contents], {type});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  downloadFileText(contents, filename = 'download-file', type = 'application/octet-stream') {
    const blob = new Blob([contents], {type});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
