<div class="dropdown">
  <div class="input-group" [ngClass]="class">
    <div class="inner-addon right-addon" [ngClass]="{'w-input': !options.isShowPopUp}">
      <input class="form-control dropdown-toggle" readonly [formControl]="selectedVal" (focus)="toggleDrop(true)" (click)="activeFocus()"
             (blur)="onTouched()" [placeholder]="option.placeholder" >
      <span style="padding-right: 13px" class="inner-icon-right" *ngIf="selectedVal.value" (click)="clearData()"
            [ngClass]="{'d-none': selectedVal.disabled || !flagProduct}">&times;</span>
    </div>
    <div class="input-group-append" >
      <button class="btn border-left-0" type="button" [disabled]="selectedVal.disabled" (click)="openPop()" *ngIf="options.isShowPopUp">
        <i class="fas fa-search fs14"></i></button>
    </div>
  </div>

  <div class="dropdown-menu p-1" [class]="{'show': showDrop.value}" (scroll)="dropListScroll($event)">
    <input #selectSearch class="form-control form-control-sm custom-size" [formControl]="searchKey">
    <a class="dropdown-item" *ngFor="let opt of optionList" (click)="chooseOpt(opt)" (keyup.enter)="chooseOpt(opt)"
       tabindex="0" [ngClass]="{'active': selectedVal.value === opt[option.colValue]}">
      <span class="custom-size">{{opt[option.colLabel]}}</span>
    </a>
  </div>
</div>

<ng-template #modalContent let-modal>
  <app-exam-data-master *ngIf="option?.masterSearchComponents === masterSelectSearchScreen.EXAM_DATA_LIST"
                        [selectSearchMode]="true" [selectedValue]="{key: option.selectColumn, value: selectedVal.value}"
                        (selectOneRow)="chooseOptFromMaster($event)"
                        (dismissPopup)="modal.dismiss()"></app-exam-data-master>

  <app-employee-group-master *ngIf="option?.masterSearchComponents === masterSelectSearchScreen.EMPLOYEES"
    [selectSearchMode]="true" [selectedValue]="{key: option.selectColumn, value: selectedVal.value}"
    (selectOneRow)="chooseOptFromMaster($event)"
  (dismissPopup)="modal.dismiss()"></app-employee-group-master>

  <app-employee-group-master *ngIf="option?.masterSearchComponents === masterSelectSearchScreen.EMPLOYEE_GROUP"
    [selectSearchMode]="true" [selectedValue]="{key: option.selectColumn, value: selectedVal.value}"
    (selectOneRow)="chooseOptFromMaster($event)" (dismissPopup)="modal.dismiss()"></app-employee-group-master>

    <ng-container *ngIf="option?.masterSearchComponents === masterSelectSearchScreen.DEPARTMENTS">
      <app-share-depatment-select-one [selectSearchMode]="true"
        [selectedValue]="{key: option.selectColumn, value: selectedVal.value}" (selectOneRow)="chooseOptFromMaster($event)"
        (dismissPopup)="modal.dismiss()"></app-share-depatment-select-one>
    </ng-container>
</ng-template>
