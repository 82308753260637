import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ManagerStudySelectApi } from 'src/app/services/api-service/manager-study-select.api.service';

@Pipe({
  name: 'StudyDate'
})
export class StudyDatePipe implements PipeTransform {

  constructor(protected studySelectApi: ManagerStudySelectApi){

  }

  transform(value: any, ...args: any[]): any {
    if (args.length === 3){
      return this.processWorking(value, args[0], args[1], args[2]);
    }else{
      const studySelected = this.studySelectApi.getStudyWorking(false);
      if (!studySelected){
        return value;
      }
      return this.processWorking(value, studySelected?.time_zone, studySelected?.date_format, studySelected?.time_format);
    }
  }

  processWorking(value, timezone, dateformat = 0, timeformat = 0): any{
    if (!value){
      return value;
    }
    let date = moment(value);
    date = this.processTimeZone(date, timezone);
    return this.processFormat(date, dateformat, timeformat);
  }

  processTimeZone(date, timezone): any{
    return date.add(timezone * 60, 'minutes');
  }

  processFormat(date, dateformat = 0, timeformat = 0): any{
    const listDateFormat = ['YYYY/MM/DD', 'DD/MM/YYYY', 'MM/DD/YYYY'];
    const listTimeFormat = ['hh:mm:ss A', 'HH:mm:ss'];
    const format = listDateFormat[dateformat] + ' ' + listTimeFormat[timeformat];
    return date.format(format);
  }
}
