import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {JwtInterceptorHelper} from './helpers/http/jwt-interceptor.helper';
import {ErrorInterceptorHelper} from './helpers/http/error-interceptor.helper';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LayoutModule} from './module/layout/layout.module';
import {AuthModule} from './module/auth/auth.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from 'ngx-toastr';
import {WebcamModule} from "ngx-webcam";
import {ColorPickerModule} from "ngx-color-picker";

export function HttpLoaderFactory(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule,
    LayoutModule,
    NgbModule,
    ToastrModule.forRoot(),
    WebcamModule,
    ColorPickerModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorHelper, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorHelper, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
