import {TableHeaderModel} from './table-header.model';
import {PersistStateScreen} from '../configs/persist-state-screen';
import {BaseModel} from "./base.model";
import {Directive, EventEmitter, Input, Output, QueryList, ViewChild} from "@angular/core";
import {HandleError, HandleErrorModel} from "./handle-error.model";
import {BaseApi} from "../services/api-service/base.api";
import {Router} from "@angular/router";
import {LoadingService} from "../share/service/loading.service";
import {ConfirmationModalService} from "../share/service/modal/confirmation-modal.service";
import {TranslateService} from "@ngx-translate/core";
import {GlobalDataService} from "../services/global-data.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {BaseSearchParam} from "./base-search-param.model";
import {SortableDirective, SortEvent} from "../share/directives/sortable.directive";
import {PagingConfig} from "../configs/paging-config";
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {PersistStateModel} from "./persist-state-model";

@Directive({})
export abstract class BaseListReadonly extends BaseModel {
  iconSort: any;
  @Input() selectSearchMode = false;
  @Input() selectedValue: { key: string, value: string } = {key: 'code', value: ''};
  screenId: PersistStateScreen;
  base_url = '';
  sortHeaders: QueryList<SortableDirective>;
  selectedHeaders: TableHeaderModel[] = [];
  headers: TableHeaderModel[] = [];

  abstract currentObj;
  searchParam = new BaseSearchParam();
  totalRecords = 0;
  dataList: Array<any> = [];
  originalData: Array<any> = [];

  disableAddButton = false;
  isFilter = false;
  popupAdd: NgbModalRef;
  formAdd: FormGroup;
  itemEdit: any;
  showPopUpEdit = false;
  disableEncode = false;
  itemSelected = null;
  @Output() detailClick: EventEmitter<any> = new EventEmitter();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalPopupEdit') modalPopupEdit;
  @ViewChild('formEdit') formEdit;

  constructor(
    protected mainAPIService: BaseApi,
    protected router: Router,
    protected loading: LoadingService,
    protected confirmPopup: ConfirmationModalService,
    protected translate: TranslateService,
    protected globalDataService: GlobalDataService,
    protected ngbModal: NgbModal,
    protected callGetList: boolean = true
  ) {
    super();
    this.globalDataService.isSave = false;
    if (this.callGetList) {
      this.getList();
    }
  }

  getListFail(er: HandleErrorModel): boolean {return true;}

  getList(searchParam = null): void {
    this.loading.showAppLoading();
    this.mainAPIService.getAll(searchParam ? searchParam : this.searchParam)
      .subscribe(res => {
        this.loading.hideAppLoading();
        this.totalRecords = res.total;
        this.updatePagination();
        if (!this.selectSearchMode) {
          // this.globalDataService.clearForm();
        }
        this.originalData = res.data;
        this.dataList = this.processDataList(res.data);
        if (this.itemSelected?.id) {
          const found = this.dataList.findIndex(item => item?.id === this.itemSelected?.id);
          if (found === -1) {
            this.detailClick.emit(this.dataList[0]);
            this.itemSelected = this.dataList[0];
          } else {
            this.detailClick.emit(this.dataList[found]);
            this.itemSelected = this.dataList[found];
          }
        }
      }, (er: HandleErrorModel) => {
        this.loading.hideAppLoading();
        this.dataList = [];
        this.originalData = [];
        this.getListFail(er);
      });
  }

  updatePagination(): void {
    const totalPage = Math.ceil(this.totalRecords / this.searchParam.limit);
    if (this.totalRecords > 0 && this.searchParam.page > totalPage) {
      this.searchParam.page = totalPage;
      this.getList();
    }
  }

  processDataList(data: any): any {
    return data;
  }

  // region update adjust item

  getPersistState(persistStateApi): Observable<any> {
    return persistStateApi.getPersistState(this.currentObj)
      .pipe(tap((persistState: PersistStateModel) =>
        this.updatePersistState(persistState)));
  }

  updatePersistState(persistState: PersistStateModel): void {
    this.searchParam.filterList = persistState.filterList;
    this.searchParam.updateCriteria();
    this.onChangeColumnSetting(persistState.userDefined ? persistState.visibleOrder : null);
    this.getList();
  }

  onChangeColumnSetting(visibleColumns: string | null): void {
    if (visibleColumns === null) {
      this.selectedHeaders = this.headers;
    } else if (visibleColumns === '') {
      this.selectedHeaders = [];
    } else {
      this.selectedHeaders = visibleColumns.split(',')
        .map(column => this.headers.find(header => column === header.column));
    }
  }

  onDetail($event: any): void {
    this.router.navigate(['/' + this.base_url + '/' + this.url_list.PATH_EDIT, +$event.item.id]);
  }

  onView($event: any): void {
    this.router.navigate(['/' + this.base_url + '/' + this.url_list.PATH_DETAIL, +$event.item.id]);
  }

  onViewPopup($event: any, modalContent: any, options = {size: 'md', scrollable: false, centered: false}): void {
    this.ngbModal.open(modalContent, options);
  }

  /**
   * Show confirm
   * Call API delete
   */
  onDelete($event, moreInfo = null): void {
    // show confirm
    let extra_title = '';
    let content = 'COMMON.DELETE_CONTENT';
    if (moreInfo != null && (moreInfo == 'documents')){
      extra_title = 'reason';
      content = 'DOCUMENTS.DELETE_DOCUMENT';
    }
    this.confirmPopup.confirm('COMMON.DELETE_TITLE',content, 'COMMON.CANCEL', '', 'COMMON.OK' ,(res = '') => {
      // call service
      this.loading.showAppLoading();
      const itemID = $event?.item?.id || $event?.id || null;
      this.mainAPIService.remove(itemID, '', this.globalDataService.getCurrentLocalTime(), extra_title, res).subscribe(result => {
        this.globalDataService.showSuccess('COMMON.DELETE_SUCCESSFUL');
        this.getList();
      }, (error: { success: boolean, message: string }) => {
        this.loading.hideAppLoading();
        this.globalDataService.showErrmessage(error.message, this.currentObj, '.');
      });
    } , () => {} , () => {} , () => {}, moreInfo);
    console.log(4);
  }

  onFilter(param: any): void {
    // this.searchParam.filterList = e;
    // this.searchParam.updateCriteria();
    this.searchParam.fields = param.fields;
    this.searchParam.conds = param.conds;
    this.searchParam.values = param.values;
    this.searchParam.page = PagingConfig.FirstPage;
    this.searchParam.order = '';
    this.getList();
    this.disableAddButton = false;
  }

  deleteMany(ids = []): void {
    if (!this.selectedRows.length) {
      return;
    }
    if (ids.length === 0 || !ids) {
      ids = this.selectedRows.map(item => item.id);
    }
    this.confirmPopup.confirm('COMMON.DELETE_TITLE', 'COMMON.DELETE_CONTENT', 'COMMON.CANCEL', '', 'COMMON.OK', () => {
      // call service
      this.mainAPIService.deleteMany(ids, 'deletes').subscribe(result => {
        this.confirmPopup.success(this.translate.instant('COMMON.DELETE_SUCCESSFUL'));
        this.getList();
        this.selectedRows = [];
      });
    });
  }

  // endregion
  onSort(e: SortEvent): void {
    this.iconSort = e?.direction;
    if (this.sortHeaders) {
      this.sortHeaders.forEach(header => {
        if (header.sortable !== e.column) {
          header.direction = 'asc';
        }
      });
    }
    this.searchParam.order = e.sortQuery;
    this.searchParam.order_by = e.column;
    this.searchParam.order_dir = e?.direction;
    this.searchParam.page = PagingConfig.FirstPage;
    this.getList();
    this.disableAddButton = false;
  }

  changePage(e: number): void {
    this.searchParam.page = e;
    this.getList();
    this.disableAddButton = false;
    this.disableEncode = false;
  }

  changePageSize(e: number): void {
    this.searchParam.limit = e;
    this.searchParam.page = PagingConfig.FirstPage;
    this.getList();
    this.disableAddButton = false;
    this.disableEncode = false;
  }

  // endregion

  onReload(subscriber?: any, location?: any, nameScreen?: string): void {
    if (subscriber) {
      setTimeout(() => {
        subscriber.next(true);
        subscriber.complete();
      }, 1001);
    } else if (location) {
      setTimeout(() => {
        location.back();
      }, 1001);
    } else if (nameScreen === 'list') {
      setTimeout(() => {
        this.selectedRows = [];
        this.getList();
      }, 1001);
    }
  }

  addNew($event: any): void {
    this.router.navigate(['/' + this.base_url + '/' + this.url_list.PATH_ADD]);
  }

  openPopupEdit($event?, $target: any = this.modalPopupEdit, options = {
    size: 'lg',
    scrollable: false,
    centered: false
  }): void {
    if (!$target) {
      $target = this.modalPopupEdit;
    }
    this.popupAdd = this.openPopUp($target, options);
    if ($event?.item) {
      this.itemEdit = $event?.item;
    } else {
      this.itemEdit = $event;
    }
    this.showPopUpEdit = true;
  }

  openPopUp($target: any = this.modalPopupEdit, options = {size: 'md', scrollable: false, centered: false}): any {
    return this.ngbModal.open($target, options);
  }

  onClosePopupEdit($event): void {
    if (this.popupAdd) {
      this.popupAdd.close();
    }
    this.itemEdit = {childs: []};
    this.showPopUpEdit = false;
    if ($event?.isLoading === true) {
      this.getList();
    }
  }

  onSelectFromTableCustom(e: Array<any>): void {
    super.onSelectFromTableCustom(e);
    this.selectOneRow.emit(this.selectedRows[0]);
  }

  selectSearchChosen(item: any): void {
    if (this.selectSearchMode) {
      this.selectOneRow.emit(item);
    }
  }

  uploadSuccess($event): void {
    if ($event === true) {
      this.getList();
    }
  }

  onDetailClick(item): void {
    if (item.id === this.itemSelected?.id) {
      return;
    }

    this.globalDataService.checkFormChange('detail click').subscribe((allowChange) => {
      if (allowChange) {
        this.detailClick.emit(item);
        this.itemSelected = item;
      }
    });
  }

  onAddItem(): void {
    this.globalDataService.checkFormChange('add new click').subscribe((allowChange) => {
      if (allowChange) {
        this.addNewItem.emit(0);
        this.itemSelected = null;
      }
    });
  }

  onApplySort($event): void {
    this.searchParam.filter = $event.filter;
    this.searchParam.order_by = $event.order_by;
    this.searchParam.order_dir = $event.order_dir;
    this.getList();
  }
}
