import {AbstractControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';

export class CustomValidators {
  static required(isQuantity = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return {required: true};
      } else if (typeof control.value === 'string' && control.value.trim() === '') {
        return {required: true};
      } else if (isQuantity && typeof (+ control.value) === 'number' && !(+ control.value)) {
        return {required: true};
      } else {
        return null;
      }
    };
  }
  static dateRequired(startDate: any, endDate: any, error: string): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } => {
      if (startDate > endDate) { return  {[error]: true}; }
    };
  }

  static regexValidator(regexValue: string, error: string): ValidatorFn {
    const regex = new RegExp(regexValue);
    return (control: AbstractControl): { [key: string]: any } => {
      const value = String(control.value).trim();
      if (!value) return null;
      return regex.test(value) ? null : {[error]: true};
    };
  }

  static regexNotTrimValidator(regexValue: string, error: string): ValidatorFn {
    const regex = new RegExp(regexValue);
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value;
      if (!value) return null;
      return regex.test(value) ? null : {[error]: true};
    };
  }

  static requiredPassWord(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return {required: true};
      } else {
        return null;
      }
    };
  }

  //at least "minRequired" checked to meet required
  static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
      return function validate (formGroup: FormGroup) {
        let checked = 0;

        Object.keys(formGroup.controls).forEach(key => {
          const control = formGroup.controls[key];

          if (control.value === true) {
            checked ++;
          }
        });
        if (checked < minRequired) {
          return {required: true};
        }

        return null;
      };
  }
}
