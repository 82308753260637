import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HandleError, HandleErrorModel } from 'src/app/models/handle-error.model';
import { BaseApi } from 'src/app/services/api-service/base.api';
import { IOService } from 'src/app/services/IO.service';
import { EnumMember } from 'typescript';
import { LoadingService } from '../../service/loading.service';
import { ConfirmationModalService } from '../../service/modal/confirmation-modal.service';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {

  @Input() type = "";
  @Input() excel = false;
  @Input() params = {};
  @Input() keyScreen = "";
  @Input() btnSize = "lg";
  @Output() beforeUpload = new EventEmitter<any>();
  @Output() uploadSuccess = new EventEmitter<any>();
  @Output() uploadError = new EventEmitter<any>();
  isLoading=true;

  @ViewChild('uploadFile') uploadFile;
  url='';

  constructor(
    private translate: TranslateService,
    private confirmPopup: ConfirmationModalService,
    private loading: LoadingService,
    private ioService: IOService,
  ) { }

  ngOnInit() {
  }

  chooseFileUpload(): void {
    this.uploadFile.nativeElement.click();
  }

  changeFile($event): void {
    const file = $event.dataTransfer ? $event.dataTransfer.files[0] : $event.target.files[0];
    this.beforeUpload.emit(file);
    this.loading.showAppLoading();
    this.excel==true? this.url='import-excel' : this.url='import-csv';
    this.ioService.importFile(this.url,file, this.type, this.params).subscribe((res) => {
      this.loading.hideAppLoading();
      this.isLoading=true;
      this.uploadSuccess.emit(this.isLoading);
      this.uploadFile.nativeElement.value = "";

      this.confirmPopup.success(this.translate.instant('COMMON.IMPORT_SUCCESS'));
    }, (error: HandleErrorModel) => {
      this.isLoading=false;
      this.loading.hideAppLoading();
      this.uploadError.emit(error);
      this.uploadFile.nativeElement.value = "";

      const handleError = new HandleError(error);
      if (!handleError.errorCodes.length && !handleError.errorData.length) {
        return;
      }

      if (handleError.errorCodes.length == 1 && handleError.errorCodes[0] == 'ERR_FILE_FORMAT') {
        this.confirmPopup.notify('', this.translate.instant('IMPORT.ERR_FILE_FORMAT'));
      } else if (handleError.errorCodes.length == 1 && handleError.errorCodes[0] == 'ERR_FILE_SIZE') {
        this.confirmPopup.notify('', this.translate.instant('IMPORT.ERR_FILE_SIZE'));
      } else {
        this.confirmPopup.confirm('', this.translate.instant('IMPORT.ERRORS'), 'COMMON.CANCEL', null, 'COMMON.SAVE', () => {
          const rows = handleError.getFileError(this.translate, this.keyScreen);
          if (rows != null)
            this.ioService.exportToTXT(rows, this.translate.instant('IMPORT.FILE_NAME_ERROR'));
        });
      }
    });
  }
}
